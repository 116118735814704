import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./LanguageSwitcher";

const Header = () => {
  const { t } = useTranslation();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  function toggleMenu() {
    setIsMenuOpen(!isMenuOpen);
  }
  const [isScrolling, setIsScrolling] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolling(true);
    } else {
      setIsScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header>
      <div className={`header ${isScrolling ? "scrolling" : ""}`}>
        <nav role="navigation">
          <div className="container">
            <div className="logo">
            <a href="#top" role="navigation" aria-label="logo back to top" className="to-top">
                  <svg
                    id="Layer_1"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 69.08 41.5"
                    width="50px"
                  >
                    <path
                      d="m50.16,10.43c1.36,0,2.36-.3,2.98-.9.63-.6.94-1.76.94-3.46v-2.23c0-1.35.22-2.32.67-2.93.45-.6,1.17-.9,2.16-.9h9.34c.99,0,1.71.3,2.16.9.44.6.67,1.58.67,2.93v4.68c0,10-1.49,16.87-4.47,20.59-2.98,3.72-6.83,5.59-11.54,5.59h-10.21c-.52,2.27-1.32,3.97-2.39,5.11-1.07,1.14-2.55,1.7-4.44,1.7h-2.75c-1.88,0-3.35-.57-4.4-1.7-1.05-1.13-1.83-2.84-2.36-5.11h-10.44c-4.76,0-8.63-1.86-11.62-5.59C1.49,25.38,0,18.52,0,8.51V3.83C0,2.48.22,1.51.67.9c.45-.6,1.17-.9,2.16-.9h9.5c.99,0,1.71.3,2.16.9.45.6.67,1.58.67,2.93v2.23c0,1.7.31,2.86.94,3.46.63.6,1.62.9,2.98.9h9.18c1.57,0,2.72.34,3.45,1.01.73.67,1.2,1.93,1.41,3.78l.47,3.51c.05.5.16.85.31,1.06.16.21.42.32.79.32s.63-.11.79-.32c.16-.21.26-.57.31-1.06l.47-3.51c.21-1.84.68-3.1,1.41-3.78.73-.67,1.88-1.01,3.45-1.01h9.03Z"
                      style={{ fill: "#a4c2f4" }}
                    />
                  </svg>
                </a>
            </div>
            <ul className={`menu ${isMenuOpen ? "active" : ""}`}>
      
              <li>
                <a href={`#${t('Nav.hrefOne')}`} aria-label="Services" title="Services" onClick={toggleMenu}>
                {t('Nav.LinkOne')}
                </a>
              </li>
              <li>
                <a href={`#${t('Nav.hrefTwo')}`} aria-label="About Us" title="About Us" onClick={toggleMenu}>
                {t('Nav.LinkTwo')}

                </a>
              </li>
              <li>
                <a
                  href={`#${t('Nav.hrefThree')}`}
                  aria-label="Contact Us"
                  title="Contact Us"
                  onClick={toggleMenu}
                >
                                  {t('Nav.LinkThree')}

                </a>
              </li>
            </ul>
            <div
              className={`toggle ${isMenuOpen ? "open" : ""}`}
              onClick={toggleMenu}
            >
              <span className="bars"></span>
              <span className="bars"></span>
              <span className="bars"></span>
            </div>
            <LanguageSwitcher />

          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
