import React from "react";
import { useInView } from "react-intersection-observer";
import src from '../assets/files/fun-work.mp4';

const VideoPlayer = () => {
  const [ref, inView] = useInView({ threshold: 0 });


  return (
    <div ref={ref}>
      {inView && (
        <video
          src={src}
          muted
          autoPlay
          loop
          height="400px"
        />
      )}
    </div>
  );
};

export default VideoPlayer;
