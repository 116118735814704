import React from "react";
import { useTranslation } from "react-i18next";
import AnimatedServices from "./AnimatedServices";
import AnimatedElement from "./reusables/BasicSpringAnimation";

const Services = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="services" id={t('Nav.hrefOne')}>
        <div className="container">
          <AnimatedElement tag="h2" delay={0}>
          {t('Services.ServicesTitle')}
          </AnimatedElement>

          <ul>
            <AnimatedElement tag="li" delay={200} className="services__card">
              <div className="icon">
                <svg
                  version="1.1"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#fff"
                >
                  <path
                    d="M469.046,56.334c-3.502-3.517-8.349-5.688-13.684-5.688H84.313
                                            c-5.352,0-10.185,2.171-13.701,5.688s-5.685,8.378-5.685,13.698v267.181c0,5.32,2.186,10.182,5.685,13.698
                                            c3.517,3.517,8.381,5.687,13.701,5.687h371.049c5.32,0,10.182-2.17,13.684-5.687c3.517-3.517,5.699-8.378,5.699-13.698V70.032
                                            C474.745,64.712,472.563,59.851,469.046,56.334
                                            M146.733,439.461v10.824h246.191v-10.824
                                            C310.855,439.461,228.803,439.461,146.733,439.461z
                                            M451.004,284.773V74.191H87.781v210.582H451.004z
                                            M458.511,70.43v218.104
                                            c0,2.048-1.682,3.761-3.745,3.761H84.022c-2.063,0-3.762-1.713-3.762-3.761V70.43c0-2.079,1.698-3.762,3.762-3.762h370.744
                                            C456.829,66.668,458.511,68.351,458.511,70.43z
                                            M314.449,364.119h-89.226l-17.839,67.82h124.906L314.449,364.119z
                                            M273.366,332.718c-0.916-0.888-2.154-1.438-3.53-1.438s-2.614,0.55-3.499,1.438c-0.919,0.917-1.471,2.14-1.471,3.517
                                            c0,1.375,0.552,2.629,1.439,3.516c0.916,0.887,2.154,1.438,3.53,1.438s2.614-0.551,3.503-1.407
                                            c0.916-0.917,1.467-2.171,1.467-3.546C274.806,334.857,274.255,333.635,273.366,332.718z
                                            M269.836,323.759
                                            c3.426,0,6.545,1.406,8.806,3.669c2.277,2.264,3.671,5.381,3.671,8.807c0,3.424-1.394,6.543-3.653,8.807
                                            c-2.278,2.262-5.397,3.668-8.823,3.668c-3.439,0-6.56-1.406-8.806-3.639c-2.277-2.293-3.671-5.412-3.671-8.836
                                            c0-3.426,1.394-6.543,3.657-8.807C263.276,325.165,266.396,323.759,269.836,323.759z
                                            M143.417,247.652l-12.445-12.444
                                            l-12.413,12.414l12.427,12.445L143.417,247.652z
                                            M133.632,227.228l17.551,17.551c0.656-0.55,1.558-0.917,2.432-0.917h7.507
                                            c2.72,0,4.632,2.905,3.376,5.412c-0.474,1.193-1.955,2.109-3.376,2.109h-7.507c-0.934,0-1.79-0.336-2.432-0.887l-17.551,17.521
                                            c-1.467,1.467-3.853,1.438-5.289,0l-17.751-17.735c-1.467-1.468-1.467-3.853,0-5.32l17.719-17.704
                                            C129.779,225.79,132.166,225.79,133.632,227.228z
                                            M420.229,247.622l-12.413-12.414l-12.325,12.506l12.294,12.354L420.229,247.622z
                                            M410.658,227.441l17.537,17.551c1.449,1.469,1.449,3.822-0.018,5.29l-17.72,17.735c-1.467,1.467-3.853,1.467-5.303,0
                                            l-17.737-17.735c-1.467-1.437-1.467-3.821-0.014-5.29l17.751-17.765c1.45-1.468,3.836-1.468,5.303,0L410.658,227.441z
                                            M191.178,251.383c1.421,0,2.906-0.916,3.38-2.109c1.253-2.507-0.66-5.412-3.38-5.412h-7.521c-1.407,0-2.892,0.947-3.365,2.11
                                            c-1.253,2.507,0.66,5.411,3.365,5.411H191.178z
                                            M221.237,251.383c1.404,0,2.902-0.916,3.362-2.109
                                            c1.253-2.507-0.642-5.412-3.362-5.412h-7.524c-1.407,0-2.902,0.947-3.362,2.11c-1.253,2.507,0.642,5.411,3.362,5.411H221.237z
                                            M251.292,251.383c1.407,0,2.906-0.916,3.362-2.109c1.256-2.507-0.643-5.412-3.362-5.412h-7.521c-1.421,0-2.906,0.947-3.379,2.11
                                            c-1.253,2.507,0.656,5.411,3.379,5.411H251.292z
                                            M281.333,251.383c1.422,0,2.906-0.916,3.38-2.109
                                            c1.253-2.507-0.66-5.412-3.38-5.412h-7.507c-1.421,0-2.905,0.947-3.38,2.11c-1.253,2.507,0.66,5.411,3.38,5.411H281.333z
                                            M311.393,251.383c1.421,0,2.888-0.916,3.376-2.109c1.253-2.507-0.656-5.412-3.376-5.412h-7.508c-1.438,0-2.905,0.947-3.394,2.11
                                            c-1.238,2.507,0.671,5.411,3.394,5.411H311.393z
                                            M341.434,251.383c1.436,0,2.902-0.916,3.394-2.109
                                            c1.253-2.507-0.674-5.412-3.394-5.412h-7.507c-1.421,0-2.905,0.947-3.38,2.11c-1.253,2.507,0.656,5.411,3.38,5.411H341.434z
                                            M371.488,251.383c1.422,0,2.906-0.916,3.381-2.109c1.252-2.507-0.656-5.412-3.381-5.412h-7.506c-1.422,0-2.906,0.947-3.38,2.11
                                            c-1.253,2.507,0.659,5.411,3.38,5.411H371.488z
                                            M231.418,152.528l-47.701-12.78v61.917l47.701,12.813V152.528z
                                            M238.924,152.528
                                            v61.949c15.916-4.28,31.782-8.53,47.684-12.813v-37.151v-0.091v-0.092v-6.36l-20.152-5.412c-1.681-0.428-2.779-1.957-2.779-3.607
                                            v-3.058L238.924,152.528z
                                            M309.479,113.758v40.574l20.653-5.534l10.139-2.721v-40.606L309.479,113.758z
                                            M281.898,100.578
                                            l23.818,6.391l23.837-6.391l-23.837-6.39L281.898,100.578z
                                            M294.129,169.346v35.193c-0.015,1.651-1.13,3.181-2.797,3.64
                                            l-12.291,3.302l33.281,8.929v-46.202L294.129,169.346z
                                            M319.847,174.207v46.202l35.222-9.448V164.76L319.847,174.207z
                                            M359.563,156.197h0.091l0.092,0.031l0.277,0.092l0,0l0.168,0.061l0,0h0.061l0.018,0.031h0.027
                                            c0.169,0.061,0.323,0.152,0.46,0.244h0.032l0.059,0.062l0,0l0.046,0.03h0.018l0.028,0.03l0.154,0.093l0.046,0.03l0,0l0.046,0.061
                                            h0.031l0.014,0.03l0.046,0.031l0.046,0.031l0.018,0.03l0.027,0.03l0.046,0.029l0,0l0.046,0.031l0.018,0.031l0.014,0.03l0.046,0.03
                                            l0,0l0.046,0.063h0.018l0.014,0.029l0.045,0.062h0.015l0.032,0.03l0.014,0.031l0.018,0.03l0.031,0.03h0.014l0.046,0.061v0.031
                                            l0.032,0.031l0.027,0.03l0,0l0.031,0.061h0.015l0.031,0.062l0.015,0.031h0.018l0.031,0.061h0.014l0.014,0.061l0.018,0.031h0.015
                                            l0.032,0.061h0.014l0.014,0.063l0.018,0.029l0.014,0.031l0.032,0.061l0.014,0.062h0.032v0.03l0.031,0.061l0,0l0.027,0.063v0.03
                                            l0.018,0.03l0.047,0.123v0.029l0.014,0.031l0.014,0.031v0.029l0.031,0.062l0,0v0.062l0.018,0.031l0.015,0.061v0.03l0.031,0.062
                                            v0.03v0.062l0.028,0.122l0,0l0.018,0.091l0,0l0.014,0.092l0,0v0.093v0.03v0.061l0.014,0.031v0.062v0.03v0.061v0.031v0.061
                                            c0,49.137,0,22.016,0,53.907c0,1.651-1.115,3.18-2.797,3.639l-42.531,11.405l-0.046,0.03h-0.014l-0.063,0.03h-0.091h-0.015
                                            l-0.045,0.031h-0.031h-0.032l-0.06,0.031h-0.031h-0.061l0,0l-0.076,0.029h-0.018h-0.061h-0.045h-0.015l-0.077,0.031h-0.018h-0.06
                                            h-0.014h-0.063h-0.027h-0.05h-0.06h-0.014h-0.078h-0.076h-0.015h-0.063h-0.045h-0.028h-0.063h-0.014h-0.077l0,0l-0.077-0.031
                                            h-0.028h-0.032h-0.063h-0.014l-0.078-0.029l0,0h-0.076h-0.015l-0.06-0.031h-0.031h-0.032l-0.06-0.031h-0.018h-0.074l-0.063-0.03
                                            h-0.014l-0.046-0.03l-42.532-11.405c-1.621-0.459-2.723-1.865-2.783-3.455l-33.266,8.929l-0.063,0.03h-0.014h-0.077l-0.06,0.03
                                            h-0.032h-0.045l-0.045,0.031h-0.032l-0.06,0.03l0,0h-0.078h-0.017h-0.06l-0.031,0.03h-0.028h-0.063h-0.014h-0.077l0,0l-0.077,0.03
                                            h-0.014h-0.063h-0.028h-0.045h-0.046h-0.031h-0.078h-0.077h-0.014h-0.046h-0.063h-0.028h-0.063l0,0l-0.091-0.03l0,0h-0.06h-0.032
                                            h-0.045h-0.046l-0.031-0.03h-0.06l0,0h-0.091l0,0l-0.063-0.03h-0.031l-0.046-0.031h-0.045h-0.028l-0.063-0.03h-0.06h-0.032
                                            l-0.046-0.03l-55.008-14.769c-1.667-0.459-2.783-1.988-2.783-3.64l-0.014-69.684c0-0.918,0.337-1.743,0.87-2.416h0.018
                                            l0.046-0.061h0.014l0.032-0.062h0.028l0.031-0.062h0.032l0.105-0.122l0.018-0.03l0.06-0.031v-0.03l0.063-0.03v-0.03l0.06-0.03
                                            l0.032-0.031l0.045-0.031h0.014l0.063-0.061h0.014l0.046-0.061h0.031l0.046-0.031l0.074-0.062h0.032l0.246-0.152l0.045-0.03h0.032
                                            l0.045-0.031l0.028-0.031l0.14-0.061h0.046l0.028-0.03l0.186-0.062h0.046l0.305-0.091l0.091-0.03h0.014l54.98-14.77
                                            c0.611-0.152,1.285-0.184,1.927,0l27.549,7.4v-23.209c0-0.887,0.319-1.742,0.856-2.385l0,0l0.06-0.061v-0.031l0.06-0.029v-0.031
                                            l0.063-0.062l0,0l0.123-0.122h0.014l0.046-0.062h0.014l0.046-0.062h0.031l0.063-0.061l0,0l0.06-0.061h0.014l0.046-0.031
                                            l0.031-0.031l0.046-0.03h0.018l0.06-0.061l0.077-0.03l0.015-0.031l0.245-0.121l0.06-0.031l0.032-0.031l0.045-0.03h0.046
                                            l0.123-0.062L266,97.092h0.06l0.169-0.061l0.045-0.03l0.306-0.092h0.092l0,0l38.085-10.243c0.61-0.152,1.284-0.184,1.94,0
                                            l38.067,10.243h0.018h0.092c0.564,0.153,1.084,0.397,1.512,0.765h0.032l0.015,0.03l0.045,0.03l0,0l0.046,0.062l0.291,0.276
                                            l0.046,0.061l0,0l0.045,0.029l0.018,0.031l0.015,0.031l0.091,0.091v0.03l0.092,0.123h0.018l0.014,0.061l0.031,0.031h0.015
                                            l0.031,0.061h0.015l0.014,0.063l0.123,0.213l0.018,0.061h0.014l0.046,0.123l0.031,0.031v0.029l0.091,0.245l0.015,0.03
                                            c0.123,0.367,0.2,0.765,0.2,1.162v48.373c-0.018,1.498-0.92,2.844-2.295,3.455L359.563,156.197z
                                            M316.084,167.45l28.27-7.583
                                            l-13.256-3.547l-22.812,6.115l-1.39,0.367l-0.046,0.031h-0.031l-0.06,0.029h-0.077h-0.018h-0.028l-0.031,0.031h-0.032h-0.028
                                            l-0.063,0.029h-0.015h-0.077l0,0h-0.06v0.031H306.3h-0.046h-0.046h-0.031l-0.06,0.031H306.1h-0.077l0,0h-0.06h-0.046h-0.046
                                            h-0.045h-0.018h-0.092h-0.06h-0.031h-0.06h-0.032h-0.031h-0.06h-0.031l0,0h-0.06l0,0l-0.077-0.031h-0.032h-0.046h-0.045h-0.032
                                            l-0.06-0.031h-0.014l0,0h-0.063h-0.014l-0.063-0.029h-0.028l0,0h-0.045l-0.046-0.031h-0.018h-0.077l-0.06-0.029h-0.014
                                            l-0.063-0.031
                                            M271.197,140.909V141v0.093v4.984l19.906,5.351h0.092l0.228,0.061l0.077,0.031l0.077,0.031h0.028l0.077,0.03
                                            l10.275,2.751v-40.574l-30.761-8.287v23.116v0.092V140.909z
                                            M194.435,134.855l40.728,10.916l28.515-7.645v-6.574l-28.515-7.645
                                            L194.435,134.855z
                                            M384.116,126.997c0,1.406,0.919,2.905,2.112,3.364c2.506,1.252,5.395-0.643,5.395-3.364v-7.522
                                            c0-1.406-0.916-2.904-2.109-3.363c-2.506-1.254-5.397,0.643-5.397,3.363V126.997z
                                            M384.116,157.055
                                            c0,1.406,0.919,2.873,2.112,3.362c2.506,1.254,5.395-0.642,5.395-3.362v-7.522c0-1.437-0.916-2.905-2.109-3.395
                                            c-2.506-1.254-5.397,0.673-5.397,3.395V157.055z
                                            M384.116,187.08c0,1.438,0.919,2.905,2.112,3.395
                                            c2.506,1.254,5.395-0.672,5.395-3.395v-7.49c0-1.438-0.916-2.905-2.109-3.395c-2.506-1.254-5.397,0.672-5.397,3.395V187.08z
                                            M85.84,471.902c-2.078,0-3.759-1.681-3.759-3.76c0-2.08,1.681-3.762,3.759-3.762h231.775c2.077,0,3.758,1.682,3.758,3.762
                                            c0,2.079-1.681,3.76-3.758,3.76H85.84z
                                            M221.556,485.999c-2.064,0-3.745-1.682-3.745-3.761s1.681-3.762,3.745-3.762h160.405
                                            c2.064,0,3.745,1.683,3.745,3.762s-1.681,3.761-3.745,3.761H221.556z
                                            M398.365,485.999c-2.064,0-3.76-1.682-3.76-3.761
                                            s1.695-3.762,3.76-3.762h20.06c2.078,0,3.763,1.683,3.763,3.762s-1.685,3.761-3.763,3.761H398.365z
                                            M44.775,170.018
                                            c0,2.08-1.695,3.762-3.759,3.762c-2.081,0-3.762-1.682-3.762-3.762V55.508c0-8.102,3.317-15.502,8.655-20.854
                                            c5.348-5.32,12.75-8.652,20.867-8.652h65.786c2.078,0,3.762,1.682,3.762,3.761s-1.685,3.761-3.762,3.761H66.776
                                            c-6.054,0-11.557,2.477-15.547,6.451c-3.977,3.977-6.454,9.51-6.454,15.533V170.018z
                                            M129.842,485.999
                                            c-2.081,0-3.762-1.682-3.762-3.761s1.681-3.762,3.762-3.762h63.936c2.063,0,3.745,1.683,3.745,3.762s-1.681,3.761-3.745,3.761
                                            H129.842z
                                            M391.623,89.418c0-1.406-0.916-2.904-2.109-3.363c-2.506-1.254-5.397,0.642-5.397,3.363v7.521
                                            c0,1.406,0.919,2.905,2.112,3.364c2.506,1.254,5.395-0.642,5.395-3.364V89.418z"
                  />
                </svg>
              </div>
              <h3 className="services__title">{t('Services.MainServices.ServiceOneTitle')}</h3>
              <p className="services__text">
              {t('Services.MainServices.ServiceOneText')}
              </p>
            </AnimatedElement>
            <AnimatedElement tag="li" delay={400} className="services__card">
              <div className="icon">
                <svg
                  version="1.1"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#fff"
                >
                  <path
                    d="M366.16,156.877v-44.65h5.921v44.65H366.16z
                                                                        M329.471,133.443h11.555
                                                                        c3.829,0,6.512-0.717,8.123-2.147c1.609-1.431,2.414-3.435,2.414-6.011c0-1.859-0.482-3.471-1.412-4.794
                                                                        c-0.949-1.324-2.201-2.218-3.722-2.647c-1.003-0.25-2.862-0.394-5.528-0.394h-11.43V133.443z
                                                                        M323.566,156.877v-44.65h16.852
                                                                        c2.97,0,5.223,0.143,6.799,0.429c2.181,0.358,4.043,1.073,5.51,2.075c1.502,1.037,2.683,2.469,3.596,4.294
                                                                        c0.912,1.86,1.34,3.864,1.34,6.081c0,3.793-1.198,7.014-3.613,9.625c-2.414,2.611-6.797,3.936-13.113,3.936h-11.465v18.211
                                                                        H323.566z
                                                                        M289.899,138.559h15.224l-4.669-12.379c-1.396-3.793-2.47-6.905-3.186-9.338c-0.626,2.897-1.43,5.76-2.469,8.586
                                                                        L289.899,138.559z
                                                                        M277.054,156.877l17.121-44.65h6.493l18.193,44.65h-6.727l-5.169-13.523h-18.803l-4.848,13.523H277.054z
                                                                        M387.788,261.992c-2.755,1.719-5.135,3.9-7.049,6.477c-3.076,4.15-4.956,9.23-5.045,14.776c-0.09,5.724,1.7,11.021,4.776,15.313
                                                                        c1.951,2.754,4.455,5.079,7.317,6.833V261.992z
                                                                        M387.788,377.305H133.442v51.735c0,4.222,1.718,8.05,4.508,10.841
                                                                        c2.772,2.79,6.619,4.508,10.823,4.508h223.666c4.204,0,8.051-1.718,10.822-4.508c2.773-2.791,4.526-6.619,4.526-10.841V377.305z
                                                                        M267.789,403.53c-1.843-1.824-4.384-2.934-7.192-2.934c-2.79,0-5.331,1.109-7.154,2.934c-1.825,1.824-2.971,4.365-2.971,7.191
                                                                        c0,2.791,1.146,5.331,2.971,7.155c1.823,1.825,4.364,2.971,7.154,2.971c2.72,0,5.151-1.038,6.977-2.755l0.196-0.216
                                                                        c1.825-1.824,2.971-4.364,2.971-7.155C270.74,407.896,269.612,405.354,267.789,403.53z
                                                                        M260.597,391.795
                                                                        c5.224,0,9.947,2.111,13.382,5.546c3.434,3.435,5.545,8.158,5.545,13.381c0,5.224-2.111,9.946-5.545,13.381l-0.304,0.287
                                                                        c-3.399,3.255-7.997,5.259-13.078,5.259c-5.222,0-9.946-2.111-13.38-5.546c-3.417-3.435-5.547-8.157-5.547-13.381
                                                                        c0-5.223,2.13-9.946,5.547-13.381C250.651,393.906,255.375,391.795,260.597,391.795z
                                                                        M223.854,142.529
                                                                        c-1.002,0.466-1.897,1.109-2.685,1.897c-0.679,0.679-1.251,1.431-1.698,2.289l-0.018,0.036c-0.233,0.429-0.43,0.859-0.572,1.324
                                                                        l-0.019,0.035l-0.125,0.43l0,0l-0.035,0.107l0,0c-0.126,0.465-0.216,0.965-0.288,1.502v0.037v0.07v0.036v0.071v0.037l-0.035,0.213
                                                                        v0.037v0.5v0.107l0,0v0.107v0.035v0.108l0,0v0.106l0,0l0.018,0.107v0.036l0.018,0.106l0,0v0.108v0.036v0.071v0.036l0.02,0.106
                                                                        h0.018v0.108v0.035l0.018,0.072v0.035l0.018,0.071v0.036l0.017,0.107v0.035l0.02,0.072v0.035l0.018,0.072l0.018,0.035v0.071v0.036
                                                                        l0.035,0.107v0.035l0.019,0.072l0.018,0.036v0.071l0.018,0.035l0.018,0.072v0.035l0.037,0.072v0.035l0.035,0.072v0.071
                                                                        l0.018,0.071l0.018,0.035l0.02,0.072l0.018,0.035l0.018,0.072l0.018,0.036l0.018,0.071l0.019,0.035l0.018,0.072l0.018,0.035
                                                                        l0.018,0.072l0.018,0.035l0.037,0.072v0.035l0.035,0.072v0.035l0.035,0.072l0.02,0.035l0.018,0.071l0.035,0.036v0.072l0.037,0.035
                                                                        l0.018,0.071l0.018,0.036l0.035,0.071l0.037,0.036v0.035l0.035,0.071l0.018,0.036l0.037,0.072l0.018,0.035l0.035,0.072l0.02,0.035
                                                                        l0.018,0.071l0.035,0.036l0.037,0.072v0.035l0.035,0.035l0.035,0.036l0.037,0.071l0.018,0.035l0.035,0.072l0.018,0.036
                                                                        l0.037,0.071h0.035l0.037,0.072v0.035l0.053,0.071l0.018,0.036l0.037,0.072h0.018l0.125,0.178v0.036l0.054,0.071h0.018
                                                                        l0.055,0.072l0.018,0.035l0.035,0.072h0.037l0.035,0.07l0.018,0.037l0.054,0.071h0.018l0.055,0.071l0.018,0.036l0.052,0.071h0.02
                                                                        l0.053,0.071h0.018l0.054,0.107h0.018l0.054,0.071h0.018l0.072,0.072l0,0l0.089,0.107l0,0l0.07,0.07l0.072,0.072l0,0l0.072,0.071
                                                                        l0.162,0.179h0.018c1.681,1.61,4.008,2.612,6.564,2.612h0.591l0,0c0.43-0.036,0.841-0.071,1.251-0.179l0,0l0.216-0.036h0.018
                                                                        l0.089-0.035h0.035l0.072-0.036h0.037h0.07l0.448-0.143h0.018l0.196-0.072h0.02l0.07-0.036h0.037l0.072-0.035l0.107-0.036h0.035
                                                                        l0.072-0.035h0.035l0.072-0.037h0.037l0.07-0.035l0.037-0.035h0.07l0.037-0.036l0.072-0.036h0.018l0.072-0.035h0.035l0.055-0.035
                                                                        l0.035-0.037l0.072-0.035h0.035l0.072-0.036l0.035-0.035h0.054l0.035-0.036l0.072-0.035l0.035-0.037h0.055l0.035-0.035
                                                                        l0.072-0.035h0.037l0.035-0.036l0.055-0.036l0.053-0.036l0.037-0.035h0.07l0.037-0.036l0.035-0.035l0.055-0.037l0.052-0.035
                                                                        l0.037-0.035h0.053l0.054-0.036l0.358-0.251l0.035-0.035c0.323-0.25,0.609-0.501,0.895-0.752l0.072-0.07l0.07-0.072v-0.036
                                                                        c0.644-0.608,1.181-1.358,1.628-2.146l0.018-0.037l0.305-0.607l0.018-0.036l0.072-0.179l0.018-0.036l0.018-0.071l0.035-0.035
                                                                        l0.02-0.072l0.018-0.035l0.035-0.072v-0.035l0.037-0.072l0,0l0.07-0.215l0,0l0.037-0.106l0.035-0.108l0,0l0.035-0.107v-0.035
                                                                        l0.037-0.071v-0.036l0.035-0.072v-0.035l0.018-0.107l0.02-0.07l0.018-0.037l0.018-0.106l0,0l0.035-0.108v-0.035l0.019-0.071
                                                                        v-0.036l0.018-0.071v-0.036l0.035-0.072v-0.035v-0.071l0.018-0.071l0.019-0.035v-0.072v-0.036l0.018-0.071l0.018-0.072v-0.07
                                                                        v-0.036v-0.072l0.018-0.036l0.018-0.07v-0.036v-0.107l0,0v-0.108v-0.035l0.02-0.071v-0.036l0.018-0.107l0,0v-0.106l0,0v-0.108
                                                                        v-0.035v-0.107l0,0v-0.107c0-2.647-1.074-5.008-2.772-6.726l-0.09-0.108l-0.072-0.07l-0.072-0.072h-0.018l-0.09-0.07l-0.072-0.072
                                                                        l-0.089-0.072l0,0l-0.09-0.071l-0.072-0.071l-0.107-0.071l-0.179-0.144l-0.089-0.072l0,0l-0.089-0.07l-0.09-0.072l-0.089-0.072
                                                                        l0,0l-0.107-0.07l0,0l-0.089-0.036l0,0l-0.09-0.071l-0.107-0.072l-0.179-0.106h-0.018l-0.09-0.072l0,0l-0.107-0.036l0,0
                                                                        l-0.216-0.106l-0.088-0.072l0,0l-0.089-0.035H232.1l-0.197-0.108l0,0l-0.107-0.035v-0.035l-0.214-0.072l0,0l-0.107-0.035l0,0
                                                                        l-0.109-0.035l0,0l-0.107-0.072l-0.107-0.036l-0.214-0.071h-0.02l-0.088-0.035h-0.019l-0.107-0.037l0,0l-0.107-0.035h-0.018
                                                                        l-0.323-0.071l0,0l-0.107-0.036h-0.018l-0.107-0.036h-0.018l-0.109-0.036l0,0l-0.107-0.035h-0.107h-0.018l-0.107-0.035h-0.126l0,0
                                                                        l-0.107-0.036h-0.125l-0.126-0.036h-0.107l-0.125-0.035h-0.125h-0.109h-0.125l0,0l-0.125-0.036h-0.107h-0.144h-0.107h-0.126
                                                                        h-0.125h-0.125h-0.126h-0.107l-0.125,0.036h-0.018h-0.109l0,0h-0.125h-0.125l-0.107,0.035h-0.126l-0.107,0.036h-0.017h-0.107l0,0
                                                                        l-0.108,0.036h-0.018h-0.107l0,0l-0.125,0.035l0,0h-0.107l0,0l-0.109,0.035h-0.018l-0.107,0.036h-0.018l-0.107,0.036l0,0
                                                                        l-0.107,0.036l0,0l-0.323,0.071h-0.035l-0.197,0.072h-0.02l-0.089,0.035h-0.018l-0.214,0.071l0,0l-0.107,0.036l0,0l-0.109,0.072
                                                                        l0,0l-0.214,0.07l0,0L223.854,142.529L223.854,142.529z
                                                                        M60.938,160.634h0.02h0.107h0.107h0.018h0.125l0,0h0.109h0.107h0.018
                                                                        h0.107h0.018l0.109-0.036l0,0h0.107h0.018h0.09h0.035h0.09h0.018l0.089-0.035h0.037h0.09h0.018l0.089-0.035h0.035h0.072h0.035
                                                                        l0.072-0.037h0.055h0.072l0.035-0.035h0.089h0.035l0.072-0.036h0.037h0.07h0.037l0.07-0.035h0.054l0.055-0.036h0.053h0.054
                                                                        l0.053-0.036h0.054l0.072-0.036h0.035h0.072l0.035-0.035l0.072-0.035h0.037h0.07l0.037-0.037h0.07l0.037-0.035h0.072l0.035-0.036
                                                                        h0.072l0.035-0.035l0.072-0.036h0.035l0.072-0.035h0.037l0.07-0.037h0.037l0.07-0.035h0.02l0.089-0.035h0.018l0.089-0.072h0.018
                                                                        l0.09-0.035l0,0l0.107-0.035l0,0l0.107-0.072l0,0l0.089-0.036l0.09-0.035l0.107-0.071l0.109-0.037l0,0l0.089-0.07h0.018
                                                                        l0.072-0.036l0.018-0.036l0.089-0.036l0,0l0.09-0.035l0.018-0.036l0.072-0.035h0.018l0.089-0.072l0,0l0.072-0.035l0.035-0.036
                                                                        l0.072-0.036l0.018-0.035l0.072-0.035h0.018l0.072-0.037l0.035-0.035l0.035-0.036l0.037-0.036l0.072-0.035l0.035-0.035
                                                                        l0.035-0.037l0.037-0.035l0.072-0.035l0.035-0.036l0.035-0.036l0.037-0.035l0.052-0.037l0.037-0.035l0.053-0.035l0.037-0.036
                                                                        h0.035l0.054-0.071h0.035l0.055-0.072l0.035-0.035l0.035-0.037l0.037-0.034l0.053-0.036l0.019-0.036l0.07-0.036l0.144-0.179
                                                                        c0.393-0.394,0.769-0.822,1.091-1.288l0,0l0.053-0.107h0.019l0.232-0.393l0,0l0.179-0.287l0,0l0.055-0.107l0.107-0.178h0.018
                                                                        l0.035-0.107l0,0l0.054-0.108l0.055-0.106l0.035-0.107l0.055-0.107l0.053-0.107l0.035-0.107l0,0l0.037-0.107l0,0l0.053-0.107
                                                                        l0.037-0.107l0.035-0.107l0.055-0.107l0.035-0.106l0.037-0.108l0,0l0.035-0.143l0.018-0.107l0.037-0.107l0.018-0.107l0.035-0.107
                                                                        l0.037-0.106l0.035-0.144l0.018-0.107l0.018-0.107l0.02-0.107l0.018-0.143l0.035-0.107v-0.107l0.018-0.143l0.02-0.108l0.018-0.106
                                                                        l0.018-0.144v-0.108v-0.106l0.018-0.144v-0.106v-0.108l0.018-0.143v-0.107v-0.143l0,0c-0.035-2.576-1.109-4.902-2.79-6.583
                                                                        c-3.685-3.686-9.75-3.686-13.435,0c-1.718,1.718-2.772,4.078-2.772,6.726c0,2.612,1.037,5.01,2.755,6.69
                                                                        C56.019,159.561,58.362,160.598,60.938,160.634z
                                                                        M70.58,318.057L70.58,318.057v-0.107v-0.107v-0.035v-0.107h-0.018v-0.107l0,0
                                                                        v-0.107v-0.035l-0.018-0.107l0,0v-0.107v-0.037v-0.07v-0.036l-0.018-0.106l0,0l-0.018-0.108v-0.035v-0.072l-0.02-0.036
                                                                        l-0.018-0.07v-0.036v-0.107v-0.036l-0.035-0.072v-0.035v-0.071l-0.018-0.036l-0.02-0.107l0,0l-0.018-0.106v-0.036l-0.018-0.072
                                                                        v-0.035l-0.035-0.071v-0.036l-0.019-0.071l-0.018-0.036l-0.018-0.071l-0.018-0.036v-0.107l-0.018-0.035l-0.019-0.072v-0.035
                                                                        l-0.035-0.072v-0.035l-0.035-0.072v-0.035l-0.037-0.071v-0.036l-0.035-0.072v-0.035l-0.037-0.071v-0.036l-0.035-0.071
                                                                        l-0.018-0.036l-0.018-0.071l-0.019-0.071l-0.018-0.036l-0.035-0.071v-0.035l-0.037-0.072l-0.017-0.036l-0.018-0.071l-0.035-0.035
                                                                        v-0.072l-0.037-0.035l-0.018-0.036l-0.035-0.072l-0.017-0.071l-0.037-0.035l-0.018-0.036l-0.018-0.071l-0.037-0.036l-0.018-0.036
                                                                        l-0.018-0.07l-0.035-0.036l-0.037-0.072v-0.035l-0.035-0.071l-0.037-0.037l-0.035-0.07v-0.036l-0.054-0.071h-0.018l-0.035-0.072
                                                                        l-0.018-0.035l-0.037-0.072l-0.018-0.035l-0.035-0.072l-0.037-0.035l-0.035-0.035l-0.018-0.036l-0.055-0.072v-0.036l-0.053-0.07
                                                                        h-0.019l-0.035-0.072l-0.035-0.036l-0.037-0.071l-0.018-0.035l-0.055-0.072l0,0l-0.07-0.071v-0.036l-0.072-0.07l0,0l-0.072-0.072
                                                                        v-0.036l-0.072-0.071l0,0l-0.07-0.072v-0.035l-0.072-0.071l0,0l-0.072-0.072l0,0l-0.072-0.106l0,0l-0.072-0.072l0,0l-0.07-0.071
                                                                        l-0.09-0.106l-0.072-0.072l0,0l-0.089-0.072c-1.718-1.717-4.098-2.79-6.726-2.79h-0.107h-0.02
                                                                        c-2.557,0.036-4.883,1.109-6.582,2.79c-1.718,1.719-2.772,4.115-2.772,6.727c0,2.647,1.037,5.01,2.755,6.727
                                                                        c1.716,1.718,4.096,2.755,6.726,2.755c2.521,0,4.829-0.966,6.512-2.54l0.214-0.215C69.471,322.959,70.545,320.633,70.58,318.057z
                                                                        M221.169,311.223c-0.465,0.466-0.875,0.967-1.233,1.503l0,0l-0.179,0.286l0,0l-0.161,0.287l0,0l-0.053,0.07l-0.018,0.037
                                                                        l-0.037,0.071l-0.018,0.035l-0.035,0.072l-0.02,0.036l-0.035,0.07l-0.018,0.036l-0.018,0.036l-0.037,0.071l-0.018,0.036
                                                                        l-0.018,0.072l-0.037,0.034l-0.018,0.072l-0.018,0.036l-0.035,0.035l-0.02,0.072l-0.018,0.035l-0.018,0.071l-0.035,0.036
                                                                        l-0.02,0.035l-0.018,0.072l-0.018,0.035l-0.035,0.072v0.036l-0.037,0.106l0,0l-0.035,0.107l0,0l-0.035,0.107l0,0l-0.037,0.107l0,0
                                                                        l-0.035,0.107l0,0l-0.037,0.107l0,0l-0.035,0.107l0,0l-0.035,0.107l0,0l-0.02,0.107v0.035l-0.018,0.072l-0.018,0.035l-0.018,0.071
                                                                        v0.036l-0.037,0.072v0.035v0.072l-0.035,0.035v0.071v0.071l-0.035,0.072v0.035v0.072l-0.02,0.035l-0.017,0.072v0.035v0.071
                                                                        l-0.018,0.072l-0.018,0.036v0.07v0.036v0.072l-0.018,0.071l-0.02,0.072v0.035v0.071v0.036v0.07v0.037l-0.018,0.107v0.035
                                                                        l-0.018,0.072v0.035v0.107l0,0v0.107l0,0v0.107v0.035v0.107v0.358v0.106v0.037l0.035,0.465l0.02,0.07l0.053,0.43v0.037
                                                                        l0.072,0.356v0.072l0.018,0.036l0.018,0.07v0.036l0.037,0.072v0.035l0.018,0.071l0.018,0.037v0.106h0.018l0.02,0.106l0,0
                                                                        l0.035,0.108l0,0l0.035,0.107l0,0l0.037,0.106l0,0l0.035,0.108v0.035l0.037,0.071v0.037l0.035,0.07l0,0l0.035,0.143l0,0
                                                                        l0.251,0.609v0.035l0.054,0.072h0.018c0.465,1.002,1.109,1.932,1.877,2.719l0.072,0.072l0.089,0.07l0.035,0.072h0.037l0.053,0.071
                                                                        h0.037l0.052,0.071l0.037,0.036l0.035,0.035l0.037,0.036l0.053,0.036l0.035,0.036l0.054,0.035l0.035,0.035l0.037,0.037
                                                                        l0.052,0.035l0.037,0.036l0.053,0.036l0.037,0.035l0.053,0.035h0.02l0.07,0.072h0.037l0.072,0.071l0,0l0.07,0.036l0.037,0.035
                                                                        l0.07,0.037v0.035l0.089,0.035h0.02l0.07,0.072l0,0l0.109,0.071l0.089,0.036l0,0l0.089,0.071l0,0l0.107,0.035v0.036l0.09,0.036
                                                                        h0.017l0.072,0.036l0.035,0.035l0.072,0.035h0.018l0.072,0.037l0.018,0.035l0.072,0.036h0.035l0.072,0.035l0.035,0.036
                                                                        l0.072,0.035h0.035l0.054,0.037l0.055,0.035h0.053l0.054,0.035h0.035l0.072,0.036l0.035,0.036l0.216,0.07l0.07,0.037h0.037
                                                                        l0.072,0.035h0.018l0.089,0.036h0.018l0.089,0.035l0.018,0.036h0.089l0.018,0.036h0.089h0.02l0.088,0.071h0.019h0.09l0.125,0.035
                                                                        l0,0l0.107,0.036l0,0l0.107,0.036h0.02l0.089,0.036h0.018h0.089l0.018,0.035h0.107h0.018l0.089,0.036h0.037h0.07l0.037,0.035
                                                                        h0.072h0.035l0.072,0.037h0.053h0.054h0.072l0.053,0.035h0.055h0.072h0.053h0.054l0.072,0.035h0.035h0.072h0.035h0.089h0.035
                                                                        l0.089,0.036h0.037h0.072h0.035h0.107h0.018h0.089h0.02h0.107h0.018h0.107l0,0h0.125h0.126l0,0h0.107l0,0h0.107h0.037h0.087h0.02
                                                                        h0.107h0.018h0.089h0.035l0.09-0.036h0.019h0.089h0.053h0.072h0.035l0.072-0.035h0.054h0.053h0.072h0.035l0.072-0.035h0.054h0.072
                                                                        h0.035l0.072-0.037h0.052h0.072l0.035-0.035h0.072h0.037l0.09-0.036h0.018h0.107v-0.035h0.107l0,0l0.107-0.036l0,0l0.107-0.036
                                                                        h0.02l0.107-0.036l0,0l0.214-0.035h0.018l0.089-0.071h0.019h0.088l0.02-0.036h0.07l0.037-0.036l0.607-0.214l0.037-0.036
                                                                        c0.572-0.215,1.109-0.537,1.591-0.858h0.018l0.34-0.287h0.018l0.072-0.035l0.018-0.035l0.054-0.036l0.035-0.036l0.072-0.035
                                                                        l0.018-0.037l0.055-0.035l0.035-0.035l0.055-0.036l0.035-0.036l0.054-0.036l0.035-0.035l0.037-0.036l0.035-0.035l0.053-0.036
                                                                        l0.037-0.035l0.035-0.036l0.055-0.036l0.142-0.179c0.43-0.43,0.823-0.895,1.164-1.395v-0.037l0.125-0.179l0,0l0.251-0.393
                                                                        l0.037-0.108l0,0l0.07-0.07l0,0l0.054-0.108l0,0l0.053-0.106l0.037-0.107l0,0l0.072-0.108l0,0l0.035-0.106l0.054-0.107
                                                                        l0.053-0.107l0,0l0.037-0.107l0.035-0.107l0,0l0.055-0.107l0,0l0.053-0.107l0.037-0.107l0.035-0.107l0.035-0.107l0,0l0.037-0.106
                                                                        l0.035-0.108l0.035-0.106l0.037-0.107l0,0l0.035-0.108l0.018-0.143l0.037-0.107l0.018-0.106l0.035-0.108l0.019-0.143l0.018-0.107
                                                                        l0.035-0.107l0.018-0.107l0.019-0.143l0.018-0.107l0.018-0.107v-0.144l0.035-0.106v-0.108v-0.144l0.02-0.106l0.018-0.106v-0.144
                                                                        v-0.108v-0.143v-0.107c0-2.611-1.074-5.008-2.772-6.727l-0.09-0.07l0,0l-0.072-0.072l-0.072-0.107l-0.09-0.072h-0.018l-0.072-0.07
                                                                        l0,0l-0.072-0.072h-0.018l-0.09-0.071l0,0l-0.072-0.071l0,0l-0.089-0.071h-0.018l-0.142-0.144h-0.02h-0.018l-0.072-0.072h-0.018
                                                                        l-0.07-0.035l-0.019-0.036l-0.07-0.035l-0.02-0.036l-0.07-0.035l-0.02-0.037l-0.07-0.035h-0.037l-0.072-0.071h-0.018l-0.072-0.036
                                                                        l-0.018-0.035l-0.072-0.035l-0.035-0.037l-0.072-0.035h-0.018l-0.072-0.036l-0.018-0.035l-0.072-0.036l-0.035-0.036l-0.072-0.036
                                                                        h-0.035l-0.216-0.106l-0.053-0.036l-0.035-0.036l-0.161-0.071h-0.037l-0.07-0.035l-0.037-0.037l-0.072-0.035h-0.035l-0.072-0.035
                                                                        l-0.142-0.072h-0.055l-0.162-0.07l-0.052-0.037h-0.055l-0.053-0.035l-0.054-0.036h-0.054l-0.053-0.036h-0.055l-0.052-0.035h-0.055
                                                                        l-0.053-0.035h-0.054l-0.072-0.037h-0.035l-0.072-0.035h-0.054l-0.214-0.071h-0.072l-0.035-0.036h-0.072h-0.035l-0.089-0.036
                                                                        h-0.037l-0.089-0.036h-0.018h-0.089h-0.035l-0.072-0.035h-0.035h-0.089l-0.018-0.035h-0.109h-0.018l-0.089-0.036h-0.035h-0.09
                                                                        h-0.018h-0.108v-0.036h-0.107h-0.018h-0.107h-0.018l-0.107-0.035l0,0h-0.109h-0.018h-0.107h-0.018h-0.107l0,0h-0.107h-0.02h-0.125
                                                                        v-0.036h-0.107h-0.018h-0.109h-0.125l0,0h-0.107l-0.018,0.036h-0.107h-0.02h-0.107l0,0h-0.107h-0.018h-0.107h-0.019h-0.107h-0.018
                                                                        l-0.089,0.035h-0.035h-0.072h-0.035h-0.108l-0.018,0.036h-0.09h-0.018h-0.089h-0.035l-0.089,0.036h-0.019h-0.107l-0.018,0.035
                                                                        h-0.089h-0.035L226,308.646h-0.035h-0.072h-0.037l-0.089,0.036h-0.035l-0.072,0.036h-0.053h-0.072l-0.035,0.036h-0.072
                                                                        l-0.037,0.036h-0.07l-0.037,0.035h-0.072h-0.035l-0.072,0.035h-0.053l-0.054,0.037h-0.053l-0.054,0.035h-0.055l-0.053,0.035
                                                                        h-0.072l-0.054,0.036h-0.053l-0.055,0.036l-0.052,0.035h-0.037l-0.072,0.037h-0.053l-0.054,0.035l-0.035,0.035h-0.072
                                                                        l-0.035,0.036l-0.072,0.036h-0.035l-0.072,0.035h-0.037l-0.07,0.035l-0.037,0.037l-0.072,0.035h-0.035l-0.054,0.036l-0.053,0.035
                                                                        l-0.055,0.036h-0.035l-0.055,0.036l-0.035,0.036h-0.072l-0.035,0.035l-0.072,0.035l-0.035,0.036l-0.072,0.036h-0.037l-0.053,0.036
                                                                        l-0.018,0.035l-0.072,0.036h-0.035l-0.072,0.072h-0.037l-0.07,0.035l-0.02,0.035l-0.053,0.036h-0.037l-0.07,0.071h-0.037
                                                                        l-0.072,0.072l0,0l-0.07,0.035l-0.037,0.036l-0.07,0.035v0.036l-0.072,0.035h-0.037l-0.07,0.072h-0.02l-0.07,0.071h-0.019
                                                                        l-0.07,0.072l0,0l-0.089,0.071h-0.02l-0.07,0.071l0,0l-0.072,0.071h-0.018l-0.072,0.072h-0.018l-0.072,0.07l0,0l-0.09,0.072v0.036
                                                                        l-0.089,0.071l0,0l-0.072,0.072l0,0L221.169,311.223z
                                                                        M60.938,244.033h0.02h0.107h0.107h0.035h0.107l0,0h0.109h0.018h0.107h0.018
                                                                        h0.107l0,0h0.109h0.018l0.107-0.037h0.018h0.107l0,0h0.107h0.019l0.107-0.035h0.018h0.09h0.018l0.107-0.036h0.019h0.088h0.037
                                                                        l0.089-0.035h0.018h0.089h0.035l0.089-0.036h0.037h0.072l0.035-0.036h0.072h0.035l0.089-0.036h0.035l0.072-0.035h0.037h0.07
                                                                        l0.054-0.035h0.072l0.035-0.036h0.072l0.035-0.036h0.072h0.037l0.07-0.036h0.055l0.052-0.035l0.055-0.036h0.054l0.053-0.035h0.054
                                                                        l0.053-0.037h0.055l0.054-0.035h0.053l0.072-0.035l0.035-0.036h0.072l0.035-0.036h0.072l0.037-0.035l0.07-0.035h0.037l0.053-0.037
                                                                        l0.055-0.035h0.054l0.053-0.036l0.054-0.036h0.053l0.054-0.035l0.035-0.035l0.055-0.037h0.035l0.072-0.035l0.035-0.036
                                                                        l0.072-0.035h0.037l0.07-0.036l0.037-0.036l0.072-0.036h0.018l0.07-0.035l0.019-0.035l0.07-0.036l0.037-0.036l0.072-0.036h0.018
                                                                        l0.072-0.035l0.018-0.036l0.072-0.035l0.035-0.037l0.072-0.034h0.018l0.072-0.072h0.018l0.072-0.036l0.035-0.035l0.072-0.035
                                                                        v-0.037l0.072-0.035l0.035-0.036l0.072-0.035l0,0l0.089-0.071h0.018l0.072-0.072l0,0l0.089-0.071l0,0l0.089-0.072l0,0l0.07-0.071
                                                                        h0.019l0.07-0.071h0.02l0.07-0.071v-0.036l0.09-0.071l0.089-0.072l0.072-0.071l0,0l0.072-0.071
                                                                        c1.681-1.682,2.755-4.043,2.79-6.619l0,0v-0.107v-0.107v-0.035l-0.018-0.107v-0.143v-0.108l-0.018-0.106v-0.107v-0.143
                                                                        l-0.018-0.108l-0.018-0.144l-0.02-0.106l-0.018-0.107v-0.144l-0.035-0.107l-0.018-0.107l-0.02-0.107l-0.018-0.143l-0.018-0.107
                                                                        l-0.035-0.107l-0.037-0.107l-0.035-0.143l-0.018-0.107l-0.037-0.107l-0.018-0.107l-0.035-0.107l-0.037-0.107l-0.035-0.107
                                                                        l-0.055-0.107l-0.035-0.106l-0.037-0.144l-0.053-0.072v-0.035l-0.037-0.071l-0.035-0.144l-0.053-0.071l-0.055-0.106l-0.035-0.108
                                                                        l-0.055-0.106l-0.054-0.108l-0.035-0.107h-0.018l-0.055-0.106l-0.052-0.108l-0.055-0.071l-0.054-0.107l0,0l-0.053-0.107l0,0
                                                                        l-0.072-0.107l0,0l-0.053-0.106l0,0l-0.054-0.072l0,0l-0.144-0.215l-0.053-0.071l-0.054-0.107l-0.072-0.071l0,0l-0.142-0.215
                                                                        l-0.144-0.179l0,0l-0.07-0.071l0,0l-0.072-0.072v-0.036l-0.216-0.25l0,0l-0.089-0.072l0,0l-0.07-0.07l0,0l-0.161-0.18
                                                                        l-0.072-0.071l0,0l-0.089-0.071l-0.411-0.394l-0.037-0.035l-0.142-0.108l-0.037-0.035l-0.142-0.107l-0.037-0.036l-0.07-0.035l0,0
                                                                        l-0.072-0.071h-0.037l-0.07-0.072l0,0l-0.179-0.107l0,0l-0.107-0.07l0,0l-0.072-0.072h-0.018l-0.089-0.036v-0.036l-0.089-0.035H66
                                                                        l-0.07-0.072h-0.037l-0.07-0.035l-0.019-0.035l-0.07-0.036h-0.02l-0.07-0.036l-0.037-0.036l-0.072-0.036H65.5l-0.072-0.035
                                                                        l-0.035-0.035h-0.055l-0.035-0.037L65.249,226l-0.053-0.035h-0.054l-0.053-0.036l-0.054-0.036H64.98l-0.035-0.035h-0.054
                                                                        l-0.053-0.037l-0.054-0.035h-0.053l-0.055-0.035h-0.035l-0.072-0.036l-0.035-0.036h-0.089l-0.019-0.035l-0.09-0.035h-0.018
                                                                        l-0.089-0.037h-0.018l-0.089-0.035h-0.018l-0.089-0.036h-0.018l-0.107-0.035l0,0l-0.108-0.036h-0.107v-0.035h-0.107l0,0
                                                                        l-0.107-0.037h-0.018l-0.089-0.035h-0.02l-0.089-0.035H63.21h-0.072l-0.035-0.036h-0.072h-0.035l-0.072-0.036H62.87h-0.07h-0.054
                                                                        l-0.072-0.036h-0.035h-0.072l-0.053-0.035h-0.054h-0.072h-0.035H62.28l-0.054-0.036h-0.07h-0.037h-0.09h-0.035l-0.072-0.035
                                                                        h-0.035h-0.089H61.78h-0.109h-0.018h-0.089h-0.035h-0.089h-0.018h-0.109l0,0l-0.107-0.037h-0.018h-0.125h-0.107h-0.02
                                                                        c-2.557,0.037-4.883,1.109-6.582,2.791c-1.735,1.718-2.772,4.08-2.772,6.727c0,2.505,0.965,4.795,2.557,6.512l0.216,0.215
                                                                        C56.054,242.959,58.381,244.033,60.938,244.033z
                                                                        M161.099,143.496c0.179,0.43,0.358,0.859,0.537,1.287l0.018,0.072l0.125,0.394
                                                                        h0.019l0.035,0.144l0.232,0.752l0.037,0.143h0.018l0.035,0.143l0.02,0.072l0.018,0.107l0.052,0.143h47.872l0.216-0.823
                                                                        l0.035-0.106l0.035-0.107v-0.036l0.144-0.394l0.018-0.036l0.055-0.179l0,0l0.035-0.144l0.035-0.071l0.072-0.215l0,0l0.072-0.143
                                                                        l0.035-0.036l0.019-0.106l0.053-0.107v-0.036l0.072-0.144v-0.035l0.179-0.358v-0.036l0,0l0.072-0.143l0.035-0.071l0.035-0.071
                                                                        l0.055-0.107v-0.036l0.072-0.144l0.018-0.035l0.054-0.071l0.053-0.144h0.018l0.055-0.144l0.035-0.035l0.055-0.108l0.142-0.285
                                                                        h0.019l0.07-0.107l0.037-0.072l0.035-0.07l0.144-0.251l0.035-0.071l0.054-0.072l0.072-0.107l0.018-0.035l0.07-0.107l0.037-0.035
                                                                        l0.053-0.108l0.054-0.106l0.035-0.037l0.162-0.25l0.054-0.071l0.035-0.035l0.089-0.144l0,0l0.179-0.25l0.089-0.107l0,0
                                                                        l0.089-0.143l0.286-0.358h0.018l0.286-0.358l0,0l0.144-0.143l0.054-0.072l0.089-0.107l0,0l0.107-0.143l0.035-0.036l0.072-0.071
                                                                        l0.072-0.071l0.035-0.036l0.214-0.214l0.055-0.072l0.054-0.036l0.107-0.107l0,0l0.142-0.178l0,0l0.162-0.144l0.162-0.144l0,0
                                                                        l0.161-0.143l0.16-0.143v-0.037l0.179-0.143l0,0l0.162-0.143l0,0l0.161-0.107v-0.037l0.179-0.106l0,0l0.179-0.144l0,0l0.16-0.143
                                                                        h0.02l0.16-0.143l0,0h0.019l0.321-0.252h0.035c0.465-0.357,0.949-0.68,1.467-0.965l0,0l0.179-0.107h0.037l0.358-0.215h0.018
                                                                        l0.375-0.18h0.018l0.179-0.106h0.018l0.805-0.358h0.018l0.609-0.25v-0.035l0.214-0.072l0,0l0.196-0.071h0.018l0.198-0.072h0.018
                                                                        l0.197-0.071h0.018l0.179-0.071h0.035l0.198-0.071h0.018l0.197-0.036h0.018l0.196-0.071h0.018l0.216-0.071l0,0l0.214-0.036l0,0
                                                                        l0.214-0.072l0,0l0.216-0.035h0.018l0.214-0.035h0.02l0.196-0.072h0.018l0.214-0.035l0,0l0.216-0.036h0.018l0.214-0.036h0.018
                                                                        l0.216-0.035l0,0l0.214-0.037h0.017h0.233l0,0l0.214-0.035l0,0l0.233-0.035h0.018h0.214l0,0l0.233-0.036h0.018h0.214h0.25
                                                                        l0.214-0.036h0.251h0.231h0.233h0.251l0.214,0.036h0.251h0.214l0,0l0.233,0.036h0.018h0.214l0,0l0.233,0.035h0.018l0.214,0.035
                                                                        l0,0h0.216h0.018l0.232,0.037l0,0l0.216,0.035l0,0l0.214,0.036h0.018l0.233,0.036l0,0l0.214,0.035l0,0l0.214,0.072h0.02
                                                                        l0.214,0.035h0.018l0.196,0.035h0.018l0.216,0.072l0,0l0.214,0.036l0,0l0.214,0.071h0.02l0.196,0.071h0.018l0.197,0.036h0.018
                                                                        l0.196,0.071h0.02l0.196,0.071h0.018l0.197,0.071h0.018l0.196,0.072h0.02l1.02,0.394l0.017,0.035l0.394,0.143l0.017,0.037
                                                                        c2.021,0.895,3.864,2.183,5.403,3.721c0.985,1.002,1.86,2.074,2.576,3.291h0.018l0.233,0.357l0,0l0.107,0.215l0,0l0.107,0.18l0,0
                                                                        l0.107,0.179l0,0l0.108,0.215l0,0l0.107,0.179l0,0l0.089,0.215h0.018l0.072,0.179l0.107,0.215l0,0l0.09,0.214h0.018l0.072,0.18
                                                                        l0,0l0.089,0.215h0.018l0.072,0.215l0.07,0.214l0.109,0.179l0.07,0.215l0.072,0.215l0,0l0.072,0.215l0,0l0.072,0.215l0.072,0.215
                                                                        l0.07,0.214l0.054,0.216l0.053,0.215l0.072,0.213l0.037,0.216l0,0l0.07,0.25l0.037,0.215l0.053,0.215l0.054,0.214l0.035,0.215
                                                                        l0.037,0.25l0.035,0.215l0.037,0.215l0.035,0.25l0.018,0.216l0.018,0.25l0.037,0.215l0.018,0.215l0.018,0.25v0.216l0.018,0.25
                                                                        l0.018,0.215v0.25v0.25v0.215l0,0v0.215v0.035l-0.018,0.215l0,0l-0.018,0.215v0.036v0.179v0.036l-0.018,0.214v0.036l-0.018,0.179
                                                                        v0.072l-0.018,0.143l-0.019,0.071v0.144l-0.018,0.071l-0.018,0.179v0.072L246,153.621v0.072l-0.037,0.143v0.072l-0.035,0.143
                                                                        v0.107l-0.037,0.107v0.107l-0.035,0.107v0.107l-0.035,0.107l-0.02,0.143l-0.018,0.072l-0.035,0.143l-0.018,0.071l-0.019,0.143
                                                                        l-0.035,0.072l-0.035,0.144v0.071l-0.037,0.144l-0.035,0.07l-0.037,0.143l-0.018,0.072l-0.035,0.18l-0.017,0.035l-0.037,0.18
                                                                        l-0.035,0.035l-0.035,0.179l-0.019,0.036l-0.053,0.179l-0.018,0.036l-0.054,0.179h-0.018l-0.055,0.214l0,0h-0.018l-0.125,0.395
                                                                        h-0.019l-0.09,0.214l0,0l-0.07,0.215l0,0l-0.251,0.573l-0.018,0.035c-0.913,2.004-2.166,3.828-3.685,5.367l-0.055,0.035
                                                                        c-2.29,2.325-5.241,4.008-8.516,4.83h-0.018v47.871l0.144,0.035l0.018,0.036l0.125,0.036h0.089l0.055,0.036l0.144,0.035h0.018
                                                                        l0.142,0.036l0.072,0.035h0.072l0.125,0.037l0.018,0.034l0.144,0.036h0.072l0.053,0.036l0.162,0.036l0,0l0.125,0.07h0.089
                                                                        l0.054,0.037l0.144,0.035l0.018,0.036l0.125,0.035l0.09,0.036h0.035l0.144,0.072h0.018l0.126,0.07h0.07l0.072,0.036l0.144,0.071
                                                                        l0,0l0.125,0.035l0.072,0.037l0.072,0.035l0.125,0.072h0.018l0.126,0.035l0.07,0.036l0.072,0.036l0.107,0.072h0.017l0.126,0.07
                                                                        l0.072,0.036l0.07,0.036l0.126,0.035l0.018,0.035l0.107,0.037l0.072,0.035l0.072,0.036l0.125,0.071h0.018l0.107,0.072l0.072,0.035
                                                                        l0.072,0.035l0.107,0.072h0.018l0.126,0.07l0.07,0.037l0.037,0.035l0.142,0.107l0,0l0.109,0.072l0.089,0.035l0.053,0.035
                                                                        l0.107,0.072h0.018l0.198,0.107l0.035,0.072l0.144,0.07l0.107,0.072l0.07,0.035l0.054,0.072l0.125,0.071l0,0l0.109,0.071
                                                                        l0.07,0.035l0.054,0.072l0.125,0.071l0,0l0.107,0.108l0.072,0.035l0.037,0.035l0.125,0.107h0.018l0.107,0.072l0.054,0.035
                                                                        l0.055,0.072l0.107,0.071l0.107,0.106l0.072,0.037l0.053,0.071l0.107,0.071l0,0l0.126,0.107l0.035,0.035l0.072,0.072l0.107,0.071
                                                                        l0,0l0.107,0.107l0.037,0.071l0.07,0.036l0.109,0.106l0,0l0.142,0.144l0.179,0.144c0.877,0.895,1.664,1.86,2.362,2.898l0,0
                                                                        l0.107,0.179l0,0l0.107,0.179h0.018l0.107,0.215h0.02l0.107,0.179l0,0l0.107,0.179l0,0l0.107,0.215l0,0l0.107,0.178l0,0
                                                                        l0.108,0.215l0,0l0.107,0.18l0,0l0.089,0.215l0,0l0.089,0.18l0,0l0.107,0.213l0.09,0.18l0.089,0.215l0,0l0.089,0.214h0.018
                                                                        l0.072,0.216l0.07,0.178l0.09,0.216h0.019l0.07,0.214l0,0l0.072,0.215l0.072,0.215l0.072,0.215l0.072,0.215l0.07,0.215
                                                                        l0.054,0.214l0.053,0.215l0,0l0.072,0.215l0.037,0.214l0.07,0.215l0.037,0.252l0.053,0.213l0.054,0.215l0.035,0.216l0.037,0.25
                                                                        l0.035,0.215l0.037,0.214l0.035,0.251l0.018,0.214l0.018,0.252l0.037,0.215l0.018,0.25l0.018,0.215v0.25l0.018,0.215l0.018,0.25
                                                                        v0.215v0.25v0.215l0,0v0.25l0,0l-0.018,0.215v0.036l-0.018,0.179v0.036v0.214l0,0l-0.018,0.215v0.037l-0.018,0.178v0.072
                                                                        l-0.018,0.143l-0.019,0.072v0.179l-0.018,0.035l-0.018,0.179v0.072L246,237.02v0.072l-0.037,0.143v0.071l-0.035,0.144v0.107
                                                                        l-0.037,0.107v0.107l-0.035,0.107v0.107l-0.035,0.143l-0.02,0.107l-0.018,0.107l-0.035,0.107l-0.018,0.107l-0.019,0.107
                                                                        l-0.035,0.107l-0.035,0.107v0.07l-0.037,0.144l-0.035,0.071l-0.037,0.144l-0.018,0.071l-0.035,0.144l-0.017,0.071l-0.037,0.143
                                                                        l-0.035,0.072l-0.035,0.143l-0.019,0.072l-0.053,0.143l-0.018,0.072l-0.054,0.143l-0.018,0.035l-0.055,0.18v0.036l-0.072,0.179
                                                                        l-0.018,0.035l-0.053,0.18l-0.019,0.035l-0.09,0.18l0,0l-0.07,0.215l0,0l-0.072,0.178l-0.107,0.215l0,0l-0.072,0.18v0.035
                                                                        l-0.107,0.18l0,0l-0.072,0.179l-0.035,0.034l-0.072,0.18l0,0l-0.089,0.179l-0.018,0.036l-0.072,0.143l-0.035,0.072l-0.072,0.143
                                                                        l-0.037,0.037l-0.07,0.143l-0.037,0.035l-0.072,0.144l-0.035,0.071l-0.072,0.107l-0.035,0.071l-0.072,0.107l-0.035,0.071
                                                                        l-0.072,0.144l-0.054,0.071l-0.07,0.107l-0.054,0.071l-0.055,0.108l-0.052,0.07l-0.072,0.108l-0.054,0.071l-0.053,0.107
                                                                        l-0.072,0.071l-0.055,0.106l-0.07,0.072l-0.055,0.072l-0.072,0.107l-0.053,0.07l-0.089,0.107l-0.037,0.072l-0.089,0.107
                                                                        l-0.053,0.071l-0.072,0.108l-0.055,0.034L242.137,246l-0.037,0.035l-0.107,0.144l-0.035,0.036l-0.109,0.107l-0.035,0.035
                                                                        l-0.107,0.143l-0.037,0.037l-0.107,0.143h-0.018l-0.125,0.143l-0.018,0.036l-0.126,0.144h-0.018l-0.125,0.143h-0.037l-0.125,0.18
                                                                        l-0.162,0.178c-1.825,1.826-4.078,3.293-6.582,4.187l-0.072,0.036l-0.072,0.036l-0.895,0.286l-0.107,0.035l-0.035,0.037
                                                                        l-0.162,0.035h-0.018l-0.144,0.035l-0.072,0.036h-0.07l-0.144,0.036l0,0l-0.162,0.035v47.871l0.162,0.036v0.036l0.144,0.035h0.07
                                                                        l0.072,0.035l0.144,0.036h0.018l0.162,0.036l0.035,0.035l0.554,0.145l0.09,0.035l0.072,0.035l0.142,0.036l0,0l0.144,0.071
                                                                        l0.072,0.035h0.072l1.162,0.466v0.036l0.126,0.035c2.002,0.931,3.792,2.183,5.294,3.686c0.985,1.002,1.86,2.111,2.576,3.292h0.018
                                                                        l0.233,0.356l0,0l0.107,0.215l0,0l0.107,0.18l0,0l0.107,0.215l0,0l0.108,0.179l0,0l0.107,0.214l0,0l0.089,0.18h0.018l0.072,0.215
                                                                        l0.107,0.179l0,0l0.09,0.214h0.018l0.072,0.215l0,0l0.089,0.18h0.018l0.072,0.215l0.07,0.214l0.109,0.215l0.07,0.215l0.072,0.179
                                                                        l0,0l0.072,0.215l0,0l0.072,0.215l0.072,0.214l0.07,0.215l0.054,0.216l0.053,0.215l0.072,0.25l0.037,0.215l0,0l0.07,0.214
                                                                        l0.037,0.215l0.053,0.215l0.054,0.214l0.035,0.251l0.037,0.214l0.035,0.215L246,315.41l0.035,0.214l0.018,0.215l0.018,0.25
                                                                        l0.037,0.215l0.018,0.25l0.018,0.215v0.251l0.018,0.215l0.018,0.25v0.215v0.25c0,5.045-2.057,9.625-5.366,12.916
                                                                        c-1.054,1.073-2.253,2.004-3.541,2.756l-0.108,0.07l-0.07,0.036l-0.323,0.215h-0.053l-0.126,0.071l-0.072,0.035l-0.018,0.037
                                                                        l-0.162,0.07l-0.142,0.072l-0.054,0.036L236,334.336l-0.054,0.035l-0.142,0.072h-0.037l-0.375,0.18h-0.018l-0.179,0.106h-0.018
                                                                        l-0.196,0.071l0,0l-0.197,0.072h-0.019l-0.179,0.106h-0.018l-0.196,0.072l0,0l-0.197,0.071h-0.018l-0.179,0.071h-0.037
                                                                        l-0.179,0.072h-0.035l-0.162,0.071h-0.053l-0.161,0.071h-0.055l-0.142,0.071h-0.072l-0.144,0.036l-0.07,0.036l-0.144,0.035
                                                                        l-0.072,0.035l-0.142,0.037h-0.072l-0.144,0.035l-0.07,0.036l-0.144,0.035h-0.09l-0.125,0.036l-0.089,0.035h-0.126l-0.107,0.037
                                                                        l-0.107,0.035h-0.107l-0.107,0.035h-0.107l-0.109,0.036h-0.142l-0.072,0.036h-0.142l-0.072,0.036h-0.144l-0.072,0.034h-0.177
                                                                        h-0.054l-0.162,0.037h-0.072l-0.16,0.035h-0.054h-0.179h-0.035l-0.196,0.036h-0.037h-0.196h-0.035l-0.179,0.036h-0.037h-0.214
                                                                        h-0.017h-0.197h-0.037h-0.214l0,0h-0.233h-0.231l0,0h-0.233l0,0h-0.232l0,0h-0.216h-0.035l-0.179-0.036h-0.035h-0.196h-0.055
                                                                        l-0.179-0.036h-0.035h-0.179H226l-0.144-0.035h-0.07l-0.161-0.037h-0.072h-0.161l-0.07-0.034h-0.144l-0.072-0.036h-0.142
                                                                        l-0.089-0.036h-0.126l-0.107-0.036h-0.107l-0.107-0.035h-0.107l-0.107-0.035l-0.126-0.037h-0.107l-0.089-0.035l-0.125-0.036
                                                                        h-0.089l-0.144-0.035l-0.072-0.036l-0.142-0.035h-0.072l-0.144-0.037l-0.07-0.035l-0.144-0.035l-0.072-0.036l-0.142-0.036h-0.072
                                                                        l-0.144-0.071h-0.07l-0.144-0.071h-0.053l-0.162-0.071h-0.037l-0.179-0.072h-0.035l-0.179-0.071h-0.018l-0.179-0.071h-0.018
                                                                        l-0.196-0.072h-0.02l-0.179-0.071l-0.018-0.035l-0.197-0.072l0,0l-0.214-0.071l0,0l-0.179-0.106l0,0l-0.214-0.072l0,0
                                                                        l-0.179-0.107h-0.037l-0.162-0.072l-0.018-0.035l-0.179-0.071l-0.035-0.036l-0.144-0.072l-0.035-0.035l-0.161-0.072h-0.053
                                                                        l-0.144-0.106h-0.035l-0.144-0.108l-0.053-0.035l-0.126-0.071l-0.072-0.036l-0.107-0.07l-0.072-0.037l-0.107-0.071l-0.07-0.035
                                                                        l-0.126-0.072l-0.072-0.036l-0.107-0.07l-0.089-0.072l-0.09-0.036l-0.089-0.071l-0.07-0.072l-0.108-0.07l-0.07-0.036l-0.109-0.071
                                                                        l-0.07-0.072l-0.109-0.071l-0.07-0.035l-0.107-0.071l-0.072-0.072l-0.107-0.071l-0.055-0.036l-0.107-0.107l-0.054-0.035
                                                                        l-0.125-0.107l-0.055-0.036l-0.107-0.071l-0.053-0.035l-0.126-0.108l-0.035-0.036l-0.126-0.107l-0.035-0.035l-0.125-0.107
                                                                        l-0.037-0.035l-0.142-0.144h-0.018l-0.126-0.144h-0.035l-0.144-0.143l0,0l-0.321-0.322c-2.327-2.289-4.025-5.224-4.831-8.515
                                                                        h-47.872h-0.018l-0.035,0.144v0.035l-0.09,0.286l-0.037,0.144l0,0l-0.07,0.285l-0.037,0.144l-0.035,0.036l-0.035,0.143v0.036
                                                                        l-0.055,0.106l-0.018,0.107l-0.037,0.036l-0.035,0.144v0.035l-0.162,0.43h-0.017l-0.035,0.144l-0.037,0.035l-0.035,0.108
                                                                        l-0.303,0.715l0,0l-0.055,0.143l-0.035,0.072l-0.037,0.072l-0.072,0.143l0,0l-0.07,0.143l-0.02,0.036l-0.053,0.107l-0.037,0.106
                                                                        h-0.035l-0.072,0.144v0.036l-0.07,0.107l-0.072,0.143l-0.072,0.144l0,0l-0.072,0.107l-0.035,0.106l-0.037,0.036l-0.07,0.144h-0.02
                                                                        l-0.07,0.143l-0.037,0.036l-0.053,0.071l-0.054,0.107l-0.018,0.036l-0.089,0.144l-0.018,0.035l-0.072,0.071l-0.161,0.251
                                                                        l-0.018,0.035l-0.162,0.251l-0.089,0.106l0,0l-0.089,0.108l-0.16,0.25l-0.02,0.036l-0.089,0.107l-0.107,0.106l-0.053,0.108
                                                                        l-0.02,0.035l-0.214,0.215l-0.035,0.072l-0.054,0.07l-0.09,0.107l0,0l-0.107,0.107l-0.035,0.072l-0.072,0.071l-0.072,0.071
                                                                        l-0.018,0.035l-0.107,0.108l-0.019,0.036l-0.088,0.07l-0.054,0.072l-0.144,0.143l-0.018,0.037l-0.196,0.178l-0.107,0.107l0,0
                                                                        l-0.072,0.071c-0.93,0.931-1.984,1.754-3.113,2.469l-0.09,0.072l-0.089,0.035l-0.107,0.071l-0.072,0.037l-0.107,0.07l-0.089,0.036
                                                                        l-0.375,0.215l-0.107,0.071l-0.072,0.035l-0.179,0.107l-0.142,0.072l-0.072,0.036l-0.144,0.071l-0.035,0.035l-0.162,0.072h-0.035
                                                                        l-0.161,0.107h-0.035l-0.179,0.072l0,0l-0.179,0.106h-0.037l-0.179,0.071l0,0l-0.214,0.072l-0.196,0.106h-0.018l-0.179,0.072
                                                                        h-0.018l-0.198,0.071l0,0l-0.196,0.071h-0.018l-0.179,0.072h-0.035l-0.179,0.071h-0.037l-0.177,0.071H149.9l-0.162,0.071h-0.052
                                                                        l-0.144,0.036l-0.072,0.036l-0.143,0.035l-0.072,0.035l-0.142,0.037h-0.072l-0.144,0.035l-0.089,0.036l-0.125,0.035h-0.107
                                                                        l-0.107,0.036l-0.109,0.035h-0.107l-0.107,0.037l-0.107,0.035h-0.107l-0.107,0.035h-0.126l-0.089,0.036h-0.142l-0.072,0.036
                                                                        h-0.144l-0.089,0.036h-0.142l-0.072,0.034h-0.161h-0.07l-0.144,0.037h-0.072l-0.162,0.035h-0.052h-0.179h-0.035l-0.198,0.036
                                                                        h-0.035h-0.196h-0.037l-0.196,0.036h-0.018h-0.214h-0.018h-0.216h-0.018h-0.214h-0.233h-0.018h-0.179h-0.035h-0.162h-0.089h-0.107
                                                                        h-0.107h-0.072h-0.179h-0.037l-0.177-0.036l0,0h-0.179h-0.072h-0.144l-0.072-0.036h-0.107h-0.142l-0.037-0.035h-0.179h-0.018
                                                                        l-0.197-0.037h-0.018h-0.161l-0.072-0.034h-0.107l-0.107-0.036h-0.089l-0.144-0.036h-0.035l-0.196-0.036l-0.179-0.035h-0.035
                                                                        l-0.144-0.035h-0.072l-0.107-0.037h-0.125l-0.055-0.035l-0.179-0.036h-0.018l-0.179-0.035h-0.018l-0.161-0.036l-0.053-0.035
                                                                        l-0.126-0.037h-0.089l-0.089-0.035l-0.125-0.035h-0.054l-0.16-0.072h-0.02l-0.179-0.071h-0.018l-0.142-0.036l-0.072-0.035
                                                                        l-0.107-0.036l-0.107-0.035h-0.072l-0.144-0.072h-0.035l-0.179-0.071l0,0l-0.179-0.071h-0.035l-0.126-0.036l-0.286-0.143h-0.055
                                                                        l-0.142-0.072h-0.035l-0.144-0.071h-0.035l-0.144-0.071l-0.054-0.035l-0.125-0.037l-0.072-0.035l-0.07-0.036l-0.144-0.071
                                                                        l-0.035-0.036l-0.144-0.071h-0.018l-0.161-0.071l-0.035-0.036l-0.126-0.072l-0.053-0.035l-0.107-0.035l-0.109-0.072h-0.053
                                                                        l-0.126-0.071l-0.035-0.036l-0.162-0.072v-0.035l-0.16-0.071l-0.037-0.036l-0.107-0.07l-0.072-0.037l-0.089-0.035l-0.107-0.071
                                                                        l-0.053-0.036l-0.126-0.072l-0.018-0.035l-0.162-0.071l-0.018-0.036l-0.125-0.071l-0.054-0.036l-0.107-0.072l-0.072-0.07
                                                                        l-0.072-0.036l-0.107-0.071l-0.035-0.035l-0.144-0.108l0,0l-0.142-0.106h-0.037l-0.107-0.107v35.992h254.346v-17.031l-0.716-5.295
                                                                        l-3.471-0.858l-3.417-1.108l-12.505,15.17c-1.395,1.682-3.791,2.074-5.616,0.965l-10.019-6.01
                                                                        c-1.914-1.145-2.647-3.507-1.787-5.475l7.477-18.139l-2.541-2.505l-2.432-2.647l-18.427,6.905
                                                                        c-2.057,0.751-4.329-0.106-5.366-1.968l-5.652-10.232c-1.074-1.933-0.537-4.293,1.145-5.617l15.563-11.986l-0.965-3.47
                                                                        l-0.751-3.472l-19.392-3.254c-2.256-0.358-3.794-2.398-3.65-4.616l0.215-11.485c0.036-2.182,1.682-3.971,3.811-4.257l19.444-2.575
                                                                        l0.896-3.472l1.074-3.435L325.57,250.15c-1.699-1.396-2.074-3.828-0.984-5.652l6.029-10.019c1.126-1.896,3.488-2.612,5.475-1.788
                                                                        l18.139,7.512l2.521-2.61l2.613-2.362l-6.889-18.426c-0.75-2.074,0.107-4.328,1.968-5.367l10.233-5.688
                                                                        c1.913-1.038,4.293-0.537,5.58,1.18l11.987,15.564l3.362-0.93l2.183-0.502V41.35c0-4.186-1.753-8.049-4.526-10.84
                                                                        c-2.771-2.756-6.618-4.508-10.822-4.508H148.773c-4.205,0-8.051,1.752-10.823,4.508c-2.79,2.791-4.508,6.654-4.508,10.84v26.227
                                                                        h220.859c2.432,0,4.4,1.967,4.4,4.399s-1.969,4.401-4.4,4.401H133.442v60.178c1.432-1.072,3.023-1.968,4.759-2.611l0,0
                                                                        l1.037-0.357h0.037l0.142-0.036h0.072l0.214-0.071l0.089-0.035l0.126-0.036h0.035l0.375-0.108h0.018l0.161-0.035h0.072
                                                                        l0.107-0.035h0.125l0.216-0.072h0.035l0.179-0.035l0,0l0.179-0.036h0.053l0.144-0.036h0.089h0.107l0.107-0.035h0.072l0.161-0.037
                                                                        h0.018h0.197h0.018l0.179-0.035h0.037h0.142l0.107-0.035h0.072h0.144h0.053l0.198-0.036l0,0h0.177h0.037h0.179h0.072h0.107
                                                                        l0.107-0.036h0.089h0.162h0.035h0.179h0.018h0.233l0,0h0.231l0,0l0.233,0.036l0,0h0.232h0.018h0.216l0,0l0.231,0.036h0.02h0.214
                                                                        l0,0l0.231,0.035h0.02l0.214,0.035l0,0h0.232l0,0l0.233,0.037l0,0l0.214,0.035l0,0l0.233,0.036l0,0l0.231,0.036l0,0l0.216,0.035
                                                                        l0,0l0.214,0.072h0.018l0.216,0.035h0.018l0.214,0.035l0,0l0.214,0.072l0,0l0.216,0.036l0,0l0.214,0.071l0,0l0.214,0.071l0,0
                                                                        l0.216,0.036h0.018l0.214,0.071l0,0l0.196,0.071h0.02l0.197,0.071h0.017l0.197,0.072h0.018l0.196,0.071l0,0l0.216,0.072l0,0
                                                                        l0.214,0.106l0,0l0.196,0.071h0.018l0.198,0.107l0,0l0.197,0.072l0,0l0.214,0.107l0,0l0.197,0.071l0,0l0.196,0.107l0,0
                                                                        l0.198,0.106h0.018l0.179,0.072l0,0l0.196,0.107h0.018l0.179,0.107l0,0l0.196,0.107l0,0l0.197,0.107l0,0l0.179,0.107l0,0
                                                                        l0.197,0.107l0,0l0.196,0.143l0,0l0.179,0.107l0,0l0.179,0.107l0,0l0.179,0.143l0,0l0.179,0.107h0.018l0.161,0.143h0.018
                                                                        l0.162,0.108h0.018l0.161,0.144h0.018l0.162,0.143l0,0l0.179,0.107l0,0l0.179,0.143l0,0l0.162,0.143h0.018l0.142,0.145h0.019
                                                                        l0.16,0.143l0,0l0.162,0.143l0,0l0.161,0.144l0,0l0.162,0.144h0.018l0.142,0.143l0,0l0.162,0.18l0,0l0.161,0.143l0,0l0.107,0.107
                                                                        l0.107,0.107c0.323,0.357,0.609,0.68,0.913,1.037l0.017,0.035l0.144,0.18l0.035,0.036l0.179,0.25l0.037,0.036l0.07,0.072
                                                                        l0.072,0.106h0.018l0.089,0.144l0,0l0.09,0.106l0.054,0.071l0.035,0.072l0.072,0.107h0.018l0.089,0.143l0.018,0.036l0.072,0.107
                                                                        l0.054,0.071l0.035,0.035l0.072,0.144l0,0l0.09,0.108l0.035,0.07l0.054,0.072l0.072,0.107v0.035l0.16,0.25l0.055,0.072
                                                                        l0.035,0.072l0.072,0.107v0.035l0.216,0.393l0.07,0.144h0.018l0.377,0.788L161.099,143.496z
                                                                        M144.104,160.634h0.393h0.375h0.072
                                                                        h0.018h0.109h0.018l0.089-0.036h0.035h0.089h0.037h0.088h0.037h0.072l0.035-0.035h0.072h0.052h0.072h0.054l0.053-0.035h0.054
                                                                        h0.072l0.035-0.037h0.072h0.053h0.037h0.018l0.072-0.035h0.035h0.072l0.054-0.036h0.053h0.054l0.09-0.035h0.035h0.072l0.035-0.036
                                                                        h0.072h0.035l0.072-0.036h0.037l0.07-0.036h0.037l0.089-0.035h0.018l0.107-0.035l0,0l0.107-0.037l0,0l0.107-0.035l0,0l0.109-0.036
                                                                        l0.107-0.035l0,0l0.107-0.036l0,0l0.107-0.035l0,0l0.107-0.037l0,0l0.089-0.035l0.018-0.035l0.089-0.036h0.02l0.07-0.036h0.037
                                                                        l0.072-0.035l0.035-0.035l0.072-0.037h0.035l0.072-0.035h0.035l0.055-0.036l0.035-0.035l0.054-0.036h0.053l0.054-0.035
                                                                        l0.035-0.037l0.072-0.035h0.037l0.053-0.035l0.054-0.036l0.035-0.036h0.055l0.052-0.036l0.037-0.035l0.053-0.036l0.054-0.035
                                                                        h0.035l0.072-0.037l0.035-0.035l0.055-0.035l0.017-0.036l0.072-0.036h0.035l0.072-0.07l0,0l0.072-0.037l0.035-0.035l0.072-0.036
                                                                        v-0.036l0.089-0.035l0.018-0.035l0.072-0.037l0,0l0.089-0.07l0,0l0.09-0.072l0.089-0.072l0.089-0.07l0,0l0.072-0.072l0,0
                                                                        l0.07-0.071h0.02l0.07-0.071h0.019l0.07-0.071v-0.036l0.072-0.036l0.018-0.036l0.162-0.179c0.644-0.608,1.181-1.323,1.611-2.11
                                                                        l0.035-0.072l0.144-0.285l0.035-0.036l0.018-0.072l0.018-0.035l0.037-0.071l0.018-0.036l0.035-0.071l0.02-0.036l0.035-0.071
                                                                        v-0.035l0.035-0.072v-0.036l0.089-0.179l0.02-0.035l0.018-0.072l0.017-0.035l0.035-0.072l0,0l0.037-0.107l0,0l0.035-0.107v-0.035
                                                                        l0.035-0.071v-0.036l0.037-0.072v-0.035l0.035-0.107l0,0l0.037-0.107l0,0l0.018-0.107h0.018l0.018-0.107l0.018-0.107l0,0
                                                                        l0.037-0.106l0,0l0.035-0.108v-0.035l0.018-0.071v-0.036l0.02-0.107l0,0l0.035-0.107l0,0v-0.107v-0.035l0.035-0.072v-0.035v-0.072
                                                                        v-0.035l0.037-0.107v-0.035v-0.072v-0.036l0.018-0.071v-0.035l0.018-0.107v-0.036v-0.071v-0.037v-0.07v-0.036l0.018-0.071v-0.072
                                                                        l0.019-0.071v-0.035v-0.071v-0.037v-0.07v-0.072v-0.107l0,0v-0.143v-0.107v-0.107h-0.019v-0.107v-0.035v-0.107h-0.018v-0.107l0,0
                                                                        v-0.107v-0.036l-0.018-0.071v-0.035l-0.018-0.107l0,0v-0.108v-0.036l-0.037-0.214l-0.018-0.071l-0.09-0.465v-0.037l-0.018-0.07
                                                                        l-0.017-0.036v-0.072l-0.018-0.071l-0.055-0.215l-0.018-0.035l-0.018-0.072v-0.035l-0.037-0.072v-0.035l-0.072-0.179v-0.036
                                                                        l-0.035-0.072l-0.035-0.143l-0.037-0.071v-0.036l-0.035-0.072v-0.034l-0.035-0.072v-0.036l-0.037-0.07l-0.018-0.037l-0.035-0.071
                                                                        v-0.035l-0.233-0.501l0,0l-0.035-0.107h-0.019l-0.053-0.107l0,0l-0.037-0.071l0,0l-0.053-0.108h-0.018l-0.037-0.106h-0.018
                                                                        l-0.107-0.179l-0.054-0.107l0,0l-0.035-0.072l-0.035-0.035l-0.288-0.43l-0.018-0.035l-0.035-0.037l-0.037-0.035l-0.017-0.071
                                                                        l-0.035-0.036l-0.286-0.357l-0.037-0.035l-0.035-0.036l-0.035-0.036l-0.037-0.071l-0.035-0.036l-0.037-0.035l-0.249-0.25
                                                                        l-0.144-0.144l-0.251-0.251l0,0c-0.179-0.179-0.393-0.357-0.607-0.501l0,0l-0.072-0.071l0,0l-0.107-0.072l0,0l-0.072-0.07l0,0
                                                                        l-0.107-0.072l0,0l-0.089-0.072l-0.089-0.07l0,0l-0.107-0.036l0,0l-0.09-0.071l0,0l-0.089-0.072l0,0l-0.107-0.035l-0.107-0.071
                                                                        l0,0l-0.09-0.072l0,0l-0.089-0.036l-0.109-0.07l0,0l-0.107-0.036l0,0l-0.107-0.072l0,0l-0.09-0.035h-0.017l-0.09-0.071h-0.018
                                                                        l-0.089-0.037l0,0l-0.089-0.035h-0.018l-0.089-0.071h-0.02l-0.089-0.036h-0.018l-0.089-0.035h-0.018l-0.09-0.035l-0.107-0.072
                                                                        h-0.018l-0.089-0.036h-0.018l-0.089-0.036h-0.02l-0.107-0.035h-0.018l-0.089-0.035h-0.018l-0.107-0.037l0,0l-0.107-0.035l0,0
                                                                        l-0.108-0.035l0,0l-0.107-0.036l0,0h-0.107h-0.018l-0.107-0.036l0,0l-0.107-0.036h-0.02l-0.107-0.036l0,0l-0.107-0.035l0,0h-0.125
                                                                        l0,0l-0.126-0.035l0,0h-0.107l0,0l-0.107-0.036l0,0h-0.125h-0.019l-0.107-0.036l0,0h-0.107l0,0l-0.125-0.035l0,0h-0.126l0,0
                                                                        h-0.107h-0.018h-0.107h-0.018l-0.109-0.036l0,0h-0.107h-0.018h-0.125l0,0h-0.107l0,0h-0.126h-0.018h-0.107l0,0h-0.126l0,0h-0.125
                                                                        l0,0h-0.107h-0.018l-0.126,0.036h-0.107h-0.107h-0.018h-0.126l0,0l-0.107,0.035l0,0h-0.125l0,0l-0.125,0.036h-0.108l-0.125,0.036
                                                                        h-0.107l-0.125,0.035h-0.109l-0.125,0.035l-0.125,0.036l-0.109,0.036l-0.107,0.036h-0.107l0,0l-0.232,0.071l0,0l-0.126,0.035l0,0
                                                                        l-0.107,0.037l0,0l-0.107,0.035l0,0l-0.107,0.035l0,0l-0.107,0.036l-0.109,0.036l-0.107,0.072l0,0l-0.214,0.07l0,0l-0.214,0.072
                                                                        v0.035l-0.109,0.035l0,0c-1.002,0.466-1.895,1.109-2.701,1.897l-0.161,0.144l-0.053,0.07h-0.02l-0.053,0.072l-0.017,0.036
                                                                        l-0.055,0.034l-0.035,0.037l-0.037,0.035l-0.035,0.071l-0.035,0.036l-0.037,0.036l-0.018,0.036l-0.072,0.035v0.035l-0.072,0.072
                                                                        v0.036l-0.07,0.035v0.036l-0.054,0.072h-0.018l-0.055,0.07v0.036l-0.07,0.071l-0.055,0.107l0,0l-0.072,0.072l0,0l-0.053,0.107
                                                                        h-0.019l-0.035,0.07l-0.035,0.036l-0.037,0.072h-0.018l-0.035,0.071l-0.02,0.035l-0.035,0.072l-0.035,0.035l-0.037,0.036
                                                                        l-0.018,0.036l-0.018,0.072l-0.037,0.035l-0.035,0.035l-0.018,0.072l-0.018,0.035l-0.037,0.072l-0.035,0.035l-0.035,0.071l0,0
                                                                        l-0.037,0.108H136l-0.035,0.07l-0.019,0.036l-0.035,0.072v0.035l-0.035,0.071h-0.02l-0.052,0.107l0,0l-0.037,0.107l0,0
                                                                        l-0.035,0.107l0,0l-0.035,0.107h-0.019l-0.035,0.072l-0.018,0.035l-0.018,0.071l-0.019,0.036l-0.018,0.07l-0.018,0.037
                                                                        l-0.035,0.071l-0.037,0.107v0.036l-0.035,0.07v0.036l-0.035,0.072v0.071l-0.02,0.035l-0.018,0.072l-0.018,0.035l-0.018,0.072
                                                                        v0.035l-0.037,0.072v0.035l-0.035,0.072v0.036l-0.035,0.071v0.035l-0.019,0.107l0,0l-0.018,0.107l-0.018,0.107h-0.018v0.107l0,0
                                                                        l-0.037,0.107v0.035l-0.018,0.072v0.035l-0.018,0.072v0.035l-0.018,0.072v0.07l-0.018,0.037v0.07v0.072l-0.019,0.036v0.071
                                                                        l-0.018,0.072v0.035v0.071v0.036l-0.018,0.072v0.035v0.107h-0.018v0.107v0.035v0.071v0.036v0.107l0,0v0.107l0,0l0,0v0.143v0.107
                                                                        l0,0v0.107v0.035v0.108l0,0v0.106l0,0v0.107l0.018,0.036v0.106l0,0l0.018,0.108l0,0v0.107v0.036l0.018,0.07v0.036l0.019,0.108l0,0
                                                                        v0.107l0.018,0.035l0.018,0.071v0.036v0.107h0.018l0.018,0.107v0.035l0.02,0.072l0.018,0.035v0.071v0.036l0.035,0.072v0.035
                                                                        l0.018,0.107v0.036l0.019,0.071v0.035l0.035,0.072v0.035l0.035,0.072v0.035l0.019,0.072l0.018,0.035l0.018,0.071l0.018,0.036
                                                                        l0.018,0.072l0.02,0.07l0.018,0.036l0.018,0.072v0.034l0.035,0.072l0.018,0.036l0.02,0.071l0.018,0.072l0.018,0.035l0.035,0.072
                                                                        v0.035l0.037,0.036v0.071l0.035,0.072l0.018,0.035l0.019,0.035l0.035,0.072v0.035l0.035,0.072l0.02,0.035l0.018,0.072l0.035,0.036
                                                                        l0.037,0.071v0.035l0.035,0.071l0.035,0.036l0.019,0.072l0.018,0.035l0.035,0.072l0.018,0.035l0.037,0.036l0.018,0.035
                                                                        l0.035,0.071l0.018,0.037l0.037,0.07l0.018,0.036l0.035,0.071l0.037,0.035l0.035,0.072l0,0l0.037,0.072l0.035,0.035l0.035,0.072
                                                                        l0.02,0.035l0.052,0.071v0.036l0.055,0.036l0.018,0.036l0.035,0.07l0.037,0.037l0.035,0.07h0.018l0.054,0.072v0.035l0.072,0.072
                                                                        l0,0l0.07,0.071v0.036l0.055,0.07h0.018l0.054,0.108h0.018l0.054,0.071h0.018l0.053,0.072l0.02,0.035l0.053,0.071h0.018
                                                                        l0.072,0.072v0.035l0.072,0.071l0,0l0.072,0.072l0,0l0.07,0.107l0,0l0.072,0.07l0,0l0.089,0.072l0,0l0.072,0.071
                                                                        c0.448,0.466,0.948,0.896,1.485,1.253h0.017l0.269,0.179h0.018l0.269,0.144h0.037l0.072,0.07h0.035l0.054,0.037l0.035,0.035
                                                                        l0.054,0.036h0.053l0.055,0.035l0.035,0.036h0.072l0.035,0.035l0.054,0.037h0.053l0.054,0.035l0.055,0.035h0.035l0.072,0.036
                                                                        l0.035,0.036h0.072l0.035,0.035l0.072,0.035h0.035l0.072,0.037h0.037l0.07,0.035h0.037l0.072,0.036h0.018l0.089,0.035h0.018
                                                                        l0.089,0.036h0.018l0.089,0.035h0.018l0.09,0.037h0.017l0.109,0.035l0,0l0.107,0.035l0,0l0.107,0.036h0.018l0.089,0.036h0.018
                                                                        h0.089l0.018,0.036h0.089h0.02l0.107,0.035h0.018h0.089l0.035,0.036h0.072h0.037l0.07,0.035h0.054h0.053h0.054l0.055,0.037h0.07
                                                                        h0.054l0.054,0.035h0.07h0.055h0.054h0.07l0.037,0.035h0.07h0.037h0.107h0.018h0.089h0.037l0.07,0.036h0.037h0.107l0,0H144.104z
                                                                        M218.396,234.015l0.018,0.536l-0.018,0.537v0.035v0.037l0.018,0.07v0.036v0.107v0.036v0.071v0.035l0.02,0.072l0.018,0.036v0.07
                                                                        v0.072v0.071l0.018,0.036l0.018,0.072v0.035v0.071l0.017,0.071l0.02,0.035v0.072v0.036l0.035,0.071v0.036v0.071l0.035,0.035v0.108
                                                                        l0,0l0.037,0.106v0.037l0.018,0.07l0.018,0.036l0.018,0.071v0.037l0.02,0.07v0.036l0.035,0.106l0,0l0.035,0.108l0.037,0.107l0,0
                                                                        l0.035,0.106l0,0l0.037,0.108l0,0l0.035,0.106l0,0l0.035,0.107l0,0l0.037,0.107l0,0l0.035,0.107h0.018l0.018,0.071l0.02,0.036
                                                                        l0.035,0.072l0.018,0.035l0.018,0.071l0.02,0.036l0.035,0.07v0.037l0.035,0.071l0.037,0.036l0.018,0.071l0.018,0.036l0.037,0.07
                                                                        l0.018,0.037l0.018,0.035l0.035,0.072l0.02,0.035l0.035,0.072l0.018,0.035l0.054,0.071l0.035,0.071l0.018,0.037l0.037,0.07
                                                                        l0.018,0.036l0.053,0.071v0.035l0.037,0.072h0.018l0.055,0.071v0.036l0.07,0.072l0,0l0.054,0.107l0.054,0.071v0.035l0.07,0.071
                                                                        l0,0l0.054,0.107h0.018l0.037,0.072h0.035l0.035,0.07l0.037,0.037l0.035,0.071h0.018l0.054,0.071l0.018,0.035l0.055,0.072
                                                                        l0.018,0.035l0.035,0.036l0.037,0.036l0.053,0.036l0.018,0.071l0.037,0.035l0.035,0.037l0.037,0.035l0.035,0.035l0.035,0.036
                                                                        l0.055,0.071l0.035,0.035l0.037,0.037l0.035,0.035l0.035,0.036l0.179,0.179c0.502,0.502,1.057,0.93,1.646,1.323h0.037l0.107,0.072
                                                                        l0.053,0.036l0.037,0.035l0.053,0.035h0.037l0.072,0.036l0.035,0.036l0.072,0.036h0.035l0.054,0.035l0.142,0.071l0.055,0.037
                                                                        l0.053,0.035l0.054,0.035h0.035l0.072,0.036l0.037,0.036h0.07l0.037,0.035l0.072,0.037h0.035l0.055,0.035l0.052,0.035h0.055
                                                                        l0.053,0.036l0.107,0.036l0.054,0.035h0.055l0.052,0.035h0.055l0.053,0.037h0.054l0.054,0.035h0.07l0.037,0.036l0.072,0.035h0.035
                                                                        h0.072l0.035,0.036h0.089l0.144,0.072h0.07l0.109,0.035h0.035l0.072,0.035h0.035l0.089,0.036h0.018h0.107h0.02l0.089,0.036H226
                                                                        l0.072,0.036h0.035h0.089h0.018l0.107,0.035l0,0h0.108h0.035l0.089,0.036h0.018h0.09h0.018l0.108,0.035h0.018h0.09h0.035h0.089
                                                                        h0.018l0.107,0.037l0,0h0.126l0,0h0.125l0,0h0.107l0,0h0.126l0,0h0.125l0,0h0.125h0.109h0.018h0.107l0,0h0.107h0.037h0.107l0,0
                                                                        h0.107h0.018h0.089h0.035l0.09-0.037h0.019h0.107h0.018h0.09h0.035l0.09-0.035h0.018h0.108h0.018l0.09-0.036h0.035h0.089h0.018
                                                                        l0.089-0.035h0.037h0.089h0.035l0.072-0.036h0.035l0.072-0.036h0.035h0.089h0.037l0.072-0.036h0.053l0.072-0.035h0.035
                                                                        l0.179-0.035l0.037-0.036l0.125-0.036h0.055h0.053l0.126-0.036l0.035-0.035l0.072-0.036l0.16-0.035l0.054-0.037l0.107-0.035h0.037
                                                                        l0.07-0.035l0.037-0.036l0.179-0.036l0.035-0.035l0.286-0.107l0.037-0.036c1-0.465,1.877-1.108,2.646-1.86l0.179-0.179
                                                                        l0.037-0.036l0.035-0.035l0.037-0.037l0.035-0.035l0.053-0.071l0.037-0.036l0.035-0.035l0.037-0.035l0.035-0.037l0.035-0.035
                                                                        l0.019-0.071l0.053-0.036l0.037-0.036l0.035-0.036l0.018-0.035l0.055-0.072l0.018-0.035l0.054-0.071h0.018l0.035-0.071
                                                                        l0.037-0.037l0.035-0.07h0.018l0.054-0.072h0.018l0.053-0.107l0,0l0.072-0.071v-0.035l0.054-0.071l0.053-0.107l0,0l0.072-0.072
                                                                        v-0.036l0.054-0.071h0.018l0.035-0.072v-0.035l0.055-0.071l0.018-0.036l0.037-0.07l0.018-0.037l0.035-0.071l0.054-0.071
                                                                        l0.018-0.035l0.035-0.072l0.018-0.035l0.037-0.072l0.018-0.035l0.017-0.037l0.037-0.07l0.018-0.036l0.018-0.071l0.035-0.036
                                                                        l0.037-0.071v-0.037l0.035-0.07l0.018-0.036l0.019-0.071l0.018-0.035l0.035-0.072l0.018-0.036l0.02-0.071h0.018l0.035-0.107l0,0
                                                                        l0.037-0.107l0,0l0.035-0.107l0,0l0.035-0.106l0,0l0.037-0.108l0,0l0.035-0.106l0,0l0.035-0.107l0.037-0.108l0,0l0.035-0.106
                                                                        v-0.036l0.018-0.07v-0.037l0.02-0.071l0.018-0.036l0.018-0.07v-0.037l0.035-0.106l0,0l0.019-0.108v-0.035l0.018-0.071v-0.036
                                                                        l0.035-0.071v-0.036v-0.072l0.018-0.035l0.019-0.071v-0.071v-0.035l0.018-0.072l0.018-0.036v-0.071v-0.072v-0.07l0.018-0.036
                                                                        l0.018-0.072v-0.035v-0.071v-0.036v-0.107v-0.036l0.02-0.07v-0.037l0.018-0.107l0,0v-0.107l0,0v-0.106v-0.036v-0.107l0,0v-0.107
                                                                        v-0.143v-0.107v-0.143v-0.108l-0.018-0.106l-0.02-0.107v-0.143v-0.108l-0.035-0.144v-0.106l-0.018-0.107l-0.018-0.144
                                                                        l-0.019-0.107l-0.018-0.107l-0.035-0.107l-0.018-0.143l-0.019-0.107l-0.035-0.107l-0.018-0.107l-0.037-0.143l-0.018-0.107
                                                                        l-0.035-0.107l-0.037-0.107l-0.035-0.107l-0.035-0.107l-0.037-0.107l-0.035-0.107l-0.035-0.106l-0.037-0.144l-0.053-0.072v-0.035
                                                                        l-0.055-0.071l-0.035-0.144l-0.037-0.071l-0.053-0.106l-0.054-0.108l-0.035-0.106l-0.072-0.108l-0.037-0.107l0,0l-0.053-0.106
                                                                        l-0.054-0.108l-0.07-0.071l-0.037-0.107l0,0l-0.072-0.107l0,0L236,229.613l0,0l-0.054-0.106l0,0l-0.072-0.072l0,0l-0.125-0.215
                                                                        l-0.055-0.071l-0.07-0.107l-0.072-0.071l0,0l-0.144-0.215l-0.125-0.179l0,0l-0.089-0.071l0,0l-0.072-0.072v-0.036l-0.214-0.25l0,0
                                                                        l-0.072-0.072l0,0l-0.072-0.07l0,0l-0.179-0.18l-0.07-0.071l0,0l-0.072-0.071l-0.037-0.035l-0.052-0.072
                                                                        c-0.555-0.5-1.181-0.965-1.843-1.323l0,0l-0.393-0.216l0,0L232.01,226v-0.035l-0.089-0.036h-0.018l-0.321-0.144l-0.072-0.035
                                                                        h-0.035l-0.054-0.036h-0.055l-0.249-0.106l-0.072-0.037h-0.037l-0.07-0.035h-0.037l-0.07-0.036h-0.037l-0.089-0.035h-0.018
                                                                        l-0.107-0.036l-0.107-0.035l0,0h-0.109l0,0l-0.017-0.037l-0.197-0.035l0,0l-0.107-0.035h-0.018l-0.089-0.036h-0.037h-0.07h-0.037
                                                                        l-0.231-0.036l-0.072-0.036h-0.054h-0.07l-0.037-0.035h-0.072h-0.053h-0.054h-0.072l-0.035-0.036h-0.072h-0.052h-0.072h-0.035
                                                                        l-0.089-0.035h-0.037h-0.072h-0.035h-0.089h-0.018h-0.107h-0.02h-0.087h-0.037h-0.107v-0.037h-0.107l0,0h-0.018h-0.109h-0.125l0,0
                                                                        h-0.107l-0.018,0.037h-0.107h-0.02h-0.107l0,0h-0.107h-0.018h-0.107h-0.019h-0.107h-0.018l-0.089,0.035h-0.035h-0.072h-0.035
                                                                        h-0.108l-0.018,0.036h-0.09h-0.018h-0.089h-0.035l-0.089,0.035h-0.019h-0.107l-0.018,0.036h-0.089h-0.035L226,225.249h-0.035
                                                                        h-0.072h-0.037l-0.089,0.036h-0.035l-0.072,0.035h-0.053l-0.072,0.035H225.5h-0.072l-0.037,0.037h-0.07l-0.037,0.035h-0.072
                                                                        h-0.035l-0.072,0.036h-0.053l-0.054,0.035h-0.053l-0.054,0.036h-0.055l-0.053,0.035h-0.072l-0.054,0.037h-0.053l-0.055,0.035
                                                                        l-0.052,0.035h-0.037l-0.072,0.036h-0.053l-0.054,0.036l-0.035,0.035h-0.072l-0.035,0.035l-0.072,0.037h-0.035l-0.072,0.035
                                                                        h-0.037l-0.07,0.036l-0.037,0.036L223.745,226h-0.035l-0.054,0.035l-0.053,0.037l-0.055,0.035h-0.035l-0.055,0.035l-0.035,0.036
                                                                        h-0.072l-0.035,0.036l-0.072,0.036l-0.035,0.036l-0.072,0.035h-0.037l-0.053,0.035l-0.018,0.037l-0.072,0.035h-0.035l-0.072,0.071
                                                                        h-0.037l-0.07,0.036l-0.02,0.035l-0.053,0.037h-0.037l-0.07,0.07h-0.037l-0.072,0.072l0,0l-0.07,0.035l-0.037,0.035l-0.07,0.037
                                                                        v0.035l-0.072,0.036l-0.037,0.035l-0.07,0.036h-0.02l-0.07,0.072h-0.019l-0.07,0.07l0,0l-0.089,0.072h-0.02l-0.07,0.071l0,0
                                                                        l-0.072,0.071h-0.018l-0.072,0.071h-0.018l-0.072,0.072l0,0l-0.09,0.071v0.035l-0.089,0.072l0,0l-0.072,0.071l0,0l-0.072,0.071
                                                                        c-0.393,0.358-0.733,0.752-1.037,1.217l0,0l-0.43,0.645v0.035l-0.214,0.322l-0.018,0.072l-0.107,0.214l-0.018,0.036l-0.037,0.035
                                                                        v0.037l-0.035,0.07l-0.037,0.036l-0.035,0.071v0.037l-0.035,0.07v0.036l-0.037,0.071l-0.018,0.035l-0.035,0.072v0.036
                                                                        l-0.037,0.071h-0.018l-0.035,0.107l0,0l-0.037,0.107l0,0l-0.035,0.107l-0.035,0.106l0,0l-0.037,0.108v0.035l-0.035,0.072v0.035
                                                                        l0,0l-0.072,0.18v0.035l-0.035,0.071v0.036l-0.02,0.07l-0.018,0.037v0.107h-0.018l-0.018,0.107v0.035l-0.037,0.071v0.036v0.072
                                                                        l-0.035,0.035v0.072v0.035l-0.035,0.071v0.036v0.035v0.037l-0.02,0.07l-0.017,0.036v0.071v0.072l-0.018,0.071l-0.018,0.035v0.072
                                                                        v0.036v0.07l-0.018,0.036l-0.02,0.107v0.036v0.072v0.035v0.071v0.036l-0.018,0.107l0,0V234.015z
                                                                        M144.086,244.033h0.411h0.395
                                                                        c2.164-0.108,4.15-0.932,5.706-2.22c0.16-0.25,0.375-0.501,0.59-0.751c0.214-0.215,0.43-0.357,0.681-0.537
                                                                        c1.269-1.574,2.057-3.576,2.111-5.76v-0.107l0,0v-0.107v-0.107v-0.035c-0.037-2.541-1.11-4.901-2.792-6.584l0,0
                                                                        c-1.628-1.609-3.864-2.683-6.315-2.754h-0.375h-0.393c-2.362,0.071-4.525,1.037-6.154,2.539l-0.035,0.037l-0.072,0.106
                                                                        l-0.089,0.071l0,0c-1.591,1.61-2.63,3.793-2.772,6.19l0.035,0.536l-0.035,0.501c0.107,2.075,0.895,3.972,2.074,5.474
                                                                        c0.251,0.144,0.502,0.322,0.698,0.537c0.233,0.25,0.448,0.501,0.626,0.787C139.937,243.138,141.921,243.961,144.086,244.033z
                                                                        M144.927,308.469h-0.43h-0.428c-2.469,0.071-4.687,1.145-6.333,2.754l-0.072,0.072l0,0l-0.072,0.072l-0.072,0.106l-0.07,0.071
                                                                        l0,0l-0.09,0.072l0,0l-0.072,0.106l0,0l-0.072,0.072l0,0l-0.072,0.071l-0.018,0.035l-0.053,0.072h-0.019l-0.053,0.071
                                                                        l-0.018,0.036l-0.037,0.072h-0.018l-0.055,0.07l-0.018,0.036l-0.052,0.071h-0.018l-0.055,0.072v0.035l-0.072,0.071v0.036
                                                                        l-0.053,0.072h-0.019l-0.035,0.07l-0.035,0.036l-0.037,0.072l-0.018,0.036l-0.054,0.035v0.035l-0.035,0.072l-0.035,0.035
                                                                        l-0.037,0.072l-0.018,0.035l-0.035,0.072h-0.019l-0.035,0.071l-0.018,0.036l-0.055,0.07v0.037l-0.035,0.071l-0.035,0.035
                                                                        l-0.019,0.072l-0.018,0.036l-0.035,0.07l-0.018,0.036l-0.037,0.036l-0.018,0.071l-0.035,0.036v0.035l-0.037,0.071l-0.035,0.072
                                                                        l-0.018,0.036l-0.02,0.035l-0.035,0.072v0.035l-0.035,0.071l-0.019,0.036l-0.018,0.072l-0.035,0.035v0.071l-0.037,0.036
                                                                        l-0.018,0.071l-0.018,0.071l-0.035,0.036v0.071l-0.037,0.036v0.071l-0.035,0.035v0.072l-0.035,0.036v0.071l-0.02,0.035
                                                                        l-0.018,0.072l-0.018,0.035l-0.018,0.072l-0.018,0.035l-0.019,0.072v0.035l-0.035,0.107l0,0l-0.035,0.107v0.036l-0.019,0.071
                                                                        v0.036l-0.018,0.071l-0.018,0.035l-0.018,0.072v0.036v0.106h-0.018l-0.02,0.107v0.036l-0.018,0.071l-0.018,0.035v0.072v0.036
                                                                        l-0.018,0.107h-0.018v0.106v0.036l-0.019,0.072v0.035l-0.018,0.108l0,0v0.106l0,0l-0.018,0.106v0.037v0.107h-0.018v0.107l0,0
                                                                        v0.143l0,0v0.107l0,0v0.107v0.035v0.107v0.107l0,0v0.107v0.035v0.108l0,0v0.106v0.037v0.106h0.018v0.106l0,0l0.018,0.108v0.035
                                                                        v0.108l0,0l0.018,0.106l0,0l0.019,0.108v0.035v0.107h0.018l0.018,0.107l0,0v0.107l0.018,0.035l0.055,0.215v0.107l0,0l0.018,0.107
                                                                        l0.018,0.035l0.018,0.072v0.036l0.019,0.071v0.035l0.179,0.645l0.035,0.036v0.071l0.035,0.035l0.072,0.215l0.018,0.072
                                                                        c0.484,1.287,1.235,2.432,2.183,3.398c0.877,0.859,1.95,1.574,3.113,2.074h0.018l0.09,0.037h0.018l0.089,0.035h0.018l0.109,0.036
                                                                        l0,0l0.214,0.071l0,0l0.107,0.036l0,0l0.107,0.071l0,0h0.107h0.019l0.09,0.035h0.018l0.107,0.036h0.018l0.214,0.072l0,0
                                                                        l0.109,0.035l0,0h0.125l0,0l0.107,0.036h0.018l0.109,0.035l0,0l0.231,0.037l0,0h0.125l0,0l0.108,0.035l0,0h0.107h0.018
                                                                        l0.107,0.035h0.018h0.107l0,0h0.109h0.035l0.107,0.036l0,0h0.107l0,0h0.126h0.018h0.107l0,0h0.125l0,0h0.126l0,0h0.107h0.018
                                                                        h0.126h0.107h0.107h0.018h0.107h0.018h0.109h0.018h0.089h0.035l0.089-0.036h0.018h0.107h0.019h0.089h0.035l0.072-0.035h0.035h0.09
                                                                        h0.037h0.07l0.054-0.035h0.072h0.035h0.072l0.053-0.037h0.019l0.142-0.035h0.072h0.054l0.053-0.036h0.054h0.072l0.053-0.035h0.072
                                                                        h0.035l0.072-0.036h0.035l0.072-0.036h0.037l0.07-0.036h0.037h0.072l0.035-0.035h0.09l0.017-0.035l0.09-0.036h0.018l0.107-0.036
                                                                        l0,0l0.109-0.036l0,0l0.107-0.035l0.107-0.036l0.107-0.035l0,0l0.107-0.037l0,0l0.089-0.035h0.018l0.109-0.071l0.07-0.036h0.037
                                                                        l0.072-0.035h0.035l0.072-0.035l0.035-0.037l0.072-0.035h0.017l0.072-0.036h0.035l0.054-0.035l0.035-0.036l0.072-0.035
                                                                        l0.035-0.037h0.072l0.037-0.035l0.107-0.071h0.035l0.055-0.036l0.052-0.036l0.037-0.035h0.053l0.054-0.036l0.035-0.035
                                                                        l0.072-0.036l0.035-0.036l0.037-0.035h0.035l0.072-0.072h0.035l0.054-0.035l0.018-0.035l0.072-0.037l0.035-0.035l0.072-0.036l0,0
                                                                        l0.072-0.071h0.035l0.072-0.072l0,0l0.179-0.143l0,0l0.072-0.072h0.018l0.089-0.07l0,0l0.072-0.072l0,0l0.07-0.071h0.02
                                                                        l0.07-0.071h0.019l0.07-0.071l0,0l0.072-0.072h0.018l0.02-0.036l0.142-0.143c0.286-0.322,0.572-0.608,0.823-0.93v-0.037
                                                                        l0.072-0.07l0,0l0.072-0.072v-0.035l0.07-0.072l0,0l0.054-0.107h0.018l0.037-0.07l0.017-0.037l0.053-0.071l0,0l0.072-0.107l0,0
                                                                        l0.054-0.071l0.018-0.035l0.035-0.072h0.019l0.053-0.107l0,0l0.055-0.106l0,0l0.052-0.108l0,0l0.072-0.07l0,0l0.035-0.108l0,0
                                                                        l0.054-0.106h0.018l0.037-0.107l0,0l0.053-0.108l0,0l0.055-0.106l0,0l0.035-0.107h0.017l0.037-0.107h0.018l0.035-0.107l0,0
                                                                        l0.037-0.107l0,0l0.035-0.107h0.018l0.037-0.107l0,0l0.035-0.107h0.018l0.018-0.107h0.02l0.035-0.107l0,0l0.035-0.106l0,0
                                                                        l0.037-0.108l0,0l0.035-0.106l0,0l0.018-0.107h0.019l0.018-0.108l0,0l0.018-0.143h0.018l0.018-0.107l0,0l0.037-0.106l0,0
                                                                        l0.017-0.108h0.018v-0.143h0.018l0.02-0.107l0,0l0.018-0.107h0.018v-0.107l0,0l0.035-0.143l0,0v-0.107l0,0l0.019-0.107h0.018
                                                                        v-0.144l0,0l0.018-0.106l0,0l0.018-0.108l0,0v-0.144l0,0v-0.106l0,0l0.018-0.106l0,0v-0.144l0.019-0.108v-0.143v-0.107l0,0v-0.107
                                                                        l0,0v-0.107v-0.035l0,0v-0.107l-0.019-0.107l0,0v-0.107l-0.018-0.25l0,0l-0.035-0.215v-0.036l-0.018-0.214l0,0l-0.019-0.108l0,0
                                                                        l-0.018-0.106l0,0l-0.018-0.107v-0.036l-0.018-0.071v-0.035l-0.018-0.108l0,0l-0.037-0.106l0,0l-0.018-0.107l0,0l-0.017-0.108l0,0
                                                                        l-0.037-0.106l0,0l-0.018-0.107l-0.018-0.036l-0.018-0.071v-0.035l-0.018-0.072l-0.019-0.036l-0.018-0.071v-0.035l-0.035-0.072
                                                                        v-0.035l-0.037-0.072v-0.035l-0.035-0.072v-0.035l-0.035-0.072v-0.035l-0.037-0.072l-0.018-0.035l-0.018-0.071l-0.017-0.036
                                                                        l-0.018-0.072l-0.02-0.035l-0.035-0.072l-0.018-0.035l-0.018-0.036l-0.019-0.071l-0.035-0.035v-0.037l-0.035-0.07l-0.02-0.036
                                                                        l-0.017-0.072l-0.035-0.034l-0.018-0.072l-0.02-0.036l-0.035-0.071l-0.018-0.036l-0.018-0.036l-0.037-0.07l-0.035-0.036v-0.036
                                                                        l-0.037-0.071l-0.035-0.036l-0.018-0.072l-0.018-0.035l-0.037-0.035l-0.035-0.072l-0.018-0.035l-0.018-0.035l-0.037-0.072
                                                                        l-0.035-0.036l-0.037-0.036l-0.018-0.07l-0.018-0.037l-0.035-0.035l-0.037-0.071l-0.035-0.036l-0.037-0.036l-0.018-0.036
                                                                        l-0.018-0.07l-0.035-0.036l-0.037-0.036l-0.035-0.071l-0.037-0.036l-0.035-0.035l-0.018-0.037l-0.037-0.07l-0.017-0.036
                                                                        l-0.053-0.036l-0.02-0.035l-0.035-0.072l-0.035-0.035l-0.037-0.036l-0.035-0.035l-0.035-0.071h-0.037l-0.035-0.072l-0.037-0.035
                                                                        l-0.035-0.036l-0.035-0.036l-0.037-0.071h-0.035l-0.037-0.071l-0.035-0.036l-0.035-0.036l-0.037-0.035l-0.035-0.036l-0.037-0.036
                                                                        l0,0l-0.214-0.215c-0.393-0.393-0.841-0.751-1.288-1.072h-0.018l-0.089-0.072l0,0l-0.393-0.25l0,0l-0.286-0.18l0,0l-0.109-0.07
                                                                        l-0.214-0.072v-0.036l-0.09-0.035l0,0l-0.107-0.036l-0.107-0.072l-0.089-0.035l-0.107-0.035l-0.109-0.072l-0.107-0.035l0,0
                                                                        l-0.107-0.035l0,0l-0.107-0.037l-0.107-0.071l-0.107-0.036l-0.126-0.035l-0.107-0.035l-0.125-0.037l0,0l-0.107-0.035h-0.108
                                                                        l-0.107-0.035l-0.107-0.036l-0.125-0.036l-0.107-0.036l-0.126-0.036h-0.107l-0.125-0.035h-0.126l-0.107-0.035l-0.107-0.036h-0.125
                                                                        h-0.126l-0.107-0.036h-0.125l-0.126-0.035h-0.107h-0.125h-0.126H144.927z
                                                                        M193.997,186c-1.181-1.181-2.809-1.896-4.615-1.896
                                                                        c-1.808,0-3.436,0.715-4.617,1.896c-1.179,1.182-1.913,2.827-1.913,4.615c0,1.646,0.607,3.148,1.591,4.294l0,0l0.321,0.322
                                                                        c1.181,1.181,2.809,1.932,4.617,1.932c1.806,0,3.434-0.751,4.615-1.932c1.181-1.146,1.915-2.791,1.915-4.616
                                                                        c0-1.717-0.681-3.291-1.753-4.436L193.997,186z
                                                                        M202.493,182.709c1.395,2.325,2.218,5.045,2.218,7.906
                                                                        c0,4.223-1.734,8.051-4.489,10.841c-2.774,2.791-6.602,4.509-10.841,4.509c-2.881,0-5.6-0.824-7.889-2.219l-21.361,21.359
                                                                        c0.949,1.539,1.646,3.256,2.111,5.045h47.872v-0.035l0.037-0.144l0.035-0.071l0.035-0.144l0.02-0.036l0.035-0.179l0.018-0.035
                                                                        l0.037-0.179l0.035-0.036l0.035-0.179l0.018-0.037l0.054-0.178v-0.035l0.035-0.037l0.109-0.321v-0.036l0,0l0.07-0.215h0.02
                                                                        l0.053-0.18l0.018-0.034l0.089-0.179l0.072-0.215l0,0l0.072-0.18v-0.036l0.107-0.178v-0.036l0.072-0.143l0.018-0.037l0.072-0.178
                                                                        l0.018-0.037l0.072-0.143l0.018-0.035l0.089-0.18l0.018-0.035l0.072-0.143l0.09-0.18l0.035-0.072l0.072-0.143l0.035-0.035
                                                                        l0.072-0.144l0.037-0.071l0.07-0.107l0.037-0.071l0.072-0.108l0.035-0.106l0.072-0.107l0.035-0.071l0.072-0.107l0.055-0.072
                                                                        l0.053-0.107l0.072-0.071l0.053-0.106l0.054-0.072l0.072-0.107l0.052-0.107l0.055-0.071l0.072-0.107l0.053-0.036l0.126-0.179
                                                                        l0.07-0.144l0.055-0.035l0.089-0.143l0.035-0.037l0.089-0.143l0.055-0.035l0.089-0.107l0.018-0.036l0.107-0.144l0.037-0.035
                                                                        l0.107-0.144l0.035-0.035l0.251-0.286l0.017-0.035l0.126-0.145h0.018l0.125-0.178l0,0l0.162-0.145l0,0l0.142-0.143v-0.035
                                                                        l0.162-0.145l0.144-0.178c0.769-0.752,1.609-1.467,2.522-2.076l0.072-0.07l0.769-0.501l0.072-0.036l0.323-0.215l0.07-0.035
                                                                        l0.072-0.036l0.107-0.071h0.037l0.125-0.072l0.055-0.035l0.07-0.036l0.108-0.071h0.035l0.144-0.072h0.018l0.125-0.07l0.072-0.036
                                                                        l0.072-0.036l0.125-0.07h0.018l0.126-0.072l0.052-0.036l0.09-0.036l0.125-0.035h0.019l0.142-0.072l0.037-0.035l0.107-0.037
                                                                        l0.107-0.035l0.035-0.035l0.144-0.036l0.018-0.036l0.125-0.035l0.089-0.035l0.055-0.037l0.161-0.035l0,0l0.142-0.071h0.072
                                                                        l0.072-0.036l0.125-0.035l0.018-0.037l0.144-0.035l0.054-0.035h0.09l0.125-0.072h0.035l0.161-0.036l0,0l0.144-0.071h0.089
                                                                        l0.053-0.035l0.144-0.036h0.018l0.142-0.035l0.072-0.036h0.089l0.144-0.036v-0.036l0.162-0.035v-47.871l-0.162-0.035l0,0
                                                                        l-0.144-0.037l-0.072-0.035h-0.07l-0.144-0.035h-0.018l-0.162-0.072h-0.035l-0.107-0.035l-0.109-0.036h-0.035l-0.142-0.071h-0.037
                                                                        l-0.125-0.037l-0.09-0.034h-0.054l-0.162-0.072l0,0l-0.142-0.036l-0.072-0.035l-0.072-0.035l-0.125-0.037h-0.018l-0.144-0.071
                                                                        h-0.053l-0.089-0.071l-0.109-0.035h-0.035l-0.142-0.072h-0.037l-0.107-0.071l-0.107-0.036h-0.037l-0.142-0.07l0,0l-0.144-0.072
                                                                        l-0.07-0.036l-0.072-0.035l-0.144-0.036l0,0l-0.142-0.072l-0.037-0.035l-0.089-0.035l-0.125-0.072h-0.018l-0.126-0.072
                                                                        l-0.035-0.035l-0.107-0.035l-0.107-0.072h-0.037l-0.125-0.107h-0.018l-0.126-0.072l-0.07-0.035l-0.055-0.035l-0.144-0.072l0,0
                                                                        l-0.125-0.07l-0.055-0.037L202.493,182.709z
                                                                        M189.382,175.303c2.379,0,4.632,0.537,6.636,1.503l16.207-16.208l-0.018-0.035
                                                                        l-0.089-0.144l0,0l-0.072-0.144l-0.035-0.035l-0.037-0.072l-0.07-0.143l0,0l-0.072-0.143l-0.037-0.036l-0.053-0.106l-0.055-0.108
                                                                        h-0.018l-0.072-0.144l-0.018-0.035l-0.053-0.107l-0.037-0.107l-0.035-0.035l-0.054-0.144h-0.018l-0.055-0.144l-0.035-0.071
                                                                        l-0.035-0.071l-0.055-0.107l-0.018-0.035l-0.054-0.145l-0.018-0.035l-0.055-0.107l-0.052-0.107v-0.035l-0.072-0.144v-0.036
                                                                        l-0.035-0.107l-0.037-0.071l-0.035-0.071l-0.054-0.143h-0.018l-0.035-0.143l-0.037-0.072l-0.018-0.071l-0.052-0.144l0,0
                                                                        l-0.055-0.144l-0.018-0.071l-0.037-0.107l-0.035-0.107v-0.035l-0.054-0.144l-0.018-0.036l-0.035-0.107l-0.035-0.106v-0.036
                                                                        l-0.055-0.143v-0.036l-0.035-0.144l-0.02-0.072l-0.035-0.07l-0.035-0.143l0,0l-0.037-0.144h-47.872l-0.035,0.144h-0.017
                                                                        l-0.037,0.143l-0.018,0.07l-0.018,0.108l-0.054,0.144l0,0l-0.035,0.143l-0.018,0.072l-0.018,0.07l-0.037,0.144h-0.018
                                                                        l-0.053,0.179v0.036l-0.037,0.106l-0.035,0.108l-0.037,0.035l-0.035,0.144v0.035l-0.054,0.108l-0.018,0.071l-0.035,0.072
                                                                        l-0.055,0.143l-0.052,0.143l-0.037,0.071l-0.035,0.071l-0.037,0.144l-0.018,0.036l-0.053,0.143l-0.019,0.036l-0.053,0.107
                                                                        l-0.035,0.071l-0.02,0.072l-0.052,0.143h-0.018l-0.055,0.143l-0.035,0.072l-0.037,0.072l-0.072,0.106l-0.053,0.144l-0.037,0.071
                                                                        l-0.053,0.071l-0.037,0.107l-0.018,0.035l-0.072,0.144l-0.018,0.036l-0.053,0.107l-0.054,0.107l-0.035,0.035l-0.072,0.107v0.036
                                                                        l-0.072,0.106l-0.035,0.072l-0.037,0.071l-0.07,0.144l-0.089,0.107l-0.037,0.071l-0.053,0.071l-0.054,0.107l-0.018,0.036
                                                                        l-0.072,0.106l-0.035,0.037l-0.055,0.106l-0.052,0.108l-0.037,0.035l-0.072,0.107l-0.035,0.036l-0.072,0.106l-0.035,0.071
                                                                        l-0.037,0.036l-0.107,0.143l-0.07,0.108l-0.037,0.071l-0.072,0.072l-0.07,0.106l0,0l-0.109,0.144l-0.018,0.035l-0.072,0.072
                                                                        l-0.07,0.071l-0.02,0.036l-0.107,0.143l0,0l-0.089,0.107l-0.053,0.072l-0.037,0.035l-0.107,0.143l-0.107,0.107l-0.035,0.036
                                                                        l-0.072,0.071l-0.072,0.107h-0.018l-0.09,0.144l-0.037,0.036l-0.07,0.07l-0.072,0.072l-0.035,0.036l-0.109,0.106l-0.018,0.037
                                                                        l-0.089,0.07l-0.072,0.072l-0.035,0.035l-0.107,0.107c-1.467,1.467-3.148,2.647-5.01,3.578h-0.053l-0.161,0.071l-0.035,0.036
                                                                        l-0.358,0.143h-0.037l-0.393,0.18l0,0l-0.196,0.071l-0.197,0.106l0,0l-0.412,0.144h-0.018l-0.358,0.106l-0.035,0.037h-0.037
                                                                        l-0.177,0.035l-0.037,0.036l-0.162,0.036l-0.052,0.035l-0.144,0.036l-0.072,0.036l-0.143,0.035h-0.072l-0.142,0.035l-0.072,0.037
                                                                        l-0.144,0.035v47.906c1.79,0.43,3.471,1.146,5.01,2.075l21.645-21.646c-0.965-2.003-1.502-4.221-1.502-6.619
                                                                        c0-4.221,1.718-8.05,4.508-10.805C181.313,177.021,185.142,175.303,189.382,175.303z
                                                                        M104.176,186
                                                                        c-1.163-1.181-2.809-1.896-4.615-1.896c-1.79,0-3.436,0.715-4.617,1.896c-1.181,1.182-1.896,2.827-1.896,4.615
                                                                        c0,1.825,0.714,3.471,1.896,4.616c1.181,1.181,2.827,1.932,4.617,1.932c1.806,0,3.452-0.751,4.615-1.932l0.179-0.144l0.053-0.072
                                                                        l0.018-0.035h0.037l0.035-0.071c1.002-1.146,1.611-2.647,1.611-4.294c0-1.717-0.681-3.291-1.753-4.436L104.176,186z
                                                                        M107.468,203.746c-2.309,1.395-5.01,2.219-7.907,2.219c-4.222,0-8.051-1.718-10.823-4.509c-2.772-2.755-4.491-6.583-4.491-10.841
                                                                        c0-2.861,0.806-5.581,2.201-7.906l-15.921-15.922l-0.037,0.037h-0.018l-0.107,0.07l-0.089,0.036l-0.054,0.036l-0.125,0.07H70.08
                                                                        l-0.126,0.072l-0.053,0.036l-0.072,0.071l-0.107,0.035l-0.035,0.037l-0.144,0.07l0,0l-0.144,0.072l-0.07,0.036l-0.072,0.036
                                                                        l-0.107,0.035h-0.018l-0.144,0.071l-0.035,0.036l-0.089,0.036l-0.109,0.035l-0.035,0.036l-0.144,0.072l0,0l-0.142,0.07h-0.072
                                                                        l-0.072,0.036l-0.107,0.071h-0.035l-0.144,0.072h-0.035l-0.107,0.035l-0.089,0.036l-0.055,0.035l-0.142,0.071l0,0l-0.161,0.037
                                                                        l-0.055,0.035l-0.089,0.035l-0.107,0.036h-0.035l-0.162,0.072l0,0l-0.144,0.034l-0.07,0.037h-0.072l-0.144,0.035v0.036
                                                                        l-0.16,0.036H66.34l-0.125,0.035l-0.089,0.036l-0.055,0.036l-0.142,0.035l0,0l-0.144,0.035h-0.072l-0.107,0.037l-0.107,0.035
                                                                        h-0.035v47.871l0.142,0.035l0.018,0.036l0.126,0.036h0.107l0.035,0.036l0.144,0.035h0.035l0.126,0.036l0.089,0.035h0.053
                                                                        l0.144,0.037l0.018,0.034l0.125,0.036h0.089l0.055,0.036l0.142,0.036h0.019l0.107,0.07h0.089l0.053,0.037l0.144,0.035l0.018,0.036
                                                                        l0.125,0.035l0.09,0.036h0.054l0.144,0.072l0,0l0.142,0.07h0.072l0.053,0.036l0.144,0.071h0.018l0.107,0.035l0.072,0.037
                                                                        l0.072,0.035l0.142,0.072l0,0l0.126,0.035l0.09,0.036l0.053,0.036l0.126,0.072l0,0l0.142,0.07l0.072,0.036l0.054,0.036
                                                                        l0.125,0.035l0.018,0.035l0.126,0.037l0.07,0.035l0.054,0.036l0.125,0.071h0.02l0.125,0.072l0.072,0.035l0.053,0.035l0.126,0.072
                                                                        l0.125,0.07l0.072,0.037l0.053,0.035l0.144,0.107l0.107,0.072l0.072,0.035l0.072,0.035l0.107,0.072l0.142,0.071l0.054,0.036
                                                                        l0.055,0.072l0.125,0.07l0.125,0.072l0.055,0.035l0.052,0.072l0.144,0.071l0,0l0.107,0.071l0.055,0.035l0.053,0.072l0.126,0.071
                                                                        l0,0l0.125,0.108l0.055,0.035l0.053,0.035l0.107,0.107l0,0l0.144,0.072l0.035,0.035l0.072,0.072l0.107,0.071l0,0l0.107,0.106
                                                                        l0.055,0.037l0.054,0.071l0.107,0.071h0.018l0.107,0.107l0.055,0.035l0.07,0.072l0.089,0.071l0.019,0.036l0.107,0.071l0.035,0.071
                                                                        l0.055,0.036l0.107,0.106h0.018l0.144,0.144l0.179,0.144c0.858,0.895,1.646,1.86,2.343,2.898l0,0l0.126,0.179l0,0l0.107,0.179l0,0
                                                                        l0.107,0.215h0.018l0.107,0.179l0,0l0.107,0.179h0.02l0.089,0.215h0.018l0.089,0.178h0.018l0.09,0.215h0.018l0.089,0.18l0,0
                                                                        l0.089,0.215l0,0l0.107,0.18l0,0l0.107,0.213l0.072,0.18l0.107,0.215l0,0l0.072,0.214l0.107,0.216l0.072,0.178l0.072,0.216h0.018
                                                                        l0.072,0.214l0,0l0.072,0.215l0.07,0.215l0.072,0.215l0.072,0.215l0.072,0.215l0.053,0.214l0.072,0.215l0,0l0.054,0.215
                                                                        l0.053,0.179h47.872c0.447-1.789,1.144-3.506,2.093-5.045L107.468,203.746z
                                                                        M99.562,175.303c4.239,0,8.084,1.718,10.839,4.508
                                                                        l0.306,0.322c2.593,2.754,4.185,6.44,4.185,10.482c0,2.361-0.537,4.616-1.502,6.619l21.645,21.646
                                                                        c1.557-0.93,3.257-1.646,5.064-2.11v-47.871h-0.037l-0.142-0.035l-0.072-0.037l-0.144-0.035h-0.072l-0.143-0.035l-0.072-0.036
                                                                        l-0.142-0.036l-0.072-0.035l-0.144-0.036l-0.072-0.036l-0.142-0.035h-0.055l-0.16-0.071h-0.037l-0.179-0.072h-0.035l-0.179-0.071
                                                                        h-0.018l-0.197-0.072l0,0l-0.198-0.106h-0.018l-0.196-0.071l0,0l-0.197-0.072l-0.196-0.107h-0.018l-0.179-0.07h-0.019
                                                                        l-0.197-0.108l0,0l-0.179-0.071h-0.035l-0.162-0.107h-0.018l-0.179-0.071l-0.035-0.036l-0.144-0.072l-0.035-0.035l-0.161-0.072
                                                                        l-0.055-0.035l-0.142-0.071l-0.037-0.036l-0.142-0.072l-0.055-0.035l-0.125-0.071l-0.072-0.036l-0.107-0.07l-0.072-0.037
                                                                        l-0.125-0.071l-0.072-0.035l-0.107-0.072l-0.072-0.071l-0.107-0.035l-0.09-0.072l-0.089-0.071l-0.089-0.036l-0.089-0.072
                                                                        l-0.09-0.07l-0.072-0.036l-0.107-0.071L133.835,166l-0.107-0.071l-0.072-0.035l-0.107-0.108l-0.07-0.035l-0.109-0.071
                                                                        l-0.053-0.072l-0.126-0.071l-0.035-0.035l-0.125-0.107l-0.054-0.036l-0.125-0.106l-0.037-0.037l-0.125-0.107l-0.035-0.035
                                                                        l-0.144-0.107l-0.018-0.035l-0.126-0.107h-0.035l-0.144-0.144h-0.018l-0.143-0.144h-0.017l-0.144-0.143v-0.036l-0.144-0.106
                                                                        l-0.179-0.18c-0.876-0.859-1.645-1.824-2.325-2.826l-0.035-0.035l-0.465-0.752l0,0l-0.306-0.537l-0.017-0.035l-0.072-0.107
                                                                        l-0.142-0.287v-0.035l-0.054-0.107l-0.142-0.286l0,0l-0.072-0.106l-0.018-0.072l-0.037-0.072l-0.07-0.143l0,0l-0.072-0.143v-0.072
                                                                        l-0.055-0.071l-0.053-0.107v-0.036l-0.072-0.143v-0.036l-0.054-0.144l-0.035-0.071l-0.018-0.071l-0.072-0.143l0,0l-0.035-0.143
                                                                        l-0.037-0.072l-0.035-0.071l-0.037-0.108v-0.035l-0.053-0.144l-0.018-0.035l-0.037-0.108l-0.035-0.106l-0.018-0.036l-0.055-0.179
                                                                        l0,0l-0.035-0.144l-0.037-0.07v-0.072l-0.053-0.143l0,0l-0.054-0.144v-0.108l-0.035-0.07l-0.037-0.143l0,0l-0.035-0.144H78.845
                                                                        l-0.053,0.144l0,0l-0.037,0.143l-0.018,0.07l-0.035,0.108l-0.037,0.144l0,0l-0.035,0.143l-0.018,0.072l-0.02,0.07l-0.053,0.144
                                                                        h-0.017l-0.037,0.179l-0.018,0.036l-0.035,0.106l-0.037,0.108l-0.018,0.035l-0.035,0.144l-0.018,0.035l-0.054,0.108l-0.018,0.071
                                                                        l-0.018,0.072l-0.055,0.143l-0.054,0.143l-0.035,0.071l-0.035,0.071l-0.037,0.144l-0.018,0.036l-0.055,0.143l-0.035,0.036
                                                                        l-0.035,0.107l-0.037,0.071l-0.035,0.072l-0.054,0.143h-0.018l-0.035,0.143l-0.037,0.072l-0.035,0.072l-0.072,0.106l-0.072,0.144
                                                                        l-0.035,0.071l-0.035,0.071l-0.054,0.107l-0.018,0.035l-0.072,0.144v0.036l-0.072,0.107l-0.035,0.107l-0.035,0.035l-0.072,0.107
                                                                        v0.036l-0.072,0.106l-0.035,0.072l-0.037,0.071l-0.089,0.144l-0.072,0.107l-0.018,0.035l16.208,16.208
                                                                        C94.945,175.84,97.2,175.303,99.562,175.303z
                                                                        M105,273.656c-1.146-1.073-2.738-1.717-4.456-1.717c-1.716,0-3.31,0.68-4.454,1.753
                                                                        l-0.161,0.144c-1.164,1.18-1.915,2.826-1.915,4.614c0,1.718,0.662,3.256,1.7,4.401l0.053,0.071l0,0l0.072,0.071l0.072,0.072h0.018
                                                                        c1.181,1.181,2.827,1.932,4.615,1.932c1.735,0,3.292-0.645,4.438-1.717l0.177-0.215c1.181-1.182,1.914-2.827,1.914-4.616
                                                                        c0-1.717-0.679-3.291-1.753-4.472l0,0l-0.072-0.071l-0.07-0.071h-0.019l-0.053-0.037l-0.037-0.035v-0.035l-0.07-0.036V273.656z
                                                                        M113.979,271.08c1.218,2.184,1.897,4.723,1.897,7.37c0,4.258-1.718,8.086-4.491,10.841l-0.303,0.287
                                                                        c-2.755,2.611-6.477,4.221-10.538,4.221c-2.666,0-5.205-0.715-7.387-1.896l-16.495,16.493l0,0l0.037,0.072l0.035,0.071
                                                                        l0.089,0.106l0,0l0.072,0.145l0.054,0.07l0.035,0.072l0.072,0.107v0.035l0.072,0.107l0.017,0.072l0.053,0.07l0.072,0.108v0.035
                                                                        l0.072,0.144l0.018,0.035l0.054,0.108l0.035,0.071l0.035,0.072l0.072,0.106v0.036l0.072,0.107l0.035,0.071l0.037,0.071
                                                                        l0.035,0.143h0.018l0.054,0.144l0.035,0.071l0.037,0.072l0.035,0.107l0.035,0.035l0.055,0.144l0.018,0.036l0.037,0.107
                                                                        l0.035,0.106l0.018,0.036l0.054,0.143l0.018,0.036l0.055,0.144l0.018,0.071l0.018,0.071l0.054,0.107l0.018,0.035l0.035,0.144
                                                                        l0.018,0.036l0.037,0.107l0.035,0.107l0.018,0.035l0.037,0.144l0.017,0.035l0.035,0.144l0.037,0.071l0.018,0.072l0.035,0.144l0,0
                                                                        l0.037,0.143l0.035,0.072l0.018,0.07l0.037,0.18l0,0l0.053,0.143h47.872l0.035-0.143l0,0l0.037-0.18l0.035-0.07v-0.072
                                                                        l0.054-0.143l0,0l0.053-0.144v-0.072l0.037-0.071l0.035-0.144v-0.035l0.072-0.144v-0.035l0.035-0.107l0.037-0.107l0.018-0.036
                                                                        l0.053-0.144v-0.035l0.037-0.107l0.035-0.071l0.037-0.071l0.035-0.144v-0.036l0.072-0.143l0.018-0.036l0.035-0.106l0.054-0.107
                                                                        v-0.036l0.072-0.144v-0.035l0.053-0.107l0.055-0.072v-0.071h0.018l0.125-0.286l0.037-0.071l0.035-0.071l0.055-0.107l0,0
                                                                        l0.072-0.143l0.018-0.072l0.053-0.071l0.054-0.108l0.018-0.035l0.054-0.144l0.035-0.035l0.035-0.108l0.072-0.07l0.017-0.072
                                                                        l0.072-0.107l0.018-0.035l0.072-0.107l0.037-0.072l0.035-0.07l0.072-0.108v-0.036l0.072-0.106l0.035-0.071l0.053-0.072
                                                                        l0.054-0.106l0.035-0.036l0.072-0.107l0.018-0.035l0.072-0.108l0.054-0.071l0.035-0.035l0.072-0.144h0.018l0.072-0.144
                                                                        l0.054-0.035l0.035-0.071l0.072-0.144h0.018l0.089-0.107l0.035-0.036l0.055-0.106l0.072-0.107h0.018l0.089-0.107l0.018-0.035
                                                                        l0.072-0.108l0.072-0.071l0.035-0.035l0.089-0.144h0.018l0.072-0.108l0.072-0.07l0.035-0.036l0.107-0.107l0.089-0.144l0.037-0.035
                                                                        l0.07-0.072l0.089-0.107l0,0l0.107-0.106l0.109-0.144l0.07-0.071l0.037-0.036l0.107-0.107l0,0l0.107-0.107l0.055-0.071
                                                                        l0.052-0.036l0.107-0.107c2.327-2.325,5.261-4.043,8.552-4.865v-47.871c-1.861-0.43-3.597-1.18-5.188-2.146L113.979,271.08z
                                                                        M100.544,263.138c2.594,0,5.027,0.644,7.156,1.753l21.056-21.037c-0.913-1.503-1.609-3.148-2.039-4.902H78.845
                                                                        c-0.823,3.256-2.521,6.19-4.829,8.516l-0.109,0.107l-0.052,0.035l-0.072,0.072l-0.089,0.107h-0.018l-0.125,0.107l-0.02,0.035
                                                                        l-0.089,0.072l-0.07,0.072l-0.037,0.035l-0.107,0.107h-0.035l-0.089,0.107l-0.055,0.035l-0.072,0.036l-0.107,0.106l-0.107,0.107
                                                                        l-0.072,0.036l-0.072,0.072l-0.09,0.071h-0.018l-0.107,0.106l-0.035,0.036l-0.107,0.072l-0.072,0.07h-0.037l-0.125,0.108h-0.018
                                                                        l-0.107,0.106l-0.072,0.037l-0.055,0.035l-0.125,0.107l-0.125,0.072l-0.054,0.035l-0.072,0.071l-0.107,0.071h-0.035l-0.107,0.072
                                                                        l-0.037,0.035l-0.107,0.071l-0.089,0.036l-0.035,0.036l-0.126,0.106h-0.035l-0.107,0.072l-0.072,0.036l-0.055,0.035l-0.125,0.071
                                                                        l0,0l-0.144,0.072l-0.07,0.035l-0.072,0.072l-0.107,0.035l-0.019,0.035l-0.125,0.072h-0.053l-0.089,0.071l-0.109,0.036
                                                                        l-0.035,0.036l-0.144,0.071h-0.018l-0.125,0.071l-0.072,0.036l-0.053,0.036l-0.144,0.071l0,0l-0.125,0.072h-0.072l-0.072,0.035
                                                                        l-0.144,0.071l0,0l-0.142,0.071h-0.055l-0.089,0.072l-0.107,0.035h-0.035l-0.144,0.072h-0.035l-0.107,0.07l-0.107,0.037h-0.037
                                                                        l-0.142,0.07h-0.019l-0.142,0.036l-0.072,0.036l-0.072,0.036l-0.142,0.036l0,0l-0.162,0.07h-0.035l-0.108,0.036l-0.107,0.036
                                                                        l-0.035,0.035l-0.144,0.036h-0.035l-0.125,0.036l-0.09,0.035l-0.072,0.037l-0.144,0.035l0,0l-0.142,0.035l-0.072,0.036h-0.072
                                                                        l-0.162,0.036h-0.018l-0.142,0.035v47.871l0.142,0.036l0.018,0.036l0.162,0.035h0.072l0.072,0.035l0.142,0.036l0,0l0.144,0.036
                                                                        l0.072,0.035l0.09,0.036l0.125,0.036h0.035l0.144,0.035h0.035l0.107,0.037l0.108,0.035l0.035,0.035l0.162,0.036l0,0l0.142,0.071
                                                                        l0.072,0.035h0.072l0.142,0.072h0.019l0.142,0.071h0.037l0.107,0.036l0.107,0.035l0.035,0.037l0.144,0.035l0.035,0.035
                                                                        l0.107,0.036l0.072,0.036l0.072,0.036h0.018l0.25,0.106l0.09,0.036l0.072,0.035l0.107,0.072h0.018l0.144,0.071l0.053,0.035
                                                                        l0.072,0.037l0.125,0.071h0.018l0.144,0.071h0.035l0.109,0.072l0.089,0.035l0.053,0.035l0.125,0.072h0.019l0.107,0.07l0.072,0.037
                                                                        l0.07,0.035l0.126,0.072h0.018l0.035,0.035l16.602-16.602c-1.146-2.146-1.79-4.578-1.79-7.155c0-4.222,1.735-8.05,4.51-10.84
                                                                        l0.321-0.287C92.799,264.748,96.483,263.138,100.544,263.138z
                                                                        M190.615,272.619c-1.181-1.181-2.827-1.932-4.615-1.932
                                                                        c-1.574,0-3.041,0.572-4.15,1.502l0,0l-0.072,0.072h-0.035l-0.019,0.035l-0.339,0.322c-1.181,1.18-1.914,2.79-1.914,4.615
                                                                        c0,1.789,0.733,3.435,1.914,4.615c1.179,1.181,2.808,1.896,4.615,1.896c1.718,0,3.292-0.645,4.473-1.753l0.142-0.144
                                                                        c1.181-1.181,1.915-2.826,1.915-4.615S191.796,273.799,190.615,272.619z
                                                                        M194.122,290.223c-2.343,1.465-5.152,2.324-8.121,2.324
                                                                        c-4.222,0-8.068-1.717-10.841-4.472c-2.755-2.791-4.491-6.619-4.491-10.841c0-2.971,0.858-5.76,2.345-8.122l-18.981-18.962
                                                                        c-1.574,0.967-3.31,1.682-5.134,2.146v47.907c3.274,0.786,6.208,2.504,8.516,4.794c0.644,0.68,1.253,1.359,1.788,2.111l0,0
                                                                        l0.251,0.357l0.035,0.036l0.072,0.106l0.144,0.215l0.035,0.035l0.072,0.144l0.09,0.144l0.035,0.035l0.054,0.072l0.072,0.107v0.035
                                                                        l0.09,0.144h0.018l0.072,0.106l0.035,0.072l0.035,0.072l0.072,0.143l0,0l0.072,0.143l0.035,0.037l0.037,0.07l0.072,0.107
                                                                        l0.018,0.035l0.07,0.144l0,0l0.054,0.144l0.035,0.071l0.037,0.072l0.072,0.106v0.037l0.07,0.106l0.037,0.071l0.035,0.072
                                                                        l0.037,0.106l0.035,0.036l0.055,0.143h0.018l0.052,0.144l0.02,0.071l0.035,0.072l0.053,0.107l0.019,0.035l0.053,0.144l0.018,0.036
                                                                        l0.037,0.107l0.035,0.106l0.037,0.036l0.035,0.143l0,0l0.072,0.18l0.018,0.035l0.035,0.107l0.037,0.107l0.018,0.035l0.035,0.144
                                                                        h0.019l0.053,0.144l0.018,0.071l0.02,0.071l0.035,0.144l0,0l0.072,0.179v0.035l0.035,0.108l0.035,0.106v0.037l0.054,0.143l0,0
                                                                        l0.035,0.143l0.02,0.072l0.035,0.107l0.018,0.106l0.018,0.036h47.872l0.037-0.143l0,0l0.035-0.144l0.035-0.106v-0.037l0.054-0.143
                                                                        v-0.035l0.035-0.107l0.02-0.108l0.035-0.036l0.035-0.143v-0.036l0.037-0.106l0.035-0.107l0.018-0.036l0.054-0.144v-0.035
                                                                        l0.035-0.107l0.037-0.107l0.018-0.035l0.052-0.144l0,0l0.055-0.144l0.018-0.071l0.037-0.071l0.053-0.144h0.018l0.037-0.144
                                                                        l0.035-0.07l0.018-0.072l0.055-0.107v-0.035l0.072-0.107l0.035-0.072l0.018-0.071l0.054-0.144l0,0l0.072-0.143l0.035-0.071
                                                                        l0.035-0.036l0.055-0.143l0,0l0.072-0.143l0.018-0.072l0.035-0.036l0.072-0.144l0,0l0.072-0.143l0.035-0.071l0.037-0.036
                                                                        l0.07-0.143l0,0l0.072-0.143l0.037-0.072l0.035-0.036l0.072-0.144l0.072-0.143l0.035-0.036l0.035-0.071l0.072-0.143l0.072-0.072
                                                                        L194.122,290.223z
                                                                        M240.705,53.3c-2.415,0-4.383-1.968-4.383-4.401c0-2.432,1.969-4.4,4.383-4.4h39.786c2.432,0,4.4,1.969,4.4,4.4
                                                                        c0,2.434-1.969,4.401-4.4,4.401H240.705z
                                                                        M162.404,128.111c1.664,0,3.381-1.109,3.954-2.504c1.467-2.898-0.769-6.297-3.954-6.297
                                                                        h-8.783c-1.683,0-3.399,1.072-3.973,2.469c-1.467,2.934,0.788,6.332,3.973,6.332H162.404z
                                                                        M197.557,128.111
                                                                        c1.681,0,3.399-1.109,3.971-2.504c1.449-2.898-0.788-6.297-3.971-6.297h-8.784c-1.664,0-3.381,1.072-3.954,2.469
                                                                        c-1.467,2.934,0.769,6.332,3.954,6.332H197.557z
                                                                        M232.726,128.111c1.663,0,3.399-1.109,3.953-2.504
                                                                        c1.467-2.898-0.769-6.297-3.953-6.297h-8.783c-1.664,0-3.399,1.072-3.954,2.469c-1.467,2.934,0.769,6.332,3.954,6.332H232.726z
                                                                        M251.474,135.697c0,1.681,1.091,3.398,2.486,3.971c2.934,1.467,6.315-0.787,6.315-3.971v-8.766c0-1.683-1.074-3.399-2.469-3.973
                                                                        c-2.934-1.466-6.333,0.788-6.333,3.973V135.697z
                                                                        M251.474,170.867c0,1.68,1.091,3.398,2.486,3.971
                                                                        c2.934,1.467,6.315-0.787,6.315-3.971v-8.803c0-1.646-1.074-3.363-2.469-3.935c-2.934-1.468-6.333,0.786-6.333,3.935V170.867z
                                                                        M251.474,206.036c0,1.683,1.091,3.399,2.486,3.972c2.934,1.43,6.315-0.787,6.315-3.972v-8.802c0-1.645-1.074-3.398-2.469-3.936
                                                                        c-2.934-1.467-6.333,0.752-6.333,3.936V206.036z
                                                                        M251.474,241.205c0,1.646,1.091,3.399,2.486,3.937
                                                                        c2.934,1.467,6.315-0.752,6.315-3.937v-8.801c0-1.646-1.074-3.398-2.469-3.936c-2.934-1.467-6.333,0.751-6.333,3.936V241.205z
                                                                        M76.358,469.898c-2.415,0-4.383-1.968-4.383-4.4c0-2.397,1.969-4.401,4.383-4.401h264.595c2.435,0,4.401,2.004,4.401,4.401
                                                                        c0,2.433-1.967,4.4-4.401,4.4H76.358z
                                                                        M456.016,477.197c2.435,0,4.401,1.968,4.401,4.4s-1.967,4.401-4.401,4.401H222.529
                                                                        c-2.432,0-4.401-1.969-4.401-4.401s1.969-4.4,4.401-4.4H456.016z
                                                                        M203.781,477.197c2.434,0,4.401,1.968,4.401,4.4
                                                                        s-1.967,4.401-4.401,4.401h-22.897c-2.415,0-4.401-1.969-4.401-4.401s1.987-4.4,4.401-4.4H203.781z
                                                                        M359.273,469.898
                                                                        c-2.416,0-4.384-1.968-4.384-4.4c0-2.397,1.968-4.401,4.384-4.401h22.897c2.433,0,4.401,2.004,4.401,4.401
                                                                        c0,2.433-1.969,4.4-4.401,4.4H359.273z
                                                                        M186,261.922c4.222,0,8.068,1.717,10.841,4.472c2.755,2.755,4.471,6.618,4.471,10.841
                                                                        c0,2.29-0.483,4.437-1.377,6.369l18.605,18.604h0.035v-0.035l0.537-0.287l0.035-0.035l0.09-0.035l0.288-0.144h0.018l0.125-0.071
                                                                        l0.269-0.144l0,0l0.144-0.072l0.052-0.035l0.09-0.036l0.125-0.035l0.019-0.036l0.142-0.035l0.037-0.036l0.107-0.036l0.107-0.036
                                                                        l0.035-0.035l0.144-0.035l0.018-0.037l0.125-0.035l0.089-0.036l0.055-0.035l0.161-0.036l0,0l0.142-0.072h0.072l0.072-0.035
                                                                        l0.125-0.035l0.018-0.036l0.144-0.036h0.054l0.09-0.07l0.125-0.037h0.035l0.161-0.035l0,0l0.144-0.072h0.089l0.053-0.035
                                                                        l0.144-0.036h0.018l0.142-0.036l0.072-0.035h0.089l0.144-0.035v-0.036l0.162-0.036v-47.871l-0.162-0.035l0,0l-0.144-0.036h-0.089
                                                                        l-0.072-0.036l-0.142-0.035h-0.018l-0.144-0.035l-0.053-0.037l-0.089-0.035l-0.144-0.036l0,0l-0.161-0.036l-0.035-0.035
                                                                        l-0.125-0.036l-0.09-0.036h-0.054l-0.144-0.035l-0.018-0.035l-0.125-0.036l-0.072-0.036l-0.072-0.036l-0.142-0.036l-0.161-0.07
                                                                        h-0.055l-0.089-0.037l-0.125-0.07h-0.018l-0.144-0.072h-0.035l-0.107-0.035l-0.107-0.037l-0.037-0.035l-0.142-0.071h-0.019
                                                                        l-0.125-0.071l-0.09-0.035h-0.052l-0.144-0.072l-0.125-0.071l-0.072-0.036l-0.072-0.036l-0.125-0.071h-0.018l-0.144-0.071
                                                                        l-0.035-0.036l-0.108-0.036l-0.09-0.071h-0.035l-0.125-0.072l-0.037-0.035l-0.107-0.035l-0.072-0.072l-0.07-0.035l-0.126-0.072
                                                                        l-0.125-0.071l-0.072-0.035l-0.072-0.036l-0.107-0.072h-0.018l-0.125-0.106h-0.037l-0.107-0.072l-0.089-0.071h-0.035l-0.126-0.107
                                                                        h-0.018l-0.107-0.071l-0.072-0.071l-0.053-0.035l-0.144-0.072l-0.107-0.107l-0.055-0.035l-0.072-0.037l-0.107-0.071l-0.018-0.035
                                                                        l-0.125-0.071l-0.037-0.037l-0.089-0.07l-0.07-0.072l-0.037-0.036l-0.125-0.071l-0.018-0.035l-0.107-0.071l-0.072-0.072
                                                                        l-0.037-0.036l-0.125-0.07l-0.125-0.108l-0.037-0.071l-0.072-0.035l-0.107-0.072v-0.035l-0.125-0.107l-0.035-0.035l-0.089-0.072
                                                                        l-0.072-0.072l-0.037-0.035l-0.107-0.107h-0.018l-0.089-0.107l-0.072-0.036l-0.035-0.036l-0.107-0.107
                                                                        c-1.056-1.072-1.986-2.289-2.755-3.577l-0.072-0.107l-0.035-0.071l-0.072-0.107l-0.037-0.071l-0.07-0.108l-0.037-0.07
                                                                        l-0.072-0.143l-0.035-0.037l-0.072-0.143l-0.035-0.072l-0.072-0.143l-0.018-0.036l-0.089-0.143v-0.036l-0.089-0.18l-0.018-0.034
                                                                        l-0.072-0.179l0,0l-0.107-0.18v-0.035l-0.072-0.18l0,0l-0.107-0.215l0,0l-0.072-0.178l0,0l-0.072-0.215l0,0l-0.089-0.18
                                                                        l-0.018-0.035l-0.053-0.18l-0.02-0.035l-0.07-0.179v-0.036l-0.055-0.18l-0.018-0.035l-0.072-0.143v-0.072l-0.054-0.143
                                                                        l-0.018-0.072l-0.035-0.143l-0.035-0.035l-0.037-0.18l-0.018-0.071l-0.035-0.144l-0.02-0.071l-0.035-0.144l-0.035-0.071
                                                                        l-0.037-0.144l0,0h-47.889c-0.447,1.754-1.126,3.399-2.021,4.902l19.429,19.427C181.564,262.387,183.728,261.922,186,261.922z"
                  />
                </svg>
              </div>
              <h3 className="services__title">{t('Services.MainServices.ServiceTwoTitle')}</h3>
              <p className="services__text">
              {t('Services.MainServices.ServiceTwoText')}
              </p>
            </AnimatedElement>
            <AnimatedElement tag="li" delay={500} className="services__card">
              <div className="icon">
                <svg
                  version="1.1"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#fff"
                >
                  <path
                    d="M128.971,380.536v49.381c0,4.025,1.621,7.721,4.204,10.394l0.181,0.181
                                                                        c2.734,2.704,6.458,4.415,10.604,4.415h211.495c4.115,0,7.87-1.711,10.574-4.415c2.732-2.704,4.414-6.458,4.414-10.574v-49.381
                                                                        H128.971z
                                                                        M128.971,105.752v34.752h96.09c2.042,0,3.696,1.653,3.696,3.695v77.857c0,2.042-1.653,3.695-3.696,3.695h-96.09v147.395
                                                                        h241.472v-11.506h-65.151c-2.043,0-3.694-1.65-3.694-3.693v-78.579c0-2.042,1.651-3.694,3.694-3.694h65.151V105.752H128.971z
                                                                        M355.455,48.289H143.96h-0.21l0,0h-0.181l0,0h-0.181l-0.03,0.029h-0.149h-0.03h-0.181l0,0l-0.181,0.029h-0.03h-0.151h-0.03
                                                                        l-0.149,0.03h-0.03h-0.151h-0.06l-0.121,0.032h-0.06l-0.12,0.029h-0.062l-0.149,0.029h-0.03l-0.121,0.03h-0.06l-0.12,0.03h-0.062
                                                                        l-0.149,0.029h-0.062l-0.089,0.029h-0.089l-0.091,0.03l-0.089,0.032h-0.09l-0.091,0.029l-0.089,0.029h-0.089l-0.091,0.03
                                                                        l-0.089,0.03l-0.09,0.029l-0.091,0.03h-0.09l-0.06,0.03l-0.091,0.029l-0.089,0.031l-0.089,0.03l-0.091,0.03h-0.09l-0.091,0.059
                                                                        h-0.09l-0.089,0.061h-0.06l-0.272,0.121h-0.089l-0.09,0.059h-0.06l-0.121,0.061l-0.151,0.06l-0.06,0.031l-0.119,0.06l-0.181,0.061
                                                                        h-0.03l-0.121,0.059l-0.179,0.092h-0.03c-4.117,1.951-7,5.525-8.141,9.852l-0.061,0.18l0,0l-0.03,0.151v0.03l-0.06,0.179
                                                                        l-0.03,0.181l-0.03,0.181l-0.03,0.181l-0.03,0.18l-0.03,0.18l0,0l-0.032,0.182l-0.03,0.179l-0.03,0.21l-0.03,0.182v0.18
                                                                        l-0.03,0.18v0.211v0.18l-0.03,0.182v0.209v0.18v0.211v35.084h241.472V63.277v-0.211v-0.18v-0.209l-0.029-0.182v-0.18v-0.211
                                                                        l-0.029-0.18l-0.032-0.18v-0.182l-0.03-0.21l-0.029-0.179l-0.029-0.182l0,0l-0.03-0.18l-0.03-0.18l-0.029-0.181l-0.029-0.181
                                                                        l-0.03-0.181l-0.062-0.179v-0.03l-0.029-0.151l0,0l-0.061-0.18l-0.029-0.18l-0.061-0.181l0,0l-0.061-0.181l-0.061-0.18l0,0
                                                                        l-0.029-0.18l0,0c-1.412-3.936-3.755-6.76-7.449-8.711l0,0l-0.15-0.092h-0.029l-0.301-0.148l0,0l-0.181-0.092l0,0l-0.149-0.059
                                                                        l0,0l-0.51-0.211l0,0l-0.362-0.119l0,0l-0.329-0.121v-0.03l-0.182-0.03l-0.359-0.119l0,0l-0.15-0.061h-0.03l-0.151-0.03
                                                                        l-0.179-0.06l-0.181-0.061l0,0l-0.359-0.059l-0.182-0.063l-0.21-0.029l-0.181-0.029l-0.18-0.03l-0.18-0.03l0,0l-0.182-0.029
                                                                        l-0.179-0.029l-0.21-0.032h-0.182l-0.18-0.03h-0.18l-0.211-0.029h-0.18l0,0h-0.182l-0.209-0.029h-0.18H355.455z
                                                                        M221.397,218.362
                                                                        v-70.469c-48.031,0-96.031,0-144.032,0v70.469C125.367,218.362,173.366,218.362,221.397,218.362z
                                                                        M308.987,283.063v71.188
                                                                        c24.78,0,49.591,0,74.403,0l-10.213-29.977h-0.031v-0.061v-0.03l-0.03-0.03l0,0v-0.06l0,0l-0.03-0.03v-0.059l0,0v-0.032
                                                                        l-0.029-0.03v-0.029v-0.029v-0.03v-0.03l-0.029-0.029l0,0v-0.029v-0.063l0,0v-0.029v-0.029l-0.03-0.03l0,0v-0.03v-0.06l0,0v-0.03
                                                                        v-0.031v-0.029l0,0v-0.03v-0.03l-0.03-0.029l0,0v-0.029v-0.03v-0.03v-0.029v-0.031v-0.03l0,0v-0.03v-0.059v-0.03v-0.03v-0.029l0,0
                                                                        v-0.062v-0.03l0,0v-0.029v-0.061l0,0v-0.029l0.03-0.029v-0.03l0,0v-0.062v-0.029l0,0v-0.03v-0.06l0,0l0.03-0.029v-0.03v-0.032l0,0
                                                                        v-0.059l0,0v-0.03l0.029-0.03v-0.029v-0.03v-0.03l0.029-0.029l0,0v-0.031v-0.061l0,0l0.03-0.029l0,0v-0.029v-0.03l0,0l0.03-0.06
                                                                        v-0.031l0,0l0.031-0.03v-0.03v-0.029l0.029-0.06l0,0l0.03-0.09l0,0l0.03-0.091h0.029l0.03-0.09l0.03-0.06v-0.032l0.029-0.059
                                                                        l0.031-0.03l0.03-0.03v-0.029l0.03-0.06l0.029-0.03l0.029-0.031v-0.06l0.03-0.03l0.03-0.029l0.029-0.03l0.029-0.06l0.032-0.062
                                                                        l0.03-0.03l0.029-0.029l0.029-0.029l0.03-0.061l0.03-0.029l0.029-0.029l0.03-0.032l0.031-0.03l0.03-0.029l0.029-0.029l0.03-0.03
                                                                        l0.03-0.06h0.029l0.029-0.062l0.03-0.03l0.03-0.029l0.091-0.061h0.03l0.029-0.029l0.029-0.029l0.03-0.03l0.211-0.181h0.06
                                                                        l0.029-0.029l0.032-0.03l0.03-0.03l0.059-0.031h0.03l0.3-0.148l0.03-0.03l0.302-0.121l0.09-0.03h0.029l0.15-0.029v-0.03h0.03h0.06
                                                                        l0.061-0.03h0.029l0,0h0.062l0.06-0.029h0.029h0.03h0.03h0.029l0,0h0.06l0.032-0.029l0,0h0.029h0.029h0.03h0.03h0.029h0.03l0,0
                                                                        h0.062h0.029l0,0h0.029h0.182h0.06l0,0h0.03h0.029h0.029l0,0h0.061l0,0h0.029l0,0h0.031h0.03h0.03h0.029l0.029,0.029h0.03l0,0
                                                                        h0.03h0.061l0,0h0.03h0.03l0.029,0.029h0.061h0.029h0.029l0.03,0.03h0.032h0.059l0.03,0.03h0.03h0.029l0.029,0.029h0.03h0.062
                                                                        l0.029,0.03l0,0h0.03l0.03,0.03h0.029l17.783,6.248v-42.804C366.778,283.063,337.883,283.063,308.987,283.063z
                                                                        M256.812,405.466
                                                                        c-1.802-1.802-4.325-2.913-7.119-2.913c-2.764,0-5.287,1.111-7.089,2.913c-1.802,1.834-2.945,4.357-2.945,7.119
                                                                        c0,2.704,1.053,5.138,2.764,6.94l0.181,0.148c1.802,1.832,4.325,2.945,7.089,2.945c2.794,0,5.317-1.113,7.119-2.945
                                                                        c1.802-1.802,2.942-4.325,2.942-7.089C259.754,409.823,258.613,407.3,256.812,405.466z
                                                                        M249.692,395.164
                                                                        c4.807,0,9.161,1.953,12.314,5.106c3.186,3.123,5.137,7.51,5.137,12.314c0,4.807-1.951,9.191-5.106,12.316
                                                                        c-3.153,3.153-7.508,5.106-12.345,5.106c-4.806,0-9.161-1.953-12.316-5.106l-0.24-0.24c-3.002-3.155-4.866-7.389-4.866-12.076
                                                                        c0-4.805,1.953-9.191,5.106-12.314C240.531,397.117,244.886,395.164,249.692,395.164z
                                                                        M349.897,147.533h-59.654v71.189h59.654
                                                                        V147.533z
                                                                        M204.155,249.48c1.381,0,2.853-0.901,3.304-2.072c1.232-2.463-0.631-5.287-3.304-5.287h-7.39
                                                                        c-1.381,0-2.853,0.902-3.304,2.072c-1.231,2.465,0.63,5.287,3.304,5.287H204.155z
                                                                        M233.682,249.48
                                                                        c1.381,0,2.854-0.901,3.304-2.072c1.232-2.463-0.632-5.287-3.304-5.287h-7.389c-1.383,0-2.853,0.902-3.304,2.072
                                                                        c-1.232,2.465,0.63,5.287,3.304,5.287H233.682z
                                                                        M263.18,249.48c1.41,0,2.853-0.901,3.334-2.072
                                                                        c1.231-2.463-0.662-5.287-3.334-5.287h-7.359c-1.413,0-2.854,0.902-3.334,2.072c-1.232,2.465,0.66,5.287,3.334,5.287H263.18z
                                                                        M280.241,262.969c1.11,5.557,8.14,3.152,7.359-0.812c0-2.434-1.532-6.219-2.645-8.351c-2.702-4.506-8.771-0.33-6.487,3.515
                                                                        C279.22,258.583,280.241,261.496,280.241,262.969z
                                                                        M280.33,292.074c0,1.383,0.902,2.854,2.072,3.305
                                                                        c2.464,1.231,5.317-0.631,5.317-3.305v-7.389c0-1.382-0.9-2.854-2.072-3.305c-2.464-1.232-5.317,0.631-5.317,3.305V292.074z
                                                                        M280.33,321.602c0,1.383,0.902,2.854,2.072,3.304c2.464,1.231,5.317-0.63,5.317-3.304v-7.39c0-1.381-0.9-2.853-2.072-3.304
                                                                        c-2.464-1.232-5.317,0.631-5.317,3.304V321.602z
                                                                        M274.292,345.781c-1.562,1.173-1.951,3.605-0.72,5.137
                                                                        c1.261,1.683,3.575,1.953,5.196,0.751c2.432-1.892,4.266-3.904,5.857-6.579c1.532-2.582-0.781-6.065-3.785-5.465
                                                                        c-1.141,0.21-1.982,0.721-2.612,1.802C277.478,342.929,275.646,344.73,274.292,345.781z
                                                                        M247.829,348.816
                                                                        c-1.381,0-2.854,0.901-3.304,2.072c-1.23,2.463,0.632,5.287,3.304,5.287h7.389c1.383,0,2.855-0.902,3.304-2.073
                                                                        c1.231-2.464-0.63-5.286-3.304-5.286H247.829z
                                                                        M218.304,348.816c-1.383,0-2.855,0.901-3.304,2.072
                                                                        c-1.232,2.463,0.63,5.287,3.304,5.287h7.389c1.381,0,2.853-0.902,3.304-2.073c1.23-2.464-0.632-5.286-3.304-5.286H218.304z
                                                                        M188.776,348.816c-1.383,0-2.855,0.901-3.304,2.072c-1.232,2.463,0.63,5.287,3.304,5.287h7.389c1.381,0,2.854-0.902,3.334-2.073
                                                                        c1.2-2.464-0.661-5.286-3.334-5.286H188.776z
                                                                        M161.021,347.435c-4.866-1.923-7.21,5.077-3.004,6.758
                                                                        c3.004,1.202,5.196,1.862,8.5,1.983c5.197,0,4.806-7.39,0.27-7.39C164.505,348.727,163.064,348.275,161.021,347.435z
                                                                        M152.19,325.687c0-1.411-0.902-2.854-2.072-3.334c-2.464-1.231-5.317,0.661-5.317,3.334v7.39c0,1.383,0.9,2.822,2.072,3.304
                                                                        c2.463,1.231,5.317-0.63,5.317-3.304V325.687z
                                                                        M152.19,296.159c0-1.381-0.902-2.853-2.072-3.304
                                                                        c-2.464-1.232-5.317,0.631-5.317,3.304v7.39c0,1.383,0.9,2.854,2.072,3.304c2.463,1.231,5.317-0.63,5.317-3.304V296.159z
                                                                        M152.19,266.632c0-1.381-0.902-2.853-2.072-3.304c-2.464-1.23-5.317,0.631-5.317,3.304v7.39c0,1.383,0.9,2.855,2.072,3.305
                                                                        c2.463,1.23,5.317-0.631,5.317-3.305V266.632z
                                                                        M167.239,242.121c-0.33,0-0.691,0.061-1.051,0.151
                                                                        c-0.421-0.091-0.87-0.121-1.351-0.03c-2.404,0-6.189,1.501-8.351,2.613c-4.506,2.734-0.3,8.801,3.545,6.487
                                                                        c1.231-0.722,4.174-1.772,5.617-1.772l0.721-0.179l0.871,0.089h7.389c1.381,0,2.853-0.901,3.304-2.072
                                                                        c1.232-2.463-0.63-5.287-3.304-5.287H167.239z
                                                                        M93.166,168.799c-2.042,0-3.694-1.65-3.694-3.693s1.651-3.695,3.694-3.695h43.254
                                                                        c2.013,0,3.695,1.652,3.695,3.695s-1.683,3.693-3.695,3.693H93.166z
                                                                        M93.166,186.822c-2.042,0-3.694-1.65-3.694-3.693
                                                                        c0-2.045,1.651-3.695,3.694-3.695H184c2.013,0,3.664,1.65,3.664,3.695c0,2.043-1.651,3.693-3.664,3.693H93.166z
                                                                        M93.166,201.961
                                                                        c-2.042,0-3.694-1.651-3.694-3.693c0-2.043,1.651-3.696,3.694-3.696H184c2.013,0,3.664,1.653,3.664,3.696
                                                                        c0,2.042-1.651,3.693-3.664,3.693H93.166z
                                                                        M230.889,74.09c-2.013,0-3.695-1.65-3.695-3.693s1.683-3.695,3.695-3.695h37.638
                                                                        c2.042,0,3.693,1.652,3.693,3.695s-1.651,3.693-3.693,3.693H230.889z
                                                                        M118.427,470.107c-2.042,0-3.694-1.651-3.694-3.693
                                                                        c0-2.013,1.651-3.695,3.694-3.695h234.655c2.043,0,3.694,1.683,3.694,3.695c0,2.042-1.651,3.693-3.694,3.693H118.427z
                                                                        M244.916,485.999c-2.042,0-3.694-1.653-3.694-3.696c0-2.042,1.651-3.693,3.694-3.693h180.646c2.042,0,3.695,1.651,3.695,3.693
                                                                        c0,2.043-1.653,3.696-3.695,3.696H244.916z
                                                                        M444.036,485.999c-2.042,0-3.695-1.653-3.695-3.696c0-2.042,1.653-3.693,3.695-3.693
                                                                        h22.588c2.042,0,3.695,1.651,3.695,3.693c0,2.043-1.653,3.696-3.695,3.696H444.036z
                                                                        M81.75,485.518
                                                                        c-2.011,0-3.693-1.652-3.693-3.695s1.683-3.693,3.693-3.693h85.609c2.042,0,3.693,1.65,3.693,3.693s-1.651,3.695-3.693,3.695
                                                                        H81.75z
                                                                        M294.088,33.391c-2.043,0-3.694-1.652-3.694-3.695s1.651-3.693,3.694-3.693h78.669c6.157,0,11.745,2.553,15.8,6.576
                                                                        l0.181,0.211c3.964,4.055,6.397,9.582,6.397,15.589v56.652c0,2.042-1.652,3.693-3.695,3.693s-3.693-1.651-3.693-3.693V48.378
                                                                        c0-4.023-1.623-7.689-4.236-10.392l-0.181-0.181c-2.732-2.704-6.458-4.415-10.572-4.415H294.088z
                                                                        M45.376,248.1
                                                                        c-2.042,0-3.695-1.652-3.695-3.695c0-2.042,1.653-3.693,3.695-3.693h61.997c2.042,0,3.695,1.651,3.695,3.693
                                                                        c0,2.043-1.653,3.695-3.695,3.695H45.376z
                                                                        M45.376,266.121c-2.042,0-3.695-1.65-3.695-3.693s1.653-3.695,3.695-3.695h61.997
                                                                        c2.042,0,3.695,1.652,3.695,3.695s-1.653,3.693-3.695,3.693H45.376z
                                                                        M45.376,284.145c-2.042,0-3.695-1.651-3.695-3.693
                                                                        c0-2.043,1.653-3.695,3.695-3.695h61.997c2.042,0,3.695,1.652,3.695,3.695c0,2.042-1.653,3.693-3.695,3.693H45.376z
                                                                        M45.376,302.168c-2.042,0-3.695-1.653-3.695-3.695c0-2.043,1.653-3.694,3.695-3.694h61.997c2.042,0,3.695,1.651,3.695,3.694
                                                                        c0,2.042-1.653,3.695-3.695,3.695H45.376z
                                                                        M45.376,320.19c-2.042,0-3.695-1.653-3.695-3.695s1.653-3.694,3.695-3.694h61.997
                                                                        c2.042,0,3.695,1.652,3.695,3.694s-1.653,3.695-3.695,3.695H45.376z
                                                                        M45.376,338.212c-2.042,0-3.695-1.651-3.695-3.694
                                                                        c0-2.042,1.653-3.695,3.695-3.695h61.997c2.042,0,3.695,1.653,3.695,3.695c0,2.043-1.653,3.694-3.695,3.694H45.376z
                                                                        M417.514,360.411l9.762,9.762l-3.424,3.453l-9.823-9.821l-14.719,14.328c-5.587-16.371-11.145-32.711-16.731-49.051
                                                                        c16.432,5.796,32.831,11.563,49.263,17.361L417.514,360.411z
                                                                        M286.548,140.145h67.046c2.012,0,3.693,1.65,3.693,3.693v78.58
                                                                        c0,2.042-1.682,3.693-3.693,3.693h-67.046c-2.043,0-3.694-1.651-3.694-3.693v-78.58
                                                                        C282.854,141.795,284.505,140.145,286.548,140.145z"
                  />
                </svg>
              </div>
              <h3 className="services__title">{t('Services.MainServices.ServiceThreeTitle')}</h3>
              <p className="services__text">
              {t('Services.MainServices.ServiceThreeText')}
              </p>
            </AnimatedElement>
            <AnimatedElement tag="li" delay={600} className="services__card">
              <div className="icon">
                <svg
                  version="1.1"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#fff"
                >
                  <path
                    d="M366.16,156.877v-44.65h5.921v44.65H366.16z
                                                                        M329.471,133.443h11.555
                                                                        c3.829,0,6.512-0.717,8.123-2.147c1.609-1.431,2.414-3.435,2.414-6.011c0-1.859-0.482-3.471-1.412-4.794
                                                                        c-0.949-1.324-2.201-2.218-3.722-2.647c-1.003-0.25-2.862-0.394-5.528-0.394h-11.43V133.443z
                                                                        M323.566,156.877v-44.65h16.852
                                                                        c2.97,0,5.223,0.143,6.799,0.429c2.181,0.358,4.043,1.073,5.51,2.075c1.502,1.037,2.683,2.469,3.596,4.294
                                                                        c0.912,1.86,1.34,3.864,1.34,6.081c0,3.793-1.198,7.014-3.613,9.625c-2.414,2.611-6.797,3.936-13.113,3.936h-11.465v18.211
                                                                        H323.566z
                                                                        M289.899,138.559h15.224l-4.669-12.379c-1.396-3.793-2.47-6.905-3.186-9.338c-0.626,2.897-1.43,5.76-2.469,8.586
                                                                        L289.899,138.559z
                                                                        M277.054,156.877l17.121-44.65h6.493l18.193,44.65h-6.727l-5.169-13.523h-18.803l-4.848,13.523H277.054z
                                                                        M387.788,261.992c-2.755,1.719-5.135,3.9-7.049,6.477c-3.076,4.15-4.956,9.23-5.045,14.776c-0.09,5.724,1.7,11.021,4.776,15.313
                                                                        c1.951,2.754,4.455,5.079,7.317,6.833V261.992z
                                                                        M387.788,377.305H133.442v51.735c0,4.222,1.718,8.05,4.508,10.841
                                                                        c2.772,2.79,6.619,4.508,10.823,4.508h223.666c4.204,0,8.051-1.718,10.822-4.508c2.773-2.791,4.526-6.619,4.526-10.841V377.305z
                                                                        M267.789,403.53c-1.843-1.824-4.384-2.934-7.192-2.934c-2.79,0-5.331,1.109-7.154,2.934c-1.825,1.824-2.971,4.365-2.971,7.191
                                                                        c0,2.791,1.146,5.331,2.971,7.155c1.823,1.825,4.364,2.971,7.154,2.971c2.72,0,5.151-1.038,6.977-2.755l0.196-0.216
                                                                        c1.825-1.824,2.971-4.364,2.971-7.155C270.74,407.896,269.612,405.354,267.789,403.53z
                                                                        M260.597,391.795
                                                                        c5.224,0,9.947,2.111,13.382,5.546c3.434,3.435,5.545,8.158,5.545,13.381c0,5.224-2.111,9.946-5.545,13.381l-0.304,0.287
                                                                        c-3.399,3.255-7.997,5.259-13.078,5.259c-5.222,0-9.946-2.111-13.38-5.546c-3.417-3.435-5.547-8.157-5.547-13.381
                                                                        c0-5.223,2.13-9.946,5.547-13.381C250.651,393.906,255.375,391.795,260.597,391.795z
                                                                        M223.854,142.529
                                                                        c-1.002,0.466-1.897,1.109-2.685,1.897c-0.679,0.679-1.251,1.431-1.698,2.289l-0.018,0.036c-0.233,0.429-0.43,0.859-0.572,1.324
                                                                        l-0.019,0.035l-0.125,0.43l0,0l-0.035,0.107l0,0c-0.126,0.465-0.216,0.965-0.288,1.502v0.037v0.07v0.036v0.071v0.037l-0.035,0.213
                                                                        v0.037v0.5v0.107l0,0v0.107v0.035v0.108l0,0v0.106l0,0l0.018,0.107v0.036l0.018,0.106l0,0v0.108v0.036v0.071v0.036l0.02,0.106
                                                                        h0.018v0.108v0.035l0.018,0.072v0.035l0.018,0.071v0.036l0.017,0.107v0.035l0.02,0.072v0.035l0.018,0.072l0.018,0.035v0.071v0.036
                                                                        l0.035,0.107v0.035l0.019,0.072l0.018,0.036v0.071l0.018,0.035l0.018,0.072v0.035l0.037,0.072v0.035l0.035,0.072v0.071
                                                                        l0.018,0.071l0.018,0.035l0.02,0.072l0.018,0.035l0.018,0.072l0.018,0.036l0.018,0.071l0.019,0.035l0.018,0.072l0.018,0.035
                                                                        l0.018,0.072l0.018,0.035l0.037,0.072v0.035l0.035,0.072v0.035l0.035,0.072l0.02,0.035l0.018,0.071l0.035,0.036v0.072l0.037,0.035
                                                                        l0.018,0.071l0.018,0.036l0.035,0.071l0.037,0.036v0.035l0.035,0.071l0.018,0.036l0.037,0.072l0.018,0.035l0.035,0.072l0.02,0.035
                                                                        l0.018,0.071l0.035,0.036l0.037,0.072v0.035l0.035,0.035l0.035,0.036l0.037,0.071l0.018,0.035l0.035,0.072l0.018,0.036
                                                                        l0.037,0.071h0.035l0.037,0.072v0.035l0.053,0.071l0.018,0.036l0.037,0.072h0.018l0.125,0.178v0.036l0.054,0.071h0.018
                                                                        l0.055,0.072l0.018,0.035l0.035,0.072h0.037l0.035,0.07l0.018,0.037l0.054,0.071h0.018l0.055,0.071l0.018,0.036l0.052,0.071h0.02
                                                                        l0.053,0.071h0.018l0.054,0.107h0.018l0.054,0.071h0.018l0.072,0.072l0,0l0.089,0.107l0,0l0.07,0.07l0.072,0.072l0,0l0.072,0.071
                                                                        l0.162,0.179h0.018c1.681,1.61,4.008,2.612,6.564,2.612h0.591l0,0c0.43-0.036,0.841-0.071,1.251-0.179l0,0l0.216-0.036h0.018
                                                                        l0.089-0.035h0.035l0.072-0.036h0.037h0.07l0.448-0.143h0.018l0.196-0.072h0.02l0.07-0.036h0.037l0.072-0.035l0.107-0.036h0.035
                                                                        l0.072-0.035h0.035l0.072-0.037h0.037l0.07-0.035l0.037-0.035h0.07l0.037-0.036l0.072-0.036h0.018l0.072-0.035h0.035l0.055-0.035
                                                                        l0.035-0.037l0.072-0.035h0.035l0.072-0.036l0.035-0.035h0.054l0.035-0.036l0.072-0.035l0.035-0.037h0.055l0.035-0.035
                                                                        l0.072-0.035h0.037l0.035-0.036l0.055-0.036l0.053-0.036l0.037-0.035h0.07l0.037-0.036l0.035-0.035l0.055-0.037l0.052-0.035
                                                                        l0.037-0.035h0.053l0.054-0.036l0.358-0.251l0.035-0.035c0.323-0.25,0.609-0.501,0.895-0.752l0.072-0.07l0.07-0.072v-0.036
                                                                        c0.644-0.608,1.181-1.358,1.628-2.146l0.018-0.037l0.305-0.607l0.018-0.036l0.072-0.179l0.018-0.036l0.018-0.071l0.035-0.035
                                                                        l0.02-0.072l0.018-0.035l0.035-0.072v-0.035l0.037-0.072l0,0l0.07-0.215l0,0l0.037-0.106l0.035-0.108l0,0l0.035-0.107v-0.035
                                                                        l0.037-0.071v-0.036l0.035-0.072v-0.035l0.018-0.107l0.02-0.07l0.018-0.037l0.018-0.106l0,0l0.035-0.108v-0.035l0.019-0.071
                                                                        v-0.036l0.018-0.071v-0.036l0.035-0.072v-0.035v-0.071l0.018-0.071l0.019-0.035v-0.072v-0.036l0.018-0.071l0.018-0.072v-0.07
                                                                        v-0.036v-0.072l0.018-0.036l0.018-0.07v-0.036v-0.107l0,0v-0.108v-0.035l0.02-0.071v-0.036l0.018-0.107l0,0v-0.106l0,0v-0.108
                                                                        v-0.035v-0.107l0,0v-0.107c0-2.647-1.074-5.008-2.772-6.726l-0.09-0.108l-0.072-0.07l-0.072-0.072h-0.018l-0.09-0.07l-0.072-0.072
                                                                        l-0.089-0.072l0,0l-0.09-0.071l-0.072-0.071l-0.107-0.071l-0.179-0.144l-0.089-0.072l0,0l-0.089-0.07l-0.09-0.072l-0.089-0.072
                                                                        l0,0l-0.107-0.07l0,0l-0.089-0.036l0,0l-0.09-0.071l-0.107-0.072l-0.179-0.106h-0.018l-0.09-0.072l0,0l-0.107-0.036l0,0
                                                                        l-0.216-0.106l-0.088-0.072l0,0l-0.089-0.035H232.1l-0.197-0.108l0,0l-0.107-0.035v-0.035l-0.214-0.072l0,0l-0.107-0.035l0,0
                                                                        l-0.109-0.035l0,0l-0.107-0.072l-0.107-0.036l-0.214-0.071h-0.02l-0.088-0.035h-0.019l-0.107-0.037l0,0l-0.107-0.035h-0.018
                                                                        l-0.323-0.071l0,0l-0.107-0.036h-0.018l-0.107-0.036h-0.018l-0.109-0.036l0,0l-0.107-0.035h-0.107h-0.018l-0.107-0.035h-0.126l0,0
                                                                        l-0.107-0.036h-0.125l-0.126-0.036h-0.107l-0.125-0.035h-0.125h-0.109h-0.125l0,0l-0.125-0.036h-0.107h-0.144h-0.107h-0.126
                                                                        h-0.125h-0.125h-0.126h-0.107l-0.125,0.036h-0.018h-0.109l0,0h-0.125h-0.125l-0.107,0.035h-0.126l-0.107,0.036h-0.017h-0.107l0,0
                                                                        l-0.108,0.036h-0.018h-0.107l0,0l-0.125,0.035l0,0h-0.107l0,0l-0.109,0.035h-0.018l-0.107,0.036h-0.018l-0.107,0.036l0,0
                                                                        l-0.107,0.036l0,0l-0.323,0.071h-0.035l-0.197,0.072h-0.02l-0.089,0.035h-0.018l-0.214,0.071l0,0l-0.107,0.036l0,0l-0.109,0.072
                                                                        l0,0l-0.214,0.07l0,0L223.854,142.529L223.854,142.529z
                                                                        M60.938,160.634h0.02h0.107h0.107h0.018h0.125l0,0h0.109h0.107h0.018
                                                                        h0.107h0.018l0.109-0.036l0,0h0.107h0.018h0.09h0.035h0.09h0.018l0.089-0.035h0.037h0.09h0.018l0.089-0.035h0.035h0.072h0.035
                                                                        l0.072-0.037h0.055h0.072l0.035-0.035h0.089h0.035l0.072-0.036h0.037h0.07h0.037l0.07-0.035h0.054l0.055-0.036h0.053h0.054
                                                                        l0.053-0.036h0.054l0.072-0.036h0.035h0.072l0.035-0.035l0.072-0.035h0.037h0.07l0.037-0.037h0.07l0.037-0.035h0.072l0.035-0.036
                                                                        h0.072l0.035-0.035l0.072-0.036h0.035l0.072-0.035h0.037l0.07-0.037h0.037l0.07-0.035h0.02l0.089-0.035h0.018l0.089-0.072h0.018
                                                                        l0.09-0.035l0,0l0.107-0.035l0,0l0.107-0.072l0,0l0.089-0.036l0.09-0.035l0.107-0.071l0.109-0.037l0,0l0.089-0.07h0.018
                                                                        l0.072-0.036l0.018-0.036l0.089-0.036l0,0l0.09-0.035l0.018-0.036l0.072-0.035h0.018l0.089-0.072l0,0l0.072-0.035l0.035-0.036
                                                                        l0.072-0.036l0.018-0.035l0.072-0.035h0.018l0.072-0.037l0.035-0.035l0.035-0.036l0.037-0.036l0.072-0.035l0.035-0.035
                                                                        l0.035-0.037l0.037-0.035l0.072-0.035l0.035-0.036l0.035-0.036l0.037-0.035l0.052-0.037l0.037-0.035l0.053-0.035l0.037-0.036
                                                                        h0.035l0.054-0.071h0.035l0.055-0.072l0.035-0.035l0.035-0.037l0.037-0.034l0.053-0.036l0.019-0.036l0.07-0.036l0.144-0.179
                                                                        c0.393-0.394,0.769-0.822,1.091-1.288l0,0l0.053-0.107h0.019l0.232-0.393l0,0l0.179-0.287l0,0l0.055-0.107l0.107-0.178h0.018
                                                                        l0.035-0.107l0,0l0.054-0.108l0.055-0.106l0.035-0.107l0.055-0.107l0.053-0.107l0.035-0.107l0,0l0.037-0.107l0,0l0.053-0.107
                                                                        l0.037-0.107l0.035-0.107l0.055-0.107l0.035-0.106l0.037-0.108l0,0l0.035-0.143l0.018-0.107l0.037-0.107l0.018-0.107l0.035-0.107
                                                                        l0.037-0.106l0.035-0.144l0.018-0.107l0.018-0.107l0.02-0.107l0.018-0.143l0.035-0.107v-0.107l0.018-0.143l0.02-0.108l0.018-0.106
                                                                        l0.018-0.144v-0.108v-0.106l0.018-0.144v-0.106v-0.108l0.018-0.143v-0.107v-0.143l0,0c-0.035-2.576-1.109-4.902-2.79-6.583
                                                                        c-3.685-3.686-9.75-3.686-13.435,0c-1.718,1.718-2.772,4.078-2.772,6.726c0,2.612,1.037,5.01,2.755,6.69
                                                                        C56.019,159.561,58.362,160.598,60.938,160.634z
                                                                        M70.58,318.057L70.58,318.057v-0.107v-0.107v-0.035v-0.107h-0.018v-0.107l0,0
                                                                        v-0.107v-0.035l-0.018-0.107l0,0v-0.107v-0.037v-0.07v-0.036l-0.018-0.106l0,0l-0.018-0.108v-0.035v-0.072l-0.02-0.036
                                                                        l-0.018-0.07v-0.036v-0.107v-0.036l-0.035-0.072v-0.035v-0.071l-0.018-0.036l-0.02-0.107l0,0l-0.018-0.106v-0.036l-0.018-0.072
                                                                        v-0.035l-0.035-0.071v-0.036l-0.019-0.071l-0.018-0.036l-0.018-0.071l-0.018-0.036v-0.107l-0.018-0.035l-0.019-0.072v-0.035
                                                                        l-0.035-0.072v-0.035l-0.035-0.072v-0.035l-0.037-0.071v-0.036l-0.035-0.072v-0.035l-0.037-0.071v-0.036l-0.035-0.071
                                                                        l-0.018-0.036l-0.018-0.071l-0.019-0.071l-0.018-0.036l-0.035-0.071v-0.035l-0.037-0.072l-0.017-0.036l-0.018-0.071l-0.035-0.035
                                                                        v-0.072l-0.037-0.035l-0.018-0.036l-0.035-0.072l-0.017-0.071l-0.037-0.035l-0.018-0.036l-0.018-0.071l-0.037-0.036l-0.018-0.036
                                                                        l-0.018-0.07l-0.035-0.036l-0.037-0.072v-0.035l-0.035-0.071l-0.037-0.037l-0.035-0.07v-0.036l-0.054-0.071h-0.018l-0.035-0.072
                                                                        l-0.018-0.035l-0.037-0.072l-0.018-0.035l-0.035-0.072l-0.037-0.035l-0.035-0.035l-0.018-0.036l-0.055-0.072v-0.036l-0.053-0.07
                                                                        h-0.019l-0.035-0.072l-0.035-0.036l-0.037-0.071l-0.018-0.035l-0.055-0.072l0,0l-0.07-0.071v-0.036l-0.072-0.07l0,0l-0.072-0.072
                                                                        v-0.036l-0.072-0.071l0,0l-0.07-0.072v-0.035l-0.072-0.071l0,0l-0.072-0.072l0,0l-0.072-0.106l0,0l-0.072-0.072l0,0l-0.07-0.071
                                                                        l-0.09-0.106l-0.072-0.072l0,0l-0.089-0.072c-1.718-1.717-4.098-2.79-6.726-2.79h-0.107h-0.02
                                                                        c-2.557,0.036-4.883,1.109-6.582,2.79c-1.718,1.719-2.772,4.115-2.772,6.727c0,2.647,1.037,5.01,2.755,6.727
                                                                        c1.716,1.718,4.096,2.755,6.726,2.755c2.521,0,4.829-0.966,6.512-2.54l0.214-0.215C69.471,322.959,70.545,320.633,70.58,318.057z
                                                                        M221.169,311.223c-0.465,0.466-0.875,0.967-1.233,1.503l0,0l-0.179,0.286l0,0l-0.161,0.287l0,0l-0.053,0.07l-0.018,0.037
                                                                        l-0.037,0.071l-0.018,0.035l-0.035,0.072l-0.02,0.036l-0.035,0.07l-0.018,0.036l-0.018,0.036l-0.037,0.071l-0.018,0.036
                                                                        l-0.018,0.072l-0.037,0.034l-0.018,0.072l-0.018,0.036l-0.035,0.035l-0.02,0.072l-0.018,0.035l-0.018,0.071l-0.035,0.036
                                                                        l-0.02,0.035l-0.018,0.072l-0.018,0.035l-0.035,0.072v0.036l-0.037,0.106l0,0l-0.035,0.107l0,0l-0.035,0.107l0,0l-0.037,0.107l0,0
                                                                        l-0.035,0.107l0,0l-0.037,0.107l0,0l-0.035,0.107l0,0l-0.035,0.107l0,0l-0.02,0.107v0.035l-0.018,0.072l-0.018,0.035l-0.018,0.071
                                                                        v0.036l-0.037,0.072v0.035v0.072l-0.035,0.035v0.071v0.071l-0.035,0.072v0.035v0.072l-0.02,0.035l-0.017,0.072v0.035v0.071
                                                                        l-0.018,0.072l-0.018,0.036v0.07v0.036v0.072l-0.018,0.071l-0.02,0.072v0.035v0.071v0.036v0.07v0.037l-0.018,0.107v0.035
                                                                        l-0.018,0.072v0.035v0.107l0,0v0.107l0,0v0.107v0.035v0.107v0.358v0.106v0.037l0.035,0.465l0.02,0.07l0.053,0.43v0.037
                                                                        l0.072,0.356v0.072l0.018,0.036l0.018,0.07v0.036l0.037,0.072v0.035l0.018,0.071l0.018,0.037v0.106h0.018l0.02,0.106l0,0
                                                                        l0.035,0.108l0,0l0.035,0.107l0,0l0.037,0.106l0,0l0.035,0.108v0.035l0.037,0.071v0.037l0.035,0.07l0,0l0.035,0.143l0,0
                                                                        l0.251,0.609v0.035l0.054,0.072h0.018c0.465,1.002,1.109,1.932,1.877,2.719l0.072,0.072l0.089,0.07l0.035,0.072h0.037l0.053,0.071
                                                                        h0.037l0.052,0.071l0.037,0.036l0.035,0.035l0.037,0.036l0.053,0.036l0.035,0.036l0.054,0.035l0.035,0.035l0.037,0.037
                                                                        l0.052,0.035l0.037,0.036l0.053,0.036l0.037,0.035l0.053,0.035h0.02l0.07,0.072h0.037l0.072,0.071l0,0l0.07,0.036l0.037,0.035
                                                                        l0.07,0.037v0.035l0.089,0.035h0.02l0.07,0.072l0,0l0.109,0.071l0.089,0.036l0,0l0.089,0.071l0,0l0.107,0.035v0.036l0.09,0.036
                                                                        h0.017l0.072,0.036l0.035,0.035l0.072,0.035h0.018l0.072,0.037l0.018,0.035l0.072,0.036h0.035l0.072,0.035l0.035,0.036
                                                                        l0.072,0.035h0.035l0.054,0.037l0.055,0.035h0.053l0.054,0.035h0.035l0.072,0.036l0.035,0.036l0.216,0.07l0.07,0.037h0.037
                                                                        l0.072,0.035h0.018l0.089,0.036h0.018l0.089,0.035l0.018,0.036h0.089l0.018,0.036h0.089h0.02l0.088,0.071h0.019h0.09l0.125,0.035
                                                                        l0,0l0.107,0.036l0,0l0.107,0.036h0.02l0.089,0.036h0.018h0.089l0.018,0.035h0.107h0.018l0.089,0.036h0.037h0.07l0.037,0.035
                                                                        h0.072h0.035l0.072,0.037h0.053h0.054h0.072l0.053,0.035h0.055h0.072h0.053h0.054l0.072,0.035h0.035h0.072h0.035h0.089h0.035
                                                                        l0.089,0.036h0.037h0.072h0.035h0.107h0.018h0.089h0.02h0.107h0.018h0.107l0,0h0.125h0.126l0,0h0.107l0,0h0.107h0.037h0.087h0.02
                                                                        h0.107h0.018h0.089h0.035l0.09-0.036h0.019h0.089h0.053h0.072h0.035l0.072-0.035h0.054h0.053h0.072h0.035l0.072-0.035h0.054h0.072
                                                                        h0.035l0.072-0.037h0.052h0.072l0.035-0.035h0.072h0.037l0.09-0.036h0.018h0.107v-0.035h0.107l0,0l0.107-0.036l0,0l0.107-0.036
                                                                        h0.02l0.107-0.036l0,0l0.214-0.035h0.018l0.089-0.071h0.019h0.088l0.02-0.036h0.07l0.037-0.036l0.607-0.214l0.037-0.036
                                                                        c0.572-0.215,1.109-0.537,1.591-0.858h0.018l0.34-0.287h0.018l0.072-0.035l0.018-0.035l0.054-0.036l0.035-0.036l0.072-0.035
                                                                        l0.018-0.037l0.055-0.035l0.035-0.035l0.055-0.036l0.035-0.036l0.054-0.036l0.035-0.035l0.037-0.036l0.035-0.035l0.053-0.036
                                                                        l0.037-0.035l0.035-0.036l0.055-0.036l0.142-0.179c0.43-0.43,0.823-0.895,1.164-1.395v-0.037l0.125-0.179l0,0l0.251-0.393
                                                                        l0.037-0.108l0,0l0.07-0.07l0,0l0.054-0.108l0,0l0.053-0.106l0.037-0.107l0,0l0.072-0.108l0,0l0.035-0.106l0.054-0.107
                                                                        l0.053-0.107l0,0l0.037-0.107l0.035-0.107l0,0l0.055-0.107l0,0l0.053-0.107l0.037-0.107l0.035-0.107l0.035-0.107l0,0l0.037-0.106
                                                                        l0.035-0.108l0.035-0.106l0.037-0.107l0,0l0.035-0.108l0.018-0.143l0.037-0.107l0.018-0.106l0.035-0.108l0.019-0.143l0.018-0.107
                                                                        l0.035-0.107l0.018-0.107l0.019-0.143l0.018-0.107l0.018-0.107v-0.144l0.035-0.106v-0.108v-0.144l0.02-0.106l0.018-0.106v-0.144
                                                                        v-0.108v-0.143v-0.107c0-2.611-1.074-5.008-2.772-6.727l-0.09-0.07l0,0l-0.072-0.072l-0.072-0.107l-0.09-0.072h-0.018l-0.072-0.07
                                                                        l0,0l-0.072-0.072h-0.018l-0.09-0.071l0,0l-0.072-0.071l0,0l-0.089-0.071h-0.018l-0.142-0.144h-0.02h-0.018l-0.072-0.072h-0.018
                                                                        l-0.07-0.035l-0.019-0.036l-0.07-0.035l-0.02-0.036l-0.07-0.035l-0.02-0.037l-0.07-0.035h-0.037l-0.072-0.071h-0.018l-0.072-0.036
                                                                        l-0.018-0.035l-0.072-0.035l-0.035-0.037l-0.072-0.035h-0.018l-0.072-0.036l-0.018-0.035l-0.072-0.036l-0.035-0.036l-0.072-0.036
                                                                        h-0.035l-0.216-0.106l-0.053-0.036l-0.035-0.036l-0.161-0.071h-0.037l-0.07-0.035l-0.037-0.037l-0.072-0.035h-0.035l-0.072-0.035
                                                                        l-0.142-0.072h-0.055l-0.162-0.07l-0.052-0.037h-0.055l-0.053-0.035l-0.054-0.036h-0.054l-0.053-0.036h-0.055l-0.052-0.035h-0.055
                                                                        l-0.053-0.035h-0.054l-0.072-0.037h-0.035l-0.072-0.035h-0.054l-0.214-0.071h-0.072l-0.035-0.036h-0.072h-0.035l-0.089-0.036
                                                                        h-0.037l-0.089-0.036h-0.018h-0.089h-0.035l-0.072-0.035h-0.035h-0.089l-0.018-0.035h-0.109h-0.018l-0.089-0.036h-0.035h-0.09
                                                                        h-0.018h-0.108v-0.036h-0.107h-0.018h-0.107h-0.018l-0.107-0.035l0,0h-0.109h-0.018h-0.107h-0.018h-0.107l0,0h-0.107h-0.02h-0.125
                                                                        v-0.036h-0.107h-0.018h-0.109h-0.125l0,0h-0.107l-0.018,0.036h-0.107h-0.02h-0.107l0,0h-0.107h-0.018h-0.107h-0.019h-0.107h-0.018
                                                                        l-0.089,0.035h-0.035h-0.072h-0.035h-0.108l-0.018,0.036h-0.09h-0.018h-0.089h-0.035l-0.089,0.036h-0.019h-0.107l-0.018,0.035
                                                                        h-0.089h-0.035L226,308.646h-0.035h-0.072h-0.037l-0.089,0.036h-0.035l-0.072,0.036h-0.053h-0.072l-0.035,0.036h-0.072
                                                                        l-0.037,0.036h-0.07l-0.037,0.035h-0.072h-0.035l-0.072,0.035h-0.053l-0.054,0.037h-0.053l-0.054,0.035h-0.055l-0.053,0.035
                                                                        h-0.072l-0.054,0.036h-0.053l-0.055,0.036l-0.052,0.035h-0.037l-0.072,0.037h-0.053l-0.054,0.035l-0.035,0.035h-0.072
                                                                        l-0.035,0.036l-0.072,0.036h-0.035l-0.072,0.035h-0.037l-0.07,0.035l-0.037,0.037l-0.072,0.035h-0.035l-0.054,0.036l-0.053,0.035
                                                                        l-0.055,0.036h-0.035l-0.055,0.036l-0.035,0.036h-0.072l-0.035,0.035l-0.072,0.035l-0.035,0.036l-0.072,0.036h-0.037l-0.053,0.036
                                                                        l-0.018,0.035l-0.072,0.036h-0.035l-0.072,0.072h-0.037l-0.07,0.035l-0.02,0.035l-0.053,0.036h-0.037l-0.07,0.071h-0.037
                                                                        l-0.072,0.072l0,0l-0.07,0.035l-0.037,0.036l-0.07,0.035v0.036l-0.072,0.035h-0.037l-0.07,0.072h-0.02l-0.07,0.071h-0.019
                                                                        l-0.07,0.072l0,0l-0.089,0.071h-0.02l-0.07,0.071l0,0l-0.072,0.071h-0.018l-0.072,0.072h-0.018l-0.072,0.07l0,0l-0.09,0.072v0.036
                                                                        l-0.089,0.071l0,0l-0.072,0.072l0,0L221.169,311.223z
                                                                        M60.938,244.033h0.02h0.107h0.107h0.035h0.107l0,0h0.109h0.018h0.107h0.018
                                                                        h0.107l0,0h0.109h0.018l0.107-0.037h0.018h0.107l0,0h0.107h0.019l0.107-0.035h0.018h0.09h0.018l0.107-0.036h0.019h0.088h0.037
                                                                        l0.089-0.035h0.018h0.089h0.035l0.089-0.036h0.037h0.072l0.035-0.036h0.072h0.035l0.089-0.036h0.035l0.072-0.035h0.037h0.07
                                                                        l0.054-0.035h0.072l0.035-0.036h0.072l0.035-0.036h0.072h0.037l0.07-0.036h0.055l0.052-0.035l0.055-0.036h0.054l0.053-0.035h0.054
                                                                        l0.053-0.037h0.055l0.054-0.035h0.053l0.072-0.035l0.035-0.036h0.072l0.035-0.036h0.072l0.037-0.035l0.07-0.035h0.037l0.053-0.037
                                                                        l0.055-0.035h0.054l0.053-0.036l0.054-0.036h0.053l0.054-0.035l0.035-0.035l0.055-0.037h0.035l0.072-0.035l0.035-0.036
                                                                        l0.072-0.035h0.037l0.07-0.036l0.037-0.036l0.072-0.036h0.018l0.07-0.035l0.019-0.035l0.07-0.036l0.037-0.036l0.072-0.036h0.018
                                                                        l0.072-0.035l0.018-0.036l0.072-0.035l0.035-0.037l0.072-0.034h0.018l0.072-0.072h0.018l0.072-0.036l0.035-0.035l0.072-0.035
                                                                        v-0.037l0.072-0.035l0.035-0.036l0.072-0.035l0,0l0.089-0.071h0.018l0.072-0.072l0,0l0.089-0.071l0,0l0.089-0.072l0,0l0.07-0.071
                                                                        h0.019l0.07-0.071h0.02l0.07-0.071v-0.036l0.09-0.071l0.089-0.072l0.072-0.071l0,0l0.072-0.071
                                                                        c1.681-1.682,2.755-4.043,2.79-6.619l0,0v-0.107v-0.107v-0.035l-0.018-0.107v-0.143v-0.108l-0.018-0.106v-0.107v-0.143
                                                                        l-0.018-0.108l-0.018-0.144l-0.02-0.106l-0.018-0.107v-0.144l-0.035-0.107l-0.018-0.107l-0.02-0.107l-0.018-0.143l-0.018-0.107
                                                                        l-0.035-0.107l-0.037-0.107l-0.035-0.143l-0.018-0.107l-0.037-0.107l-0.018-0.107l-0.035-0.107l-0.037-0.107l-0.035-0.107
                                                                        l-0.055-0.107l-0.035-0.106l-0.037-0.144l-0.053-0.072v-0.035l-0.037-0.071l-0.035-0.144l-0.053-0.071l-0.055-0.106l-0.035-0.108
                                                                        l-0.055-0.106l-0.054-0.108l-0.035-0.107h-0.018l-0.055-0.106l-0.052-0.108l-0.055-0.071l-0.054-0.107l0,0l-0.053-0.107l0,0
                                                                        l-0.072-0.107l0,0l-0.053-0.106l0,0l-0.054-0.072l0,0l-0.144-0.215l-0.053-0.071l-0.054-0.107l-0.072-0.071l0,0l-0.142-0.215
                                                                        l-0.144-0.179l0,0l-0.07-0.071l0,0l-0.072-0.072v-0.036l-0.216-0.25l0,0l-0.089-0.072l0,0l-0.07-0.07l0,0l-0.161-0.18
                                                                        l-0.072-0.071l0,0l-0.089-0.071l-0.411-0.394l-0.037-0.035l-0.142-0.108l-0.037-0.035l-0.142-0.107l-0.037-0.036l-0.07-0.035l0,0
                                                                        l-0.072-0.071h-0.037l-0.07-0.072l0,0l-0.179-0.107l0,0l-0.107-0.07l0,0l-0.072-0.072h-0.018l-0.089-0.036v-0.036l-0.089-0.035H66
                                                                        l-0.07-0.072h-0.037l-0.07-0.035l-0.019-0.035l-0.07-0.036h-0.02l-0.07-0.036l-0.037-0.036l-0.072-0.036H65.5l-0.072-0.035
                                                                        l-0.035-0.035h-0.055l-0.035-0.037L65.249,226l-0.053-0.035h-0.054l-0.053-0.036l-0.054-0.036H64.98l-0.035-0.035h-0.054
                                                                        l-0.053-0.037l-0.054-0.035h-0.053l-0.055-0.035h-0.035l-0.072-0.036l-0.035-0.036h-0.089l-0.019-0.035l-0.09-0.035h-0.018
                                                                        l-0.089-0.037h-0.018l-0.089-0.035h-0.018l-0.089-0.036h-0.018l-0.107-0.035l0,0l-0.108-0.036h-0.107v-0.035h-0.107l0,0
                                                                        l-0.107-0.037h-0.018l-0.089-0.035h-0.02l-0.089-0.035H63.21h-0.072l-0.035-0.036h-0.072h-0.035l-0.072-0.036H62.87h-0.07h-0.054
                                                                        l-0.072-0.036h-0.035h-0.072l-0.053-0.035h-0.054h-0.072h-0.035H62.28l-0.054-0.036h-0.07h-0.037h-0.09h-0.035l-0.072-0.035
                                                                        h-0.035h-0.089H61.78h-0.109h-0.018h-0.089h-0.035h-0.089h-0.018h-0.109l0,0l-0.107-0.037h-0.018h-0.125h-0.107h-0.02
                                                                        c-2.557,0.037-4.883,1.109-6.582,2.791c-1.735,1.718-2.772,4.08-2.772,6.727c0,2.505,0.965,4.795,2.557,6.512l0.216,0.215
                                                                        C56.054,242.959,58.381,244.033,60.938,244.033z
                                                                        M161.099,143.496c0.179,0.43,0.358,0.859,0.537,1.287l0.018,0.072l0.125,0.394
                                                                        h0.019l0.035,0.144l0.232,0.752l0.037,0.143h0.018l0.035,0.143l0.02,0.072l0.018,0.107l0.052,0.143h47.872l0.216-0.823
                                                                        l0.035-0.106l0.035-0.107v-0.036l0.144-0.394l0.018-0.036l0.055-0.179l0,0l0.035-0.144l0.035-0.071l0.072-0.215l0,0l0.072-0.143
                                                                        l0.035-0.036l0.019-0.106l0.053-0.107v-0.036l0.072-0.144v-0.035l0.179-0.358v-0.036l0,0l0.072-0.143l0.035-0.071l0.035-0.071
                                                                        l0.055-0.107v-0.036l0.072-0.144l0.018-0.035l0.054-0.071l0.053-0.144h0.018l0.055-0.144l0.035-0.035l0.055-0.108l0.142-0.285
                                                                        h0.019l0.07-0.107l0.037-0.072l0.035-0.07l0.144-0.251l0.035-0.071l0.054-0.072l0.072-0.107l0.018-0.035l0.07-0.107l0.037-0.035
                                                                        l0.053-0.108l0.054-0.106l0.035-0.037l0.162-0.25l0.054-0.071l0.035-0.035l0.089-0.144l0,0l0.179-0.25l0.089-0.107l0,0
                                                                        l0.089-0.143l0.286-0.358h0.018l0.286-0.358l0,0l0.144-0.143l0.054-0.072l0.089-0.107l0,0l0.107-0.143l0.035-0.036l0.072-0.071
                                                                        l0.072-0.071l0.035-0.036l0.214-0.214l0.055-0.072l0.054-0.036l0.107-0.107l0,0l0.142-0.178l0,0l0.162-0.144l0.162-0.144l0,0
                                                                        l0.161-0.143l0.16-0.143v-0.037l0.179-0.143l0,0l0.162-0.143l0,0l0.161-0.107v-0.037l0.179-0.106l0,0l0.179-0.144l0,0l0.16-0.143
                                                                        h0.02l0.16-0.143l0,0h0.019l0.321-0.252h0.035c0.465-0.357,0.949-0.68,1.467-0.965l0,0l0.179-0.107h0.037l0.358-0.215h0.018
                                                                        l0.375-0.18h0.018l0.179-0.106h0.018l0.805-0.358h0.018l0.609-0.25v-0.035l0.214-0.072l0,0l0.196-0.071h0.018l0.198-0.072h0.018
                                                                        l0.197-0.071h0.018l0.179-0.071h0.035l0.198-0.071h0.018l0.197-0.036h0.018l0.196-0.071h0.018l0.216-0.071l0,0l0.214-0.036l0,0
                                                                        l0.214-0.072l0,0l0.216-0.035h0.018l0.214-0.035h0.02l0.196-0.072h0.018l0.214-0.035l0,0l0.216-0.036h0.018l0.214-0.036h0.018
                                                                        l0.216-0.035l0,0l0.214-0.037h0.017h0.233l0,0l0.214-0.035l0,0l0.233-0.035h0.018h0.214l0,0l0.233-0.036h0.018h0.214h0.25
                                                                        l0.214-0.036h0.251h0.231h0.233h0.251l0.214,0.036h0.251h0.214l0,0l0.233,0.036h0.018h0.214l0,0l0.233,0.035h0.018l0.214,0.035
                                                                        l0,0h0.216h0.018l0.232,0.037l0,0l0.216,0.035l0,0l0.214,0.036h0.018l0.233,0.036l0,0l0.214,0.035l0,0l0.214,0.072h0.02
                                                                        l0.214,0.035h0.018l0.196,0.035h0.018l0.216,0.072l0,0l0.214,0.036l0,0l0.214,0.071h0.02l0.196,0.071h0.018l0.197,0.036h0.018
                                                                        l0.196,0.071h0.02l0.196,0.071h0.018l0.197,0.071h0.018l0.196,0.072h0.02l1.02,0.394l0.017,0.035l0.394,0.143l0.017,0.037
                                                                        c2.021,0.895,3.864,2.183,5.403,3.721c0.985,1.002,1.86,2.074,2.576,3.291h0.018l0.233,0.357l0,0l0.107,0.215l0,0l0.107,0.18l0,0
                                                                        l0.107,0.179l0,0l0.108,0.215l0,0l0.107,0.179l0,0l0.089,0.215h0.018l0.072,0.179l0.107,0.215l0,0l0.09,0.214h0.018l0.072,0.18
                                                                        l0,0l0.089,0.215h0.018l0.072,0.215l0.07,0.214l0.109,0.179l0.07,0.215l0.072,0.215l0,0l0.072,0.215l0,0l0.072,0.215l0.072,0.215
                                                                        l0.07,0.214l0.054,0.216l0.053,0.215l0.072,0.213l0.037,0.216l0,0l0.07,0.25l0.037,0.215l0.053,0.215l0.054,0.214l0.035,0.215
                                                                        l0.037,0.25l0.035,0.215l0.037,0.215l0.035,0.25l0.018,0.216l0.018,0.25l0.037,0.215l0.018,0.215l0.018,0.25v0.216l0.018,0.25
                                                                        l0.018,0.215v0.25v0.25v0.215l0,0v0.215v0.035l-0.018,0.215l0,0l-0.018,0.215v0.036v0.179v0.036l-0.018,0.214v0.036l-0.018,0.179
                                                                        v0.072l-0.018,0.143l-0.019,0.071v0.144l-0.018,0.071l-0.018,0.179v0.072L246,153.621v0.072l-0.037,0.143v0.072l-0.035,0.143
                                                                        v0.107l-0.037,0.107v0.107l-0.035,0.107v0.107l-0.035,0.107l-0.02,0.143l-0.018,0.072l-0.035,0.143l-0.018,0.071l-0.019,0.143
                                                                        l-0.035,0.072l-0.035,0.144v0.071l-0.037,0.144l-0.035,0.07l-0.037,0.143l-0.018,0.072l-0.035,0.18l-0.017,0.035l-0.037,0.18
                                                                        l-0.035,0.035l-0.035,0.179l-0.019,0.036l-0.053,0.179l-0.018,0.036l-0.054,0.179h-0.018l-0.055,0.214l0,0h-0.018l-0.125,0.395
                                                                        h-0.019l-0.09,0.214l0,0l-0.07,0.215l0,0l-0.251,0.573l-0.018,0.035c-0.913,2.004-2.166,3.828-3.685,5.367l-0.055,0.035
                                                                        c-2.29,2.325-5.241,4.008-8.516,4.83h-0.018v47.871l0.144,0.035l0.018,0.036l0.125,0.036h0.089l0.055,0.036l0.144,0.035h0.018
                                                                        l0.142,0.036l0.072,0.035h0.072l0.125,0.037l0.018,0.034l0.144,0.036h0.072l0.053,0.036l0.162,0.036l0,0l0.125,0.07h0.089
                                                                        l0.054,0.037l0.144,0.035l0.018,0.036l0.125,0.035l0.09,0.036h0.035l0.144,0.072h0.018l0.126,0.07h0.07l0.072,0.036l0.144,0.071
                                                                        l0,0l0.125,0.035l0.072,0.037l0.072,0.035l0.125,0.072h0.018l0.126,0.035l0.07,0.036l0.072,0.036l0.107,0.072h0.017l0.126,0.07
                                                                        l0.072,0.036l0.07,0.036l0.126,0.035l0.018,0.035l0.107,0.037l0.072,0.035l0.072,0.036l0.125,0.071h0.018l0.107,0.072l0.072,0.035
                                                                        l0.072,0.035l0.107,0.072h0.018l0.126,0.07l0.07,0.037l0.037,0.035l0.142,0.107l0,0l0.109,0.072l0.089,0.035l0.053,0.035
                                                                        l0.107,0.072h0.018l0.198,0.107l0.035,0.072l0.144,0.07l0.107,0.072l0.07,0.035l0.054,0.072l0.125,0.071l0,0l0.109,0.071
                                                                        l0.07,0.035l0.054,0.072l0.125,0.071l0,0l0.107,0.108l0.072,0.035l0.037,0.035l0.125,0.107h0.018l0.107,0.072l0.054,0.035
                                                                        l0.055,0.072l0.107,0.071l0.107,0.106l0.072,0.037l0.053,0.071l0.107,0.071l0,0l0.126,0.107l0.035,0.035l0.072,0.072l0.107,0.071
                                                                        l0,0l0.107,0.107l0.037,0.071l0.07,0.036l0.109,0.106l0,0l0.142,0.144l0.179,0.144c0.877,0.895,1.664,1.86,2.362,2.898l0,0
                                                                        l0.107,0.179l0,0l0.107,0.179h0.018l0.107,0.215h0.02l0.107,0.179l0,0l0.107,0.179l0,0l0.107,0.215l0,0l0.107,0.178l0,0
                                                                        l0.108,0.215l0,0l0.107,0.18l0,0l0.089,0.215l0,0l0.089,0.18l0,0l0.107,0.213l0.09,0.18l0.089,0.215l0,0l0.089,0.214h0.018
                                                                        l0.072,0.216l0.07,0.178l0.09,0.216h0.019l0.07,0.214l0,0l0.072,0.215l0.072,0.215l0.072,0.215l0.072,0.215l0.07,0.215
                                                                        l0.054,0.214l0.053,0.215l0,0l0.072,0.215l0.037,0.214l0.07,0.215l0.037,0.252l0.053,0.213l0.054,0.215l0.035,0.216l0.037,0.25
                                                                        l0.035,0.215l0.037,0.214l0.035,0.251l0.018,0.214l0.018,0.252l0.037,0.215l0.018,0.25l0.018,0.215v0.25l0.018,0.215l0.018,0.25
                                                                        v0.215v0.25v0.215l0,0v0.25l0,0l-0.018,0.215v0.036l-0.018,0.179v0.036v0.214l0,0l-0.018,0.215v0.037l-0.018,0.178v0.072
                                                                        l-0.018,0.143l-0.019,0.072v0.179l-0.018,0.035l-0.018,0.179v0.072L246,237.02v0.072l-0.037,0.143v0.071l-0.035,0.144v0.107
                                                                        l-0.037,0.107v0.107l-0.035,0.107v0.107l-0.035,0.143l-0.02,0.107l-0.018,0.107l-0.035,0.107l-0.018,0.107l-0.019,0.107
                                                                        l-0.035,0.107l-0.035,0.107v0.07l-0.037,0.144l-0.035,0.071l-0.037,0.144l-0.018,0.071l-0.035,0.144l-0.017,0.071l-0.037,0.143
                                                                        l-0.035,0.072l-0.035,0.143l-0.019,0.072l-0.053,0.143l-0.018,0.072l-0.054,0.143l-0.018,0.035l-0.055,0.18v0.036l-0.072,0.179
                                                                        l-0.018,0.035l-0.053,0.18l-0.019,0.035l-0.09,0.18l0,0l-0.07,0.215l0,0l-0.072,0.178l-0.107,0.215l0,0l-0.072,0.18v0.035
                                                                        l-0.107,0.18l0,0l-0.072,0.179l-0.035,0.034l-0.072,0.18l0,0l-0.089,0.179l-0.018,0.036l-0.072,0.143l-0.035,0.072l-0.072,0.143
                                                                        l-0.037,0.037l-0.07,0.143l-0.037,0.035l-0.072,0.144l-0.035,0.071l-0.072,0.107l-0.035,0.071l-0.072,0.107l-0.035,0.071
                                                                        l-0.072,0.144l-0.054,0.071l-0.07,0.107l-0.054,0.071l-0.055,0.108l-0.052,0.07l-0.072,0.108l-0.054,0.071l-0.053,0.107
                                                                        l-0.072,0.071l-0.055,0.106l-0.07,0.072l-0.055,0.072l-0.072,0.107l-0.053,0.07l-0.089,0.107l-0.037,0.072l-0.089,0.107
                                                                        l-0.053,0.071l-0.072,0.108l-0.055,0.034L242.137,246l-0.037,0.035l-0.107,0.144l-0.035,0.036l-0.109,0.107l-0.035,0.035
                                                                        l-0.107,0.143l-0.037,0.037l-0.107,0.143h-0.018l-0.125,0.143l-0.018,0.036l-0.126,0.144h-0.018l-0.125,0.143h-0.037l-0.125,0.18
                                                                        l-0.162,0.178c-1.825,1.826-4.078,3.293-6.582,4.187l-0.072,0.036l-0.072,0.036l-0.895,0.286l-0.107,0.035l-0.035,0.037
                                                                        l-0.162,0.035h-0.018l-0.144,0.035l-0.072,0.036h-0.07l-0.144,0.036l0,0l-0.162,0.035v47.871l0.162,0.036v0.036l0.144,0.035h0.07
                                                                        l0.072,0.035l0.144,0.036h0.018l0.162,0.036l0.035,0.035l0.554,0.145l0.09,0.035l0.072,0.035l0.142,0.036l0,0l0.144,0.071
                                                                        l0.072,0.035h0.072l1.162,0.466v0.036l0.126,0.035c2.002,0.931,3.792,2.183,5.294,3.686c0.985,1.002,1.86,2.111,2.576,3.292h0.018
                                                                        l0.233,0.356l0,0l0.107,0.215l0,0l0.107,0.18l0,0l0.107,0.215l0,0l0.108,0.179l0,0l0.107,0.214l0,0l0.089,0.18h0.018l0.072,0.215
                                                                        l0.107,0.179l0,0l0.09,0.214h0.018l0.072,0.215l0,0l0.089,0.18h0.018l0.072,0.215l0.07,0.214l0.109,0.215l0.07,0.215l0.072,0.179
                                                                        l0,0l0.072,0.215l0,0l0.072,0.215l0.072,0.214l0.07,0.215l0.054,0.216l0.053,0.215l0.072,0.25l0.037,0.215l0,0l0.07,0.214
                                                                        l0.037,0.215l0.053,0.215l0.054,0.214l0.035,0.251l0.037,0.214l0.035,0.215L246,315.41l0.035,0.214l0.018,0.215l0.018,0.25
                                                                        l0.037,0.215l0.018,0.25l0.018,0.215v0.251l0.018,0.215l0.018,0.25v0.215v0.25c0,5.045-2.057,9.625-5.366,12.916
                                                                        c-1.054,1.073-2.253,2.004-3.541,2.756l-0.108,0.07l-0.07,0.036l-0.323,0.215h-0.053l-0.126,0.071l-0.072,0.035l-0.018,0.037
                                                                        l-0.162,0.07l-0.142,0.072l-0.054,0.036L236,334.336l-0.054,0.035l-0.142,0.072h-0.037l-0.375,0.18h-0.018l-0.179,0.106h-0.018
                                                                        l-0.196,0.071l0,0l-0.197,0.072h-0.019l-0.179,0.106h-0.018l-0.196,0.072l0,0l-0.197,0.071h-0.018l-0.179,0.071h-0.037
                                                                        l-0.179,0.072h-0.035l-0.162,0.071h-0.053l-0.161,0.071h-0.055l-0.142,0.071h-0.072l-0.144,0.036l-0.07,0.036l-0.144,0.035
                                                                        l-0.072,0.035l-0.142,0.037h-0.072l-0.144,0.035l-0.07,0.036l-0.144,0.035h-0.09l-0.125,0.036l-0.089,0.035h-0.126l-0.107,0.037
                                                                        l-0.107,0.035h-0.107l-0.107,0.035h-0.107l-0.109,0.036h-0.142l-0.072,0.036h-0.142l-0.072,0.036h-0.144l-0.072,0.034h-0.177
                                                                        h-0.054l-0.162,0.037h-0.072l-0.16,0.035h-0.054h-0.179h-0.035l-0.196,0.036h-0.037h-0.196h-0.035l-0.179,0.036h-0.037h-0.214
                                                                        h-0.017h-0.197h-0.037h-0.214l0,0h-0.233h-0.231l0,0h-0.233l0,0h-0.232l0,0h-0.216h-0.035l-0.179-0.036h-0.035h-0.196h-0.055
                                                                        l-0.179-0.036h-0.035h-0.179H226l-0.144-0.035h-0.07l-0.161-0.037h-0.072h-0.161l-0.07-0.034h-0.144l-0.072-0.036h-0.142
                                                                        l-0.089-0.036h-0.126l-0.107-0.036h-0.107l-0.107-0.035h-0.107l-0.107-0.035l-0.126-0.037h-0.107l-0.089-0.035l-0.125-0.036
                                                                        h-0.089l-0.144-0.035l-0.072-0.036l-0.142-0.035h-0.072l-0.144-0.037l-0.07-0.035l-0.144-0.035l-0.072-0.036l-0.142-0.036h-0.072
                                                                        l-0.144-0.071h-0.07l-0.144-0.071h-0.053l-0.162-0.071h-0.037l-0.179-0.072h-0.035l-0.179-0.071h-0.018l-0.179-0.071h-0.018
                                                                        l-0.196-0.072h-0.02l-0.179-0.071l-0.018-0.035l-0.197-0.072l0,0l-0.214-0.071l0,0l-0.179-0.106l0,0l-0.214-0.072l0,0
                                                                        l-0.179-0.107h-0.037l-0.162-0.072l-0.018-0.035l-0.179-0.071l-0.035-0.036l-0.144-0.072l-0.035-0.035l-0.161-0.072h-0.053
                                                                        l-0.144-0.106h-0.035l-0.144-0.108l-0.053-0.035l-0.126-0.071l-0.072-0.036l-0.107-0.07l-0.072-0.037l-0.107-0.071l-0.07-0.035
                                                                        l-0.126-0.072l-0.072-0.036l-0.107-0.07l-0.089-0.072l-0.09-0.036l-0.089-0.071l-0.07-0.072l-0.108-0.07l-0.07-0.036l-0.109-0.071
                                                                        l-0.07-0.072l-0.109-0.071l-0.07-0.035l-0.107-0.071l-0.072-0.072l-0.107-0.071l-0.055-0.036l-0.107-0.107l-0.054-0.035
                                                                        l-0.125-0.107l-0.055-0.036l-0.107-0.071l-0.053-0.035l-0.126-0.108l-0.035-0.036l-0.126-0.107l-0.035-0.035l-0.125-0.107
                                                                        l-0.037-0.035l-0.142-0.144h-0.018l-0.126-0.144h-0.035l-0.144-0.143l0,0l-0.321-0.322c-2.327-2.289-4.025-5.224-4.831-8.515
                                                                        h-47.872h-0.018l-0.035,0.144v0.035l-0.09,0.286l-0.037,0.144l0,0l-0.07,0.285l-0.037,0.144l-0.035,0.036l-0.035,0.143v0.036
                                                                        l-0.055,0.106l-0.018,0.107l-0.037,0.036l-0.035,0.144v0.035l-0.162,0.43h-0.017l-0.035,0.144l-0.037,0.035l-0.035,0.108
                                                                        l-0.303,0.715l0,0l-0.055,0.143l-0.035,0.072l-0.037,0.072l-0.072,0.143l0,0l-0.07,0.143l-0.02,0.036l-0.053,0.107l-0.037,0.106
                                                                        h-0.035l-0.072,0.144v0.036l-0.07,0.107l-0.072,0.143l-0.072,0.144l0,0l-0.072,0.107l-0.035,0.106l-0.037,0.036l-0.07,0.144h-0.02
                                                                        l-0.07,0.143l-0.037,0.036l-0.053,0.071l-0.054,0.107l-0.018,0.036l-0.089,0.144l-0.018,0.035l-0.072,0.071l-0.161,0.251
                                                                        l-0.018,0.035l-0.162,0.251l-0.089,0.106l0,0l-0.089,0.108l-0.16,0.25l-0.02,0.036l-0.089,0.107l-0.107,0.106l-0.053,0.108
                                                                        l-0.02,0.035l-0.214,0.215l-0.035,0.072l-0.054,0.07l-0.09,0.107l0,0l-0.107,0.107l-0.035,0.072l-0.072,0.071l-0.072,0.071
                                                                        l-0.018,0.035l-0.107,0.108l-0.019,0.036l-0.088,0.07l-0.054,0.072l-0.144,0.143l-0.018,0.037l-0.196,0.178l-0.107,0.107l0,0
                                                                        l-0.072,0.071c-0.93,0.931-1.984,1.754-3.113,2.469l-0.09,0.072l-0.089,0.035l-0.107,0.071l-0.072,0.037l-0.107,0.07l-0.089,0.036
                                                                        l-0.375,0.215l-0.107,0.071l-0.072,0.035l-0.179,0.107l-0.142,0.072l-0.072,0.036l-0.144,0.071l-0.035,0.035l-0.162,0.072h-0.035
                                                                        l-0.161,0.107h-0.035l-0.179,0.072l0,0l-0.179,0.106h-0.037l-0.179,0.071l0,0l-0.214,0.072l-0.196,0.106h-0.018l-0.179,0.072
                                                                        h-0.018l-0.198,0.071l0,0l-0.196,0.071h-0.018l-0.179,0.072h-0.035l-0.179,0.071h-0.037l-0.177,0.071H149.9l-0.162,0.071h-0.052
                                                                        l-0.144,0.036l-0.072,0.036l-0.143,0.035l-0.072,0.035l-0.142,0.037h-0.072l-0.144,0.035l-0.089,0.036l-0.125,0.035h-0.107
                                                                        l-0.107,0.036l-0.109,0.035h-0.107l-0.107,0.037l-0.107,0.035h-0.107l-0.107,0.035h-0.126l-0.089,0.036h-0.142l-0.072,0.036
                                                                        h-0.144l-0.089,0.036h-0.142l-0.072,0.034h-0.161h-0.07l-0.144,0.037h-0.072l-0.162,0.035h-0.052h-0.179h-0.035l-0.198,0.036
                                                                        h-0.035h-0.196h-0.037l-0.196,0.036h-0.018h-0.214h-0.018h-0.216h-0.018h-0.214h-0.233h-0.018h-0.179h-0.035h-0.162h-0.089h-0.107
                                                                        h-0.107h-0.072h-0.179h-0.037l-0.177-0.036l0,0h-0.179h-0.072h-0.144l-0.072-0.036h-0.107h-0.142l-0.037-0.035h-0.179h-0.018
                                                                        l-0.197-0.037h-0.018h-0.161l-0.072-0.034h-0.107l-0.107-0.036h-0.089l-0.144-0.036h-0.035l-0.196-0.036l-0.179-0.035h-0.035
                                                                        l-0.144-0.035h-0.072l-0.107-0.037h-0.125l-0.055-0.035l-0.179-0.036h-0.018l-0.179-0.035h-0.018l-0.161-0.036l-0.053-0.035
                                                                        l-0.126-0.037h-0.089l-0.089-0.035l-0.125-0.035h-0.054l-0.16-0.072h-0.02l-0.179-0.071h-0.018l-0.142-0.036l-0.072-0.035
                                                                        l-0.107-0.036l-0.107-0.035h-0.072l-0.144-0.072h-0.035l-0.179-0.071l0,0l-0.179-0.071h-0.035l-0.126-0.036l-0.286-0.143h-0.055
                                                                        l-0.142-0.072h-0.035l-0.144-0.071h-0.035l-0.144-0.071l-0.054-0.035l-0.125-0.037l-0.072-0.035l-0.07-0.036l-0.144-0.071
                                                                        l-0.035-0.036l-0.144-0.071h-0.018l-0.161-0.071l-0.035-0.036l-0.126-0.072l-0.053-0.035l-0.107-0.035l-0.109-0.072h-0.053
                                                                        l-0.126-0.071l-0.035-0.036l-0.162-0.072v-0.035l-0.16-0.071l-0.037-0.036l-0.107-0.07l-0.072-0.037l-0.089-0.035l-0.107-0.071
                                                                        l-0.053-0.036l-0.126-0.072l-0.018-0.035l-0.162-0.071l-0.018-0.036l-0.125-0.071l-0.054-0.036l-0.107-0.072l-0.072-0.07
                                                                        l-0.072-0.036l-0.107-0.071l-0.035-0.035l-0.144-0.108l0,0l-0.142-0.106h-0.037l-0.107-0.107v35.992h254.346v-17.031l-0.716-5.295
                                                                        l-3.471-0.858l-3.417-1.108l-12.505,15.17c-1.395,1.682-3.791,2.074-5.616,0.965l-10.019-6.01
                                                                        c-1.914-1.145-2.647-3.507-1.787-5.475l7.477-18.139l-2.541-2.505l-2.432-2.647l-18.427,6.905
                                                                        c-2.057,0.751-4.329-0.106-5.366-1.968l-5.652-10.232c-1.074-1.933-0.537-4.293,1.145-5.617l15.563-11.986l-0.965-3.47
                                                                        l-0.751-3.472l-19.392-3.254c-2.256-0.358-3.794-2.398-3.65-4.616l0.215-11.485c0.036-2.182,1.682-3.971,3.811-4.257l19.444-2.575
                                                                        l0.896-3.472l1.074-3.435L325.57,250.15c-1.699-1.396-2.074-3.828-0.984-5.652l6.029-10.019c1.126-1.896,3.488-2.612,5.475-1.788
                                                                        l18.139,7.512l2.521-2.61l2.613-2.362l-6.889-18.426c-0.75-2.074,0.107-4.328,1.968-5.367l10.233-5.688
                                                                        c1.913-1.038,4.293-0.537,5.58,1.18l11.987,15.564l3.362-0.93l2.183-0.502V41.35c0-4.186-1.753-8.049-4.526-10.84
                                                                        c-2.771-2.756-6.618-4.508-10.822-4.508H148.773c-4.205,0-8.051,1.752-10.823,4.508c-2.79,2.791-4.508,6.654-4.508,10.84v26.227
                                                                        h220.859c2.432,0,4.4,1.967,4.4,4.399s-1.969,4.401-4.4,4.401H133.442v60.178c1.432-1.072,3.023-1.968,4.759-2.611l0,0
                                                                        l1.037-0.357h0.037l0.142-0.036h0.072l0.214-0.071l0.089-0.035l0.126-0.036h0.035l0.375-0.108h0.018l0.161-0.035h0.072
                                                                        l0.107-0.035h0.125l0.216-0.072h0.035l0.179-0.035l0,0l0.179-0.036h0.053l0.144-0.036h0.089h0.107l0.107-0.035h0.072l0.161-0.037
                                                                        h0.018h0.197h0.018l0.179-0.035h0.037h0.142l0.107-0.035h0.072h0.144h0.053l0.198-0.036l0,0h0.177h0.037h0.179h0.072h0.107
                                                                        l0.107-0.036h0.089h0.162h0.035h0.179h0.018h0.233l0,0h0.231l0,0l0.233,0.036l0,0h0.232h0.018h0.216l0,0l0.231,0.036h0.02h0.214
                                                                        l0,0l0.231,0.035h0.02l0.214,0.035l0,0h0.232l0,0l0.233,0.037l0,0l0.214,0.035l0,0l0.233,0.036l0,0l0.231,0.036l0,0l0.216,0.035
                                                                        l0,0l0.214,0.072h0.018l0.216,0.035h0.018l0.214,0.035l0,0l0.214,0.072l0,0l0.216,0.036l0,0l0.214,0.071l0,0l0.214,0.071l0,0
                                                                        l0.216,0.036h0.018l0.214,0.071l0,0l0.196,0.071h0.02l0.197,0.071h0.017l0.197,0.072h0.018l0.196,0.071l0,0l0.216,0.072l0,0
                                                                        l0.214,0.106l0,0l0.196,0.071h0.018l0.198,0.107l0,0l0.197,0.072l0,0l0.214,0.107l0,0l0.197,0.071l0,0l0.196,0.107l0,0
                                                                        l0.198,0.106h0.018l0.179,0.072l0,0l0.196,0.107h0.018l0.179,0.107l0,0l0.196,0.107l0,0l0.197,0.107l0,0l0.179,0.107l0,0
                                                                        l0.197,0.107l0,0l0.196,0.143l0,0l0.179,0.107l0,0l0.179,0.107l0,0l0.179,0.143l0,0l0.179,0.107h0.018l0.161,0.143h0.018
                                                                        l0.162,0.108h0.018l0.161,0.144h0.018l0.162,0.143l0,0l0.179,0.107l0,0l0.179,0.143l0,0l0.162,0.143h0.018l0.142,0.145h0.019
                                                                        l0.16,0.143l0,0l0.162,0.143l0,0l0.161,0.144l0,0l0.162,0.144h0.018l0.142,0.143l0,0l0.162,0.18l0,0l0.161,0.143l0,0l0.107,0.107
                                                                        l0.107,0.107c0.323,0.357,0.609,0.68,0.913,1.037l0.017,0.035l0.144,0.18l0.035,0.036l0.179,0.25l0.037,0.036l0.07,0.072
                                                                        l0.072,0.106h0.018l0.089,0.144l0,0l0.09,0.106l0.054,0.071l0.035,0.072l0.072,0.107h0.018l0.089,0.143l0.018,0.036l0.072,0.107
                                                                        l0.054,0.071l0.035,0.035l0.072,0.144l0,0l0.09,0.108l0.035,0.07l0.054,0.072l0.072,0.107v0.035l0.16,0.25l0.055,0.072
                                                                        l0.035,0.072l0.072,0.107v0.035l0.216,0.393l0.07,0.144h0.018l0.377,0.788L161.099,143.496z
                                                                        M144.104,160.634h0.393h0.375h0.072
                                                                        h0.018h0.109h0.018l0.089-0.036h0.035h0.089h0.037h0.088h0.037h0.072l0.035-0.035h0.072h0.052h0.072h0.054l0.053-0.035h0.054
                                                                        h0.072l0.035-0.037h0.072h0.053h0.037h0.018l0.072-0.035h0.035h0.072l0.054-0.036h0.053h0.054l0.09-0.035h0.035h0.072l0.035-0.036
                                                                        h0.072h0.035l0.072-0.036h0.037l0.07-0.036h0.037l0.089-0.035h0.018l0.107-0.035l0,0l0.107-0.037l0,0l0.107-0.035l0,0l0.109-0.036
                                                                        l0.107-0.035l0,0l0.107-0.036l0,0l0.107-0.035l0,0l0.107-0.037l0,0l0.089-0.035l0.018-0.035l0.089-0.036h0.02l0.07-0.036h0.037
                                                                        l0.072-0.035l0.035-0.035l0.072-0.037h0.035l0.072-0.035h0.035l0.055-0.036l0.035-0.035l0.054-0.036h0.053l0.054-0.035
                                                                        l0.035-0.037l0.072-0.035h0.037l0.053-0.035l0.054-0.036l0.035-0.036h0.055l0.052-0.036l0.037-0.035l0.053-0.036l0.054-0.035
                                                                        h0.035l0.072-0.037l0.035-0.035l0.055-0.035l0.017-0.036l0.072-0.036h0.035l0.072-0.07l0,0l0.072-0.037l0.035-0.035l0.072-0.036
                                                                        v-0.036l0.089-0.035l0.018-0.035l0.072-0.037l0,0l0.089-0.07l0,0l0.09-0.072l0.089-0.072l0.089-0.07l0,0l0.072-0.072l0,0
                                                                        l0.07-0.071h0.02l0.07-0.071h0.019l0.07-0.071v-0.036l0.072-0.036l0.018-0.036l0.162-0.179c0.644-0.608,1.181-1.323,1.611-2.11
                                                                        l0.035-0.072l0.144-0.285l0.035-0.036l0.018-0.072l0.018-0.035l0.037-0.071l0.018-0.036l0.035-0.071l0.02-0.036l0.035-0.071
                                                                        v-0.035l0.035-0.072v-0.036l0.089-0.179l0.02-0.035l0.018-0.072l0.017-0.035l0.035-0.072l0,0l0.037-0.107l0,0l0.035-0.107v-0.035
                                                                        l0.035-0.071v-0.036l0.037-0.072v-0.035l0.035-0.107l0,0l0.037-0.107l0,0l0.018-0.107h0.018l0.018-0.107l0.018-0.107l0,0
                                                                        l0.037-0.106l0,0l0.035-0.108v-0.035l0.018-0.071v-0.036l0.02-0.107l0,0l0.035-0.107l0,0v-0.107v-0.035l0.035-0.072v-0.035v-0.072
                                                                        v-0.035l0.037-0.107v-0.035v-0.072v-0.036l0.018-0.071v-0.035l0.018-0.107v-0.036v-0.071v-0.037v-0.07v-0.036l0.018-0.071v-0.072
                                                                        l0.019-0.071v-0.035v-0.071v-0.037v-0.07v-0.072v-0.107l0,0v-0.143v-0.107v-0.107h-0.019v-0.107v-0.035v-0.107h-0.018v-0.107l0,0
                                                                        v-0.107v-0.036l-0.018-0.071v-0.035l-0.018-0.107l0,0v-0.108v-0.036l-0.037-0.214l-0.018-0.071l-0.09-0.465v-0.037l-0.018-0.07
                                                                        l-0.017-0.036v-0.072l-0.018-0.071l-0.055-0.215l-0.018-0.035l-0.018-0.072v-0.035l-0.037-0.072v-0.035l-0.072-0.179v-0.036
                                                                        l-0.035-0.072l-0.035-0.143l-0.037-0.071v-0.036l-0.035-0.072v-0.034l-0.035-0.072v-0.036l-0.037-0.07l-0.018-0.037l-0.035-0.071
                                                                        v-0.035l-0.233-0.501l0,0l-0.035-0.107h-0.019l-0.053-0.107l0,0l-0.037-0.071l0,0l-0.053-0.108h-0.018l-0.037-0.106h-0.018
                                                                        l-0.107-0.179l-0.054-0.107l0,0l-0.035-0.072l-0.035-0.035l-0.288-0.43l-0.018-0.035l-0.035-0.037l-0.037-0.035l-0.017-0.071
                                                                        l-0.035-0.036l-0.286-0.357l-0.037-0.035l-0.035-0.036l-0.035-0.036l-0.037-0.071l-0.035-0.036l-0.037-0.035l-0.249-0.25
                                                                        l-0.144-0.144l-0.251-0.251l0,0c-0.179-0.179-0.393-0.357-0.607-0.501l0,0l-0.072-0.071l0,0l-0.107-0.072l0,0l-0.072-0.07l0,0
                                                                        l-0.107-0.072l0,0l-0.089-0.072l-0.089-0.07l0,0l-0.107-0.036l0,0l-0.09-0.071l0,0l-0.089-0.072l0,0l-0.107-0.035l-0.107-0.071
                                                                        l0,0l-0.09-0.072l0,0l-0.089-0.036l-0.109-0.07l0,0l-0.107-0.036l0,0l-0.107-0.072l0,0l-0.09-0.035h-0.017l-0.09-0.071h-0.018
                                                                        l-0.089-0.037l0,0l-0.089-0.035h-0.018l-0.089-0.071h-0.02l-0.089-0.036h-0.018l-0.089-0.035h-0.018l-0.09-0.035l-0.107-0.072
                                                                        h-0.018l-0.089-0.036h-0.018l-0.089-0.036h-0.02l-0.107-0.035h-0.018l-0.089-0.035h-0.018l-0.107-0.037l0,0l-0.107-0.035l0,0
                                                                        l-0.108-0.035l0,0l-0.107-0.036l0,0h-0.107h-0.018l-0.107-0.036l0,0l-0.107-0.036h-0.02l-0.107-0.036l0,0l-0.107-0.035l0,0h-0.125
                                                                        l0,0l-0.126-0.035l0,0h-0.107l0,0l-0.107-0.036l0,0h-0.125h-0.019l-0.107-0.036l0,0h-0.107l0,0l-0.125-0.035l0,0h-0.126l0,0
                                                                        h-0.107h-0.018h-0.107h-0.018l-0.109-0.036l0,0h-0.107h-0.018h-0.125l0,0h-0.107l0,0h-0.126h-0.018h-0.107l0,0h-0.126l0,0h-0.125
                                                                        l0,0h-0.107h-0.018l-0.126,0.036h-0.107h-0.107h-0.018h-0.126l0,0l-0.107,0.035l0,0h-0.125l0,0l-0.125,0.036h-0.108l-0.125,0.036
                                                                        h-0.107l-0.125,0.035h-0.109l-0.125,0.035l-0.125,0.036l-0.109,0.036l-0.107,0.036h-0.107l0,0l-0.232,0.071l0,0l-0.126,0.035l0,0
                                                                        l-0.107,0.037l0,0l-0.107,0.035l0,0l-0.107,0.035l0,0l-0.107,0.036l-0.109,0.036l-0.107,0.072l0,0l-0.214,0.07l0,0l-0.214,0.072
                                                                        v0.035l-0.109,0.035l0,0c-1.002,0.466-1.895,1.109-2.701,1.897l-0.161,0.144l-0.053,0.07h-0.02l-0.053,0.072l-0.017,0.036
                                                                        l-0.055,0.034l-0.035,0.037l-0.037,0.035l-0.035,0.071l-0.035,0.036l-0.037,0.036l-0.018,0.036l-0.072,0.035v0.035l-0.072,0.072
                                                                        v0.036l-0.07,0.035v0.036l-0.054,0.072h-0.018l-0.055,0.07v0.036l-0.07,0.071l-0.055,0.107l0,0l-0.072,0.072l0,0l-0.053,0.107
                                                                        h-0.019l-0.035,0.07l-0.035,0.036l-0.037,0.072h-0.018l-0.035,0.071l-0.02,0.035l-0.035,0.072l-0.035,0.035l-0.037,0.036
                                                                        l-0.018,0.036l-0.018,0.072l-0.037,0.035l-0.035,0.035l-0.018,0.072l-0.018,0.035l-0.037,0.072l-0.035,0.035l-0.035,0.071l0,0
                                                                        l-0.037,0.108H136l-0.035,0.07l-0.019,0.036l-0.035,0.072v0.035l-0.035,0.071h-0.02l-0.052,0.107l0,0l-0.037,0.107l0,0
                                                                        l-0.035,0.107l0,0l-0.035,0.107h-0.019l-0.035,0.072l-0.018,0.035l-0.018,0.071l-0.019,0.036l-0.018,0.07l-0.018,0.037
                                                                        l-0.035,0.071l-0.037,0.107v0.036l-0.035,0.07v0.036l-0.035,0.072v0.071l-0.02,0.035l-0.018,0.072l-0.018,0.035l-0.018,0.072
                                                                        v0.035l-0.037,0.072v0.035l-0.035,0.072v0.036l-0.035,0.071v0.035l-0.019,0.107l0,0l-0.018,0.107l-0.018,0.107h-0.018v0.107l0,0
                                                                        l-0.037,0.107v0.035l-0.018,0.072v0.035l-0.018,0.072v0.035l-0.018,0.072v0.07l-0.018,0.037v0.07v0.072l-0.019,0.036v0.071
                                                                        l-0.018,0.072v0.035v0.071v0.036l-0.018,0.072v0.035v0.107h-0.018v0.107v0.035v0.071v0.036v0.107l0,0v0.107l0,0l0,0v0.143v0.107
                                                                        l0,0v0.107v0.035v0.108l0,0v0.106l0,0v0.107l0.018,0.036v0.106l0,0l0.018,0.108l0,0v0.107v0.036l0.018,0.07v0.036l0.019,0.108l0,0
                                                                        v0.107l0.018,0.035l0.018,0.071v0.036v0.107h0.018l0.018,0.107v0.035l0.02,0.072l0.018,0.035v0.071v0.036l0.035,0.072v0.035
                                                                        l0.018,0.107v0.036l0.019,0.071v0.035l0.035,0.072v0.035l0.035,0.072v0.035l0.019,0.072l0.018,0.035l0.018,0.071l0.018,0.036
                                                                        l0.018,0.072l0.02,0.07l0.018,0.036l0.018,0.072v0.034l0.035,0.072l0.018,0.036l0.02,0.071l0.018,0.072l0.018,0.035l0.035,0.072
                                                                        v0.035l0.037,0.036v0.071l0.035,0.072l0.018,0.035l0.019,0.035l0.035,0.072v0.035l0.035,0.072l0.02,0.035l0.018,0.072l0.035,0.036
                                                                        l0.037,0.071v0.035l0.035,0.071l0.035,0.036l0.019,0.072l0.018,0.035l0.035,0.072l0.018,0.035l0.037,0.036l0.018,0.035
                                                                        l0.035,0.071l0.018,0.037l0.037,0.07l0.018,0.036l0.035,0.071l0.037,0.035l0.035,0.072l0,0l0.037,0.072l0.035,0.035l0.035,0.072
                                                                        l0.02,0.035l0.052,0.071v0.036l0.055,0.036l0.018,0.036l0.035,0.07l0.037,0.037l0.035,0.07h0.018l0.054,0.072v0.035l0.072,0.072
                                                                        l0,0l0.07,0.071v0.036l0.055,0.07h0.018l0.054,0.108h0.018l0.054,0.071h0.018l0.053,0.072l0.02,0.035l0.053,0.071h0.018
                                                                        l0.072,0.072v0.035l0.072,0.071l0,0l0.072,0.072l0,0l0.07,0.107l0,0l0.072,0.07l0,0l0.089,0.072l0,0l0.072,0.071
                                                                        c0.448,0.466,0.948,0.896,1.485,1.253h0.017l0.269,0.179h0.018l0.269,0.144h0.037l0.072,0.07h0.035l0.054,0.037l0.035,0.035
                                                                        l0.054,0.036h0.053l0.055,0.035l0.035,0.036h0.072l0.035,0.035l0.054,0.037h0.053l0.054,0.035l0.055,0.035h0.035l0.072,0.036
                                                                        l0.035,0.036h0.072l0.035,0.035l0.072,0.035h0.035l0.072,0.037h0.037l0.07,0.035h0.037l0.072,0.036h0.018l0.089,0.035h0.018
                                                                        l0.089,0.036h0.018l0.089,0.035h0.018l0.09,0.037h0.017l0.109,0.035l0,0l0.107,0.035l0,0l0.107,0.036h0.018l0.089,0.036h0.018
                                                                        h0.089l0.018,0.036h0.089h0.02l0.107,0.035h0.018h0.089l0.035,0.036h0.072h0.037l0.07,0.035h0.054h0.053h0.054l0.055,0.037h0.07
                                                                        h0.054l0.054,0.035h0.07h0.055h0.054h0.07l0.037,0.035h0.07h0.037h0.107h0.018h0.089h0.037l0.07,0.036h0.037h0.107l0,0H144.104z
                                                                        M218.396,234.015l0.018,0.536l-0.018,0.537v0.035v0.037l0.018,0.07v0.036v0.107v0.036v0.071v0.035l0.02,0.072l0.018,0.036v0.07
                                                                        v0.072v0.071l0.018,0.036l0.018,0.072v0.035v0.071l0.017,0.071l0.02,0.035v0.072v0.036l0.035,0.071v0.036v0.071l0.035,0.035v0.108
                                                                        l0,0l0.037,0.106v0.037l0.018,0.07l0.018,0.036l0.018,0.071v0.037l0.02,0.07v0.036l0.035,0.106l0,0l0.035,0.108l0.037,0.107l0,0
                                                                        l0.035,0.106l0,0l0.037,0.108l0,0l0.035,0.106l0,0l0.035,0.107l0,0l0.037,0.107l0,0l0.035,0.107h0.018l0.018,0.071l0.02,0.036
                                                                        l0.035,0.072l0.018,0.035l0.018,0.071l0.02,0.036l0.035,0.07v0.037l0.035,0.071l0.037,0.036l0.018,0.071l0.018,0.036l0.037,0.07
                                                                        l0.018,0.037l0.018,0.035l0.035,0.072l0.02,0.035l0.035,0.072l0.018,0.035l0.054,0.071l0.035,0.071l0.018,0.037l0.037,0.07
                                                                        l0.018,0.036l0.053,0.071v0.035l0.037,0.072h0.018l0.055,0.071v0.036l0.07,0.072l0,0l0.054,0.107l0.054,0.071v0.035l0.07,0.071
                                                                        l0,0l0.054,0.107h0.018l0.037,0.072h0.035l0.035,0.07l0.037,0.037l0.035,0.071h0.018l0.054,0.071l0.018,0.035l0.055,0.072
                                                                        l0.018,0.035l0.035,0.036l0.037,0.036l0.053,0.036l0.018,0.071l0.037,0.035l0.035,0.037l0.037,0.035l0.035,0.035l0.035,0.036
                                                                        l0.055,0.071l0.035,0.035l0.037,0.037l0.035,0.035l0.035,0.036l0.179,0.179c0.502,0.502,1.057,0.93,1.646,1.323h0.037l0.107,0.072
                                                                        l0.053,0.036l0.037,0.035l0.053,0.035h0.037l0.072,0.036l0.035,0.036l0.072,0.036h0.035l0.054,0.035l0.142,0.071l0.055,0.037
                                                                        l0.053,0.035l0.054,0.035h0.035l0.072,0.036l0.037,0.036h0.07l0.037,0.035l0.072,0.037h0.035l0.055,0.035l0.052,0.035h0.055
                                                                        l0.053,0.036l0.107,0.036l0.054,0.035h0.055l0.052,0.035h0.055l0.053,0.037h0.054l0.054,0.035h0.07l0.037,0.036l0.072,0.035h0.035
                                                                        h0.072l0.035,0.036h0.089l0.144,0.072h0.07l0.109,0.035h0.035l0.072,0.035h0.035l0.089,0.036h0.018h0.107h0.02l0.089,0.036H226
                                                                        l0.072,0.036h0.035h0.089h0.018l0.107,0.035l0,0h0.108h0.035l0.089,0.036h0.018h0.09h0.018l0.108,0.035h0.018h0.09h0.035h0.089
                                                                        h0.018l0.107,0.037l0,0h0.126l0,0h0.125l0,0h0.107l0,0h0.126l0,0h0.125l0,0h0.125h0.109h0.018h0.107l0,0h0.107h0.037h0.107l0,0
                                                                        h0.107h0.018h0.089h0.035l0.09-0.037h0.019h0.107h0.018h0.09h0.035l0.09-0.035h0.018h0.108h0.018l0.09-0.036h0.035h0.089h0.018
                                                                        l0.089-0.035h0.037h0.089h0.035l0.072-0.036h0.035l0.072-0.036h0.035h0.089h0.037l0.072-0.036h0.053l0.072-0.035h0.035
                                                                        l0.179-0.035l0.037-0.036l0.125-0.036h0.055h0.053l0.126-0.036l0.035-0.035l0.072-0.036l0.16-0.035l0.054-0.037l0.107-0.035h0.037
                                                                        l0.07-0.035l0.037-0.036l0.179-0.036l0.035-0.035l0.286-0.107l0.037-0.036c1-0.465,1.877-1.108,2.646-1.86l0.179-0.179
                                                                        l0.037-0.036l0.035-0.035l0.037-0.037l0.035-0.035l0.053-0.071l0.037-0.036l0.035-0.035l0.037-0.035l0.035-0.037l0.035-0.035
                                                                        l0.019-0.071l0.053-0.036l0.037-0.036l0.035-0.036l0.018-0.035l0.055-0.072l0.018-0.035l0.054-0.071h0.018l0.035-0.071
                                                                        l0.037-0.037l0.035-0.07h0.018l0.054-0.072h0.018l0.053-0.107l0,0l0.072-0.071v-0.035l0.054-0.071l0.053-0.107l0,0l0.072-0.072
                                                                        v-0.036l0.054-0.071h0.018l0.035-0.072v-0.035l0.055-0.071l0.018-0.036l0.037-0.07l0.018-0.037l0.035-0.071l0.054-0.071
                                                                        l0.018-0.035l0.035-0.072l0.018-0.035l0.037-0.072l0.018-0.035l0.017-0.037l0.037-0.07l0.018-0.036l0.018-0.071l0.035-0.036
                                                                        l0.037-0.071v-0.037l0.035-0.07l0.018-0.036l0.019-0.071l0.018-0.035l0.035-0.072l0.018-0.036l0.02-0.071h0.018l0.035-0.107l0,0
                                                                        l0.037-0.107l0,0l0.035-0.107l0,0l0.035-0.106l0,0l0.037-0.108l0,0l0.035-0.106l0,0l0.035-0.107l0.037-0.108l0,0l0.035-0.106
                                                                        v-0.036l0.018-0.07v-0.037l0.02-0.071l0.018-0.036l0.018-0.07v-0.037l0.035-0.106l0,0l0.019-0.108v-0.035l0.018-0.071v-0.036
                                                                        l0.035-0.071v-0.036v-0.072l0.018-0.035l0.019-0.071v-0.071v-0.035l0.018-0.072l0.018-0.036v-0.071v-0.072v-0.07l0.018-0.036
                                                                        l0.018-0.072v-0.035v-0.071v-0.036v-0.107v-0.036l0.02-0.07v-0.037l0.018-0.107l0,0v-0.107l0,0v-0.106v-0.036v-0.107l0,0v-0.107
                                                                        v-0.143v-0.107v-0.143v-0.108l-0.018-0.106l-0.02-0.107v-0.143v-0.108l-0.035-0.144v-0.106l-0.018-0.107l-0.018-0.144
                                                                        l-0.019-0.107l-0.018-0.107l-0.035-0.107l-0.018-0.143l-0.019-0.107l-0.035-0.107l-0.018-0.107l-0.037-0.143l-0.018-0.107
                                                                        l-0.035-0.107l-0.037-0.107l-0.035-0.107l-0.035-0.107l-0.037-0.107l-0.035-0.107l-0.035-0.106l-0.037-0.144l-0.053-0.072v-0.035
                                                                        l-0.055-0.071l-0.035-0.144l-0.037-0.071l-0.053-0.106l-0.054-0.108l-0.035-0.106l-0.072-0.108l-0.037-0.107l0,0l-0.053-0.106
                                                                        l-0.054-0.108l-0.07-0.071l-0.037-0.107l0,0l-0.072-0.107l0,0L236,229.613l0,0l-0.054-0.106l0,0l-0.072-0.072l0,0l-0.125-0.215
                                                                        l-0.055-0.071l-0.07-0.107l-0.072-0.071l0,0l-0.144-0.215l-0.125-0.179l0,0l-0.089-0.071l0,0l-0.072-0.072v-0.036l-0.214-0.25l0,0
                                                                        l-0.072-0.072l0,0l-0.072-0.07l0,0l-0.179-0.18l-0.07-0.071l0,0l-0.072-0.071l-0.037-0.035l-0.052-0.072
                                                                        c-0.555-0.5-1.181-0.965-1.843-1.323l0,0l-0.393-0.216l0,0L232.01,226v-0.035l-0.089-0.036h-0.018l-0.321-0.144l-0.072-0.035
                                                                        h-0.035l-0.054-0.036h-0.055l-0.249-0.106l-0.072-0.037h-0.037l-0.07-0.035h-0.037l-0.07-0.036h-0.037l-0.089-0.035h-0.018
                                                                        l-0.107-0.036l-0.107-0.035l0,0h-0.109l0,0l-0.017-0.037l-0.197-0.035l0,0l-0.107-0.035h-0.018l-0.089-0.036h-0.037h-0.07h-0.037
                                                                        l-0.231-0.036l-0.072-0.036h-0.054h-0.07l-0.037-0.035h-0.072h-0.053h-0.054h-0.072l-0.035-0.036h-0.072h-0.052h-0.072h-0.035
                                                                        l-0.089-0.035h-0.037h-0.072h-0.035h-0.089h-0.018h-0.107h-0.02h-0.087h-0.037h-0.107v-0.037h-0.107l0,0h-0.018h-0.109h-0.125l0,0
                                                                        h-0.107l-0.018,0.037h-0.107h-0.02h-0.107l0,0h-0.107h-0.018h-0.107h-0.019h-0.107h-0.018l-0.089,0.035h-0.035h-0.072h-0.035
                                                                        h-0.108l-0.018,0.036h-0.09h-0.018h-0.089h-0.035l-0.089,0.035h-0.019h-0.107l-0.018,0.036h-0.089h-0.035L226,225.249h-0.035
                                                                        h-0.072h-0.037l-0.089,0.036h-0.035l-0.072,0.035h-0.053l-0.072,0.035H225.5h-0.072l-0.037,0.037h-0.07l-0.037,0.035h-0.072
                                                                        h-0.035l-0.072,0.036h-0.053l-0.054,0.035h-0.053l-0.054,0.036h-0.055l-0.053,0.035h-0.072l-0.054,0.037h-0.053l-0.055,0.035
                                                                        l-0.052,0.035h-0.037l-0.072,0.036h-0.053l-0.054,0.036l-0.035,0.035h-0.072l-0.035,0.035l-0.072,0.037h-0.035l-0.072,0.035
                                                                        h-0.037l-0.07,0.036l-0.037,0.036L223.745,226h-0.035l-0.054,0.035l-0.053,0.037l-0.055,0.035h-0.035l-0.055,0.035l-0.035,0.036
                                                                        h-0.072l-0.035,0.036l-0.072,0.036l-0.035,0.036l-0.072,0.035h-0.037l-0.053,0.035l-0.018,0.037l-0.072,0.035h-0.035l-0.072,0.071
                                                                        h-0.037l-0.07,0.036l-0.02,0.035l-0.053,0.037h-0.037l-0.07,0.07h-0.037l-0.072,0.072l0,0l-0.07,0.035l-0.037,0.035l-0.07,0.037
                                                                        v0.035l-0.072,0.036l-0.037,0.035l-0.07,0.036h-0.02l-0.07,0.072h-0.019l-0.07,0.07l0,0l-0.089,0.072h-0.02l-0.07,0.071l0,0
                                                                        l-0.072,0.071h-0.018l-0.072,0.071h-0.018l-0.072,0.072l0,0l-0.09,0.071v0.035l-0.089,0.072l0,0l-0.072,0.071l0,0l-0.072,0.071
                                                                        c-0.393,0.358-0.733,0.752-1.037,1.217l0,0l-0.43,0.645v0.035l-0.214,0.322l-0.018,0.072l-0.107,0.214l-0.018,0.036l-0.037,0.035
                                                                        v0.037l-0.035,0.07l-0.037,0.036l-0.035,0.071v0.037l-0.035,0.07v0.036l-0.037,0.071l-0.018,0.035l-0.035,0.072v0.036
                                                                        l-0.037,0.071h-0.018l-0.035,0.107l0,0l-0.037,0.107l0,0l-0.035,0.107l-0.035,0.106l0,0l-0.037,0.108v0.035l-0.035,0.072v0.035
                                                                        l0,0l-0.072,0.18v0.035l-0.035,0.071v0.036l-0.02,0.07l-0.018,0.037v0.107h-0.018l-0.018,0.107v0.035l-0.037,0.071v0.036v0.072
                                                                        l-0.035,0.035v0.072v0.035l-0.035,0.071v0.036v0.035v0.037l-0.02,0.07l-0.017,0.036v0.071v0.072l-0.018,0.071l-0.018,0.035v0.072
                                                                        v0.036v0.07l-0.018,0.036l-0.02,0.107v0.036v0.072v0.035v0.071v0.036l-0.018,0.107l0,0V234.015z
                                                                        M144.086,244.033h0.411h0.395
                                                                        c2.164-0.108,4.15-0.932,5.706-2.22c0.16-0.25,0.375-0.501,0.59-0.751c0.214-0.215,0.43-0.357,0.681-0.537
                                                                        c1.269-1.574,2.057-3.576,2.111-5.76v-0.107l0,0v-0.107v-0.107v-0.035c-0.037-2.541-1.11-4.901-2.792-6.584l0,0
                                                                        c-1.628-1.609-3.864-2.683-6.315-2.754h-0.375h-0.393c-2.362,0.071-4.525,1.037-6.154,2.539l-0.035,0.037l-0.072,0.106
                                                                        l-0.089,0.071l0,0c-1.591,1.61-2.63,3.793-2.772,6.19l0.035,0.536l-0.035,0.501c0.107,2.075,0.895,3.972,2.074,5.474
                                                                        c0.251,0.144,0.502,0.322,0.698,0.537c0.233,0.25,0.448,0.501,0.626,0.787C139.937,243.138,141.921,243.961,144.086,244.033z
                                                                        M144.927,308.469h-0.43h-0.428c-2.469,0.071-4.687,1.145-6.333,2.754l-0.072,0.072l0,0l-0.072,0.072l-0.072,0.106l-0.07,0.071
                                                                        l0,0l-0.09,0.072l0,0l-0.072,0.106l0,0l-0.072,0.072l0,0l-0.072,0.071l-0.018,0.035l-0.053,0.072h-0.019l-0.053,0.071
                                                                        l-0.018,0.036l-0.037,0.072h-0.018l-0.055,0.07l-0.018,0.036l-0.052,0.071h-0.018l-0.055,0.072v0.035l-0.072,0.071v0.036
                                                                        l-0.053,0.072h-0.019l-0.035,0.07l-0.035,0.036l-0.037,0.072l-0.018,0.036l-0.054,0.035v0.035l-0.035,0.072l-0.035,0.035
                                                                        l-0.037,0.072l-0.018,0.035l-0.035,0.072h-0.019l-0.035,0.071l-0.018,0.036l-0.055,0.07v0.037l-0.035,0.071l-0.035,0.035
                                                                        l-0.019,0.072l-0.018,0.036l-0.035,0.07l-0.018,0.036l-0.037,0.036l-0.018,0.071l-0.035,0.036v0.035l-0.037,0.071l-0.035,0.072
                                                                        l-0.018,0.036l-0.02,0.035l-0.035,0.072v0.035l-0.035,0.071l-0.019,0.036l-0.018,0.072l-0.035,0.035v0.071l-0.037,0.036
                                                                        l-0.018,0.071l-0.018,0.071l-0.035,0.036v0.071l-0.037,0.036v0.071l-0.035,0.035v0.072l-0.035,0.036v0.071l-0.02,0.035
                                                                        l-0.018,0.072l-0.018,0.035l-0.018,0.072l-0.018,0.035l-0.019,0.072v0.035l-0.035,0.107l0,0l-0.035,0.107v0.036l-0.019,0.071
                                                                        v0.036l-0.018,0.071l-0.018,0.035l-0.018,0.072v0.036v0.106h-0.018l-0.02,0.107v0.036l-0.018,0.071l-0.018,0.035v0.072v0.036
                                                                        l-0.018,0.107h-0.018v0.106v0.036l-0.019,0.072v0.035l-0.018,0.108l0,0v0.106l0,0l-0.018,0.106v0.037v0.107h-0.018v0.107l0,0
                                                                        v0.143l0,0v0.107l0,0v0.107v0.035v0.107v0.107l0,0v0.107v0.035v0.108l0,0v0.106v0.037v0.106h0.018v0.106l0,0l0.018,0.108v0.035
                                                                        v0.108l0,0l0.018,0.106l0,0l0.019,0.108v0.035v0.107h0.018l0.018,0.107l0,0v0.107l0.018,0.035l0.055,0.215v0.107l0,0l0.018,0.107
                                                                        l0.018,0.035l0.018,0.072v0.036l0.019,0.071v0.035l0.179,0.645l0.035,0.036v0.071l0.035,0.035l0.072,0.215l0.018,0.072
                                                                        c0.484,1.287,1.235,2.432,2.183,3.398c0.877,0.859,1.95,1.574,3.113,2.074h0.018l0.09,0.037h0.018l0.089,0.035h0.018l0.109,0.036
                                                                        l0,0l0.214,0.071l0,0l0.107,0.036l0,0l0.107,0.071l0,0h0.107h0.019l0.09,0.035h0.018l0.107,0.036h0.018l0.214,0.072l0,0
                                                                        l0.109,0.035l0,0h0.125l0,0l0.107,0.036h0.018l0.109,0.035l0,0l0.231,0.037l0,0h0.125l0,0l0.108,0.035l0,0h0.107h0.018
                                                                        l0.107,0.035h0.018h0.107l0,0h0.109h0.035l0.107,0.036l0,0h0.107l0,0h0.126h0.018h0.107l0,0h0.125l0,0h0.126l0,0h0.107h0.018
                                                                        h0.126h0.107h0.107h0.018h0.107h0.018h0.109h0.018h0.089h0.035l0.089-0.036h0.018h0.107h0.019h0.089h0.035l0.072-0.035h0.035h0.09
                                                                        h0.037h0.07l0.054-0.035h0.072h0.035h0.072l0.053-0.037h0.019l0.142-0.035h0.072h0.054l0.053-0.036h0.054h0.072l0.053-0.035h0.072
                                                                        h0.035l0.072-0.036h0.035l0.072-0.036h0.037l0.07-0.036h0.037h0.072l0.035-0.035h0.09l0.017-0.035l0.09-0.036h0.018l0.107-0.036
                                                                        l0,0l0.109-0.036l0,0l0.107-0.035l0.107-0.036l0.107-0.035l0,0l0.107-0.037l0,0l0.089-0.035h0.018l0.109-0.071l0.07-0.036h0.037
                                                                        l0.072-0.035h0.035l0.072-0.035l0.035-0.037l0.072-0.035h0.017l0.072-0.036h0.035l0.054-0.035l0.035-0.036l0.072-0.035
                                                                        l0.035-0.037h0.072l0.037-0.035l0.107-0.071h0.035l0.055-0.036l0.052-0.036l0.037-0.035h0.053l0.054-0.036l0.035-0.035
                                                                        l0.072-0.036l0.035-0.036l0.037-0.035h0.035l0.072-0.072h0.035l0.054-0.035l0.018-0.035l0.072-0.037l0.035-0.035l0.072-0.036l0,0
                                                                        l0.072-0.071h0.035l0.072-0.072l0,0l0.179-0.143l0,0l0.072-0.072h0.018l0.089-0.07l0,0l0.072-0.072l0,0l0.07-0.071h0.02
                                                                        l0.07-0.071h0.019l0.07-0.071l0,0l0.072-0.072h0.018l0.02-0.036l0.142-0.143c0.286-0.322,0.572-0.608,0.823-0.93v-0.037
                                                                        l0.072-0.07l0,0l0.072-0.072v-0.035l0.07-0.072l0,0l0.054-0.107h0.018l0.037-0.07l0.017-0.037l0.053-0.071l0,0l0.072-0.107l0,0
                                                                        l0.054-0.071l0.018-0.035l0.035-0.072h0.019l0.053-0.107l0,0l0.055-0.106l0,0l0.052-0.108l0,0l0.072-0.07l0,0l0.035-0.108l0,0
                                                                        l0.054-0.106h0.018l0.037-0.107l0,0l0.053-0.108l0,0l0.055-0.106l0,0l0.035-0.107h0.017l0.037-0.107h0.018l0.035-0.107l0,0
                                                                        l0.037-0.107l0,0l0.035-0.107h0.018l0.037-0.107l0,0l0.035-0.107h0.018l0.018-0.107h0.02l0.035-0.107l0,0l0.035-0.106l0,0
                                                                        l0.037-0.108l0,0l0.035-0.106l0,0l0.018-0.107h0.019l0.018-0.108l0,0l0.018-0.143h0.018l0.018-0.107l0,0l0.037-0.106l0,0
                                                                        l0.017-0.108h0.018v-0.143h0.018l0.02-0.107l0,0l0.018-0.107h0.018v-0.107l0,0l0.035-0.143l0,0v-0.107l0,0l0.019-0.107h0.018
                                                                        v-0.144l0,0l0.018-0.106l0,0l0.018-0.108l0,0v-0.144l0,0v-0.106l0,0l0.018-0.106l0,0v-0.144l0.019-0.108v-0.143v-0.107l0,0v-0.107
                                                                        l0,0v-0.107v-0.035l0,0v-0.107l-0.019-0.107l0,0v-0.107l-0.018-0.25l0,0l-0.035-0.215v-0.036l-0.018-0.214l0,0l-0.019-0.108l0,0
                                                                        l-0.018-0.106l0,0l-0.018-0.107v-0.036l-0.018-0.071v-0.035l-0.018-0.108l0,0l-0.037-0.106l0,0l-0.018-0.107l0,0l-0.017-0.108l0,0
                                                                        l-0.037-0.106l0,0l-0.018-0.107l-0.018-0.036l-0.018-0.071v-0.035l-0.018-0.072l-0.019-0.036l-0.018-0.071v-0.035l-0.035-0.072
                                                                        v-0.035l-0.037-0.072v-0.035l-0.035-0.072v-0.035l-0.035-0.072v-0.035l-0.037-0.072l-0.018-0.035l-0.018-0.071l-0.017-0.036
                                                                        l-0.018-0.072l-0.02-0.035l-0.035-0.072l-0.018-0.035l-0.018-0.036l-0.019-0.071l-0.035-0.035v-0.037l-0.035-0.07l-0.02-0.036
                                                                        l-0.017-0.072l-0.035-0.034l-0.018-0.072l-0.02-0.036l-0.035-0.071l-0.018-0.036l-0.018-0.036l-0.037-0.07l-0.035-0.036v-0.036
                                                                        l-0.037-0.071l-0.035-0.036l-0.018-0.072l-0.018-0.035l-0.037-0.035l-0.035-0.072l-0.018-0.035l-0.018-0.035l-0.037-0.072
                                                                        l-0.035-0.036l-0.037-0.036l-0.018-0.07l-0.018-0.037l-0.035-0.035l-0.037-0.071l-0.035-0.036l-0.037-0.036l-0.018-0.036
                                                                        l-0.018-0.07l-0.035-0.036l-0.037-0.036l-0.035-0.071l-0.037-0.036l-0.035-0.035l-0.018-0.037l-0.037-0.07l-0.017-0.036
                                                                        l-0.053-0.036l-0.02-0.035l-0.035-0.072l-0.035-0.035l-0.037-0.036l-0.035-0.035l-0.035-0.071h-0.037l-0.035-0.072l-0.037-0.035
                                                                        l-0.035-0.036l-0.035-0.036l-0.037-0.071h-0.035l-0.037-0.071l-0.035-0.036l-0.035-0.036l-0.037-0.035l-0.035-0.036l-0.037-0.036
                                                                        l0,0l-0.214-0.215c-0.393-0.393-0.841-0.751-1.288-1.072h-0.018l-0.089-0.072l0,0l-0.393-0.25l0,0l-0.286-0.18l0,0l-0.109-0.07
                                                                        l-0.214-0.072v-0.036l-0.09-0.035l0,0l-0.107-0.036l-0.107-0.072l-0.089-0.035l-0.107-0.035l-0.109-0.072l-0.107-0.035l0,0
                                                                        l-0.107-0.035l0,0l-0.107-0.037l-0.107-0.071l-0.107-0.036l-0.126-0.035l-0.107-0.035l-0.125-0.037l0,0l-0.107-0.035h-0.108
                                                                        l-0.107-0.035l-0.107-0.036l-0.125-0.036l-0.107-0.036l-0.126-0.036h-0.107l-0.125-0.035h-0.126l-0.107-0.035l-0.107-0.036h-0.125
                                                                        h-0.126l-0.107-0.036h-0.125l-0.126-0.035h-0.107h-0.125h-0.126H144.927z
                                                                        M193.997,186c-1.181-1.181-2.809-1.896-4.615-1.896
                                                                        c-1.808,0-3.436,0.715-4.617,1.896c-1.179,1.182-1.913,2.827-1.913,4.615c0,1.646,0.607,3.148,1.591,4.294l0,0l0.321,0.322
                                                                        c1.181,1.181,2.809,1.932,4.617,1.932c1.806,0,3.434-0.751,4.615-1.932c1.181-1.146,1.915-2.791,1.915-4.616
                                                                        c0-1.717-0.681-3.291-1.753-4.436L193.997,186z
                                                                        M202.493,182.709c1.395,2.325,2.218,5.045,2.218,7.906
                                                                        c0,4.223-1.734,8.051-4.489,10.841c-2.774,2.791-6.602,4.509-10.841,4.509c-2.881,0-5.6-0.824-7.889-2.219l-21.361,21.359
                                                                        c0.949,1.539,1.646,3.256,2.111,5.045h47.872v-0.035l0.037-0.144l0.035-0.071l0.035-0.144l0.02-0.036l0.035-0.179l0.018-0.035
                                                                        l0.037-0.179l0.035-0.036l0.035-0.179l0.018-0.037l0.054-0.178v-0.035l0.035-0.037l0.109-0.321v-0.036l0,0l0.07-0.215h0.02
                                                                        l0.053-0.18l0.018-0.034l0.089-0.179l0.072-0.215l0,0l0.072-0.18v-0.036l0.107-0.178v-0.036l0.072-0.143l0.018-0.037l0.072-0.178
                                                                        l0.018-0.037l0.072-0.143l0.018-0.035l0.089-0.18l0.018-0.035l0.072-0.143l0.09-0.18l0.035-0.072l0.072-0.143l0.035-0.035
                                                                        l0.072-0.144l0.037-0.071l0.07-0.107l0.037-0.071l0.072-0.108l0.035-0.106l0.072-0.107l0.035-0.071l0.072-0.107l0.055-0.072
                                                                        l0.053-0.107l0.072-0.071l0.053-0.106l0.054-0.072l0.072-0.107l0.052-0.107l0.055-0.071l0.072-0.107l0.053-0.036l0.126-0.179
                                                                        l0.07-0.144l0.055-0.035l0.089-0.143l0.035-0.037l0.089-0.143l0.055-0.035l0.089-0.107l0.018-0.036l0.107-0.144l0.037-0.035
                                                                        l0.107-0.144l0.035-0.035l0.251-0.286l0.017-0.035l0.126-0.145h0.018l0.125-0.178l0,0l0.162-0.145l0,0l0.142-0.143v-0.035
                                                                        l0.162-0.145l0.144-0.178c0.769-0.752,1.609-1.467,2.522-2.076l0.072-0.07l0.769-0.501l0.072-0.036l0.323-0.215l0.07-0.035
                                                                        l0.072-0.036l0.107-0.071h0.037l0.125-0.072l0.055-0.035l0.07-0.036l0.108-0.071h0.035l0.144-0.072h0.018l0.125-0.07l0.072-0.036
                                                                        l0.072-0.036l0.125-0.07h0.018l0.126-0.072l0.052-0.036l0.09-0.036l0.125-0.035h0.019l0.142-0.072l0.037-0.035l0.107-0.037
                                                                        l0.107-0.035l0.035-0.035l0.144-0.036l0.018-0.036l0.125-0.035l0.089-0.035l0.055-0.037l0.161-0.035l0,0l0.142-0.071h0.072
                                                                        l0.072-0.036l0.125-0.035l0.018-0.037l0.144-0.035l0.054-0.035h0.09l0.125-0.072h0.035l0.161-0.036l0,0l0.144-0.071h0.089
                                                                        l0.053-0.035l0.144-0.036h0.018l0.142-0.035l0.072-0.036h0.089l0.144-0.036v-0.036l0.162-0.035v-47.871l-0.162-0.035l0,0
                                                                        l-0.144-0.037l-0.072-0.035h-0.07l-0.144-0.035h-0.018l-0.162-0.072h-0.035l-0.107-0.035l-0.109-0.036h-0.035l-0.142-0.071h-0.037
                                                                        l-0.125-0.037l-0.09-0.034h-0.054l-0.162-0.072l0,0l-0.142-0.036l-0.072-0.035l-0.072-0.035l-0.125-0.037h-0.018l-0.144-0.071
                                                                        h-0.053l-0.089-0.071l-0.109-0.035h-0.035l-0.142-0.072h-0.037l-0.107-0.071l-0.107-0.036h-0.037l-0.142-0.07l0,0l-0.144-0.072
                                                                        l-0.07-0.036l-0.072-0.035l-0.144-0.036l0,0l-0.142-0.072l-0.037-0.035l-0.089-0.035l-0.125-0.072h-0.018l-0.126-0.072
                                                                        l-0.035-0.035l-0.107-0.035l-0.107-0.072h-0.037l-0.125-0.107h-0.018l-0.126-0.072l-0.07-0.035l-0.055-0.035l-0.144-0.072l0,0
                                                                        l-0.125-0.07l-0.055-0.037L202.493,182.709z
                                                                        M189.382,175.303c2.379,0,4.632,0.537,6.636,1.503l16.207-16.208l-0.018-0.035
                                                                        l-0.089-0.144l0,0l-0.072-0.144l-0.035-0.035l-0.037-0.072l-0.07-0.143l0,0l-0.072-0.143l-0.037-0.036l-0.053-0.106l-0.055-0.108
                                                                        h-0.018l-0.072-0.144l-0.018-0.035l-0.053-0.107l-0.037-0.107l-0.035-0.035l-0.054-0.144h-0.018l-0.055-0.144l-0.035-0.071
                                                                        l-0.035-0.071l-0.055-0.107l-0.018-0.035l-0.054-0.145l-0.018-0.035l-0.055-0.107l-0.052-0.107v-0.035l-0.072-0.144v-0.036
                                                                        l-0.035-0.107l-0.037-0.071l-0.035-0.071l-0.054-0.143h-0.018l-0.035-0.143l-0.037-0.072l-0.018-0.071l-0.052-0.144l0,0
                                                                        l-0.055-0.144l-0.018-0.071l-0.037-0.107l-0.035-0.107v-0.035l-0.054-0.144l-0.018-0.036l-0.035-0.107l-0.035-0.106v-0.036
                                                                        l-0.055-0.143v-0.036l-0.035-0.144l-0.02-0.072l-0.035-0.07l-0.035-0.143l0,0l-0.037-0.144h-47.872l-0.035,0.144h-0.017
                                                                        l-0.037,0.143l-0.018,0.07l-0.018,0.108l-0.054,0.144l0,0l-0.035,0.143l-0.018,0.072l-0.018,0.07l-0.037,0.144h-0.018
                                                                        l-0.053,0.179v0.036l-0.037,0.106l-0.035,0.108l-0.037,0.035l-0.035,0.144v0.035l-0.054,0.108l-0.018,0.071l-0.035,0.072
                                                                        l-0.055,0.143l-0.052,0.143l-0.037,0.071l-0.035,0.071l-0.037,0.144l-0.018,0.036l-0.053,0.143l-0.019,0.036l-0.053,0.107
                                                                        l-0.035,0.071l-0.02,0.072l-0.052,0.143h-0.018l-0.055,0.143l-0.035,0.072l-0.037,0.072l-0.072,0.106l-0.053,0.144l-0.037,0.071
                                                                        l-0.053,0.071l-0.037,0.107l-0.018,0.035l-0.072,0.144l-0.018,0.036l-0.053,0.107l-0.054,0.107l-0.035,0.035l-0.072,0.107v0.036
                                                                        l-0.072,0.106l-0.035,0.072l-0.037,0.071l-0.07,0.144l-0.089,0.107l-0.037,0.071l-0.053,0.071l-0.054,0.107l-0.018,0.036
                                                                        l-0.072,0.106l-0.035,0.037l-0.055,0.106l-0.052,0.108l-0.037,0.035l-0.072,0.107l-0.035,0.036l-0.072,0.106l-0.035,0.071
                                                                        l-0.037,0.036l-0.107,0.143l-0.07,0.108l-0.037,0.071l-0.072,0.072l-0.07,0.106l0,0l-0.109,0.144l-0.018,0.035l-0.072,0.072
                                                                        l-0.07,0.071l-0.02,0.036l-0.107,0.143l0,0l-0.089,0.107l-0.053,0.072l-0.037,0.035l-0.107,0.143l-0.107,0.107l-0.035,0.036
                                                                        l-0.072,0.071l-0.072,0.107h-0.018l-0.09,0.144l-0.037,0.036l-0.07,0.07l-0.072,0.072l-0.035,0.036l-0.109,0.106l-0.018,0.037
                                                                        l-0.089,0.07l-0.072,0.072l-0.035,0.035l-0.107,0.107c-1.467,1.467-3.148,2.647-5.01,3.578h-0.053l-0.161,0.071l-0.035,0.036
                                                                        l-0.358,0.143h-0.037l-0.393,0.18l0,0l-0.196,0.071l-0.197,0.106l0,0l-0.412,0.144h-0.018l-0.358,0.106l-0.035,0.037h-0.037
                                                                        l-0.177,0.035l-0.037,0.036l-0.162,0.036l-0.052,0.035l-0.144,0.036l-0.072,0.036l-0.143,0.035h-0.072l-0.142,0.035l-0.072,0.037
                                                                        l-0.144,0.035v47.906c1.79,0.43,3.471,1.146,5.01,2.075l21.645-21.646c-0.965-2.003-1.502-4.221-1.502-6.619
                                                                        c0-4.221,1.718-8.05,4.508-10.805C181.313,177.021,185.142,175.303,189.382,175.303z
                                                                        M104.176,186
                                                                        c-1.163-1.181-2.809-1.896-4.615-1.896c-1.79,0-3.436,0.715-4.617,1.896c-1.181,1.182-1.896,2.827-1.896,4.615
                                                                        c0,1.825,0.714,3.471,1.896,4.616c1.181,1.181,2.827,1.932,4.617,1.932c1.806,0,3.452-0.751,4.615-1.932l0.179-0.144l0.053-0.072
                                                                        l0.018-0.035h0.037l0.035-0.071c1.002-1.146,1.611-2.647,1.611-4.294c0-1.717-0.681-3.291-1.753-4.436L104.176,186z
                                                                        M107.468,203.746c-2.309,1.395-5.01,2.219-7.907,2.219c-4.222,0-8.051-1.718-10.823-4.509c-2.772-2.755-4.491-6.583-4.491-10.841
                                                                        c0-2.861,0.806-5.581,2.201-7.906l-15.921-15.922l-0.037,0.037h-0.018l-0.107,0.07l-0.089,0.036l-0.054,0.036l-0.125,0.07H70.08
                                                                        l-0.126,0.072l-0.053,0.036l-0.072,0.071l-0.107,0.035l-0.035,0.037l-0.144,0.07l0,0l-0.144,0.072l-0.07,0.036l-0.072,0.036
                                                                        l-0.107,0.035h-0.018l-0.144,0.071l-0.035,0.036l-0.089,0.036l-0.109,0.035l-0.035,0.036l-0.144,0.072l0,0l-0.142,0.07h-0.072
                                                                        l-0.072,0.036l-0.107,0.071h-0.035l-0.144,0.072h-0.035l-0.107,0.035l-0.089,0.036l-0.055,0.035l-0.142,0.071l0,0l-0.161,0.037
                                                                        l-0.055,0.035l-0.089,0.035l-0.107,0.036h-0.035l-0.162,0.072l0,0l-0.144,0.034l-0.07,0.037h-0.072l-0.144,0.035v0.036
                                                                        l-0.16,0.036H66.34l-0.125,0.035l-0.089,0.036l-0.055,0.036l-0.142,0.035l0,0l-0.144,0.035h-0.072l-0.107,0.037l-0.107,0.035
                                                                        h-0.035v47.871l0.142,0.035l0.018,0.036l0.126,0.036h0.107l0.035,0.036l0.144,0.035h0.035l0.126,0.036l0.089,0.035h0.053
                                                                        l0.144,0.037l0.018,0.034l0.125,0.036h0.089l0.055,0.036l0.142,0.036h0.019l0.107,0.07h0.089l0.053,0.037l0.144,0.035l0.018,0.036
                                                                        l0.125,0.035l0.09,0.036h0.054l0.144,0.072l0,0l0.142,0.07h0.072l0.053,0.036l0.144,0.071h0.018l0.107,0.035l0.072,0.037
                                                                        l0.072,0.035l0.142,0.072l0,0l0.126,0.035l0.09,0.036l0.053,0.036l0.126,0.072l0,0l0.142,0.07l0.072,0.036l0.054,0.036
                                                                        l0.125,0.035l0.018,0.035l0.126,0.037l0.07,0.035l0.054,0.036l0.125,0.071h0.02l0.125,0.072l0.072,0.035l0.053,0.035l0.126,0.072
                                                                        l0.125,0.07l0.072,0.037l0.053,0.035l0.144,0.107l0.107,0.072l0.072,0.035l0.072,0.035l0.107,0.072l0.142,0.071l0.054,0.036
                                                                        l0.055,0.072l0.125,0.07l0.125,0.072l0.055,0.035l0.052,0.072l0.144,0.071l0,0l0.107,0.071l0.055,0.035l0.053,0.072l0.126,0.071
                                                                        l0,0l0.125,0.108l0.055,0.035l0.053,0.035l0.107,0.107l0,0l0.144,0.072l0.035,0.035l0.072,0.072l0.107,0.071l0,0l0.107,0.106
                                                                        l0.055,0.037l0.054,0.071l0.107,0.071h0.018l0.107,0.107l0.055,0.035l0.07,0.072l0.089,0.071l0.019,0.036l0.107,0.071l0.035,0.071
                                                                        l0.055,0.036l0.107,0.106h0.018l0.144,0.144l0.179,0.144c0.858,0.895,1.646,1.86,2.343,2.898l0,0l0.126,0.179l0,0l0.107,0.179l0,0
                                                                        l0.107,0.215h0.018l0.107,0.179l0,0l0.107,0.179h0.02l0.089,0.215h0.018l0.089,0.178h0.018l0.09,0.215h0.018l0.089,0.18l0,0
                                                                        l0.089,0.215l0,0l0.107,0.18l0,0l0.107,0.213l0.072,0.18l0.107,0.215l0,0l0.072,0.214l0.107,0.216l0.072,0.178l0.072,0.216h0.018
                                                                        l0.072,0.214l0,0l0.072,0.215l0.07,0.215l0.072,0.215l0.072,0.215l0.072,0.215l0.053,0.214l0.072,0.215l0,0l0.054,0.215
                                                                        l0.053,0.179h47.872c0.447-1.789,1.144-3.506,2.093-5.045L107.468,203.746z
                                                                        M99.562,175.303c4.239,0,8.084,1.718,10.839,4.508
                                                                        l0.306,0.322c2.593,2.754,4.185,6.44,4.185,10.482c0,2.361-0.537,4.616-1.502,6.619l21.645,21.646
                                                                        c1.557-0.93,3.257-1.646,5.064-2.11v-47.871h-0.037l-0.142-0.035l-0.072-0.037l-0.144-0.035h-0.072l-0.143-0.035l-0.072-0.036
                                                                        l-0.142-0.036l-0.072-0.035l-0.144-0.036l-0.072-0.036l-0.142-0.035h-0.055l-0.16-0.071h-0.037l-0.179-0.072h-0.035l-0.179-0.071
                                                                        h-0.018l-0.197-0.072l0,0l-0.198-0.106h-0.018l-0.196-0.071l0,0l-0.197-0.072l-0.196-0.107h-0.018l-0.179-0.07h-0.019
                                                                        l-0.197-0.108l0,0l-0.179-0.071h-0.035l-0.162-0.107h-0.018l-0.179-0.071l-0.035-0.036l-0.144-0.072l-0.035-0.035l-0.161-0.072
                                                                        l-0.055-0.035l-0.142-0.071l-0.037-0.036l-0.142-0.072l-0.055-0.035l-0.125-0.071l-0.072-0.036l-0.107-0.07l-0.072-0.037
                                                                        l-0.125-0.071l-0.072-0.035l-0.107-0.072l-0.072-0.071l-0.107-0.035l-0.09-0.072l-0.089-0.071l-0.089-0.036l-0.089-0.072
                                                                        l-0.09-0.07l-0.072-0.036l-0.107-0.071L133.835,166l-0.107-0.071l-0.072-0.035l-0.107-0.108l-0.07-0.035l-0.109-0.071
                                                                        l-0.053-0.072l-0.126-0.071l-0.035-0.035l-0.125-0.107l-0.054-0.036l-0.125-0.106l-0.037-0.037l-0.125-0.107l-0.035-0.035
                                                                        l-0.144-0.107l-0.018-0.035l-0.126-0.107h-0.035l-0.144-0.144h-0.018l-0.143-0.144h-0.017l-0.144-0.143v-0.036l-0.144-0.106
                                                                        l-0.179-0.18c-0.876-0.859-1.645-1.824-2.325-2.826l-0.035-0.035l-0.465-0.752l0,0l-0.306-0.537l-0.017-0.035l-0.072-0.107
                                                                        l-0.142-0.287v-0.035l-0.054-0.107l-0.142-0.286l0,0l-0.072-0.106l-0.018-0.072l-0.037-0.072l-0.07-0.143l0,0l-0.072-0.143v-0.072
                                                                        l-0.055-0.071l-0.053-0.107v-0.036l-0.072-0.143v-0.036l-0.054-0.144l-0.035-0.071l-0.018-0.071l-0.072-0.143l0,0l-0.035-0.143
                                                                        l-0.037-0.072l-0.035-0.071l-0.037-0.108v-0.035l-0.053-0.144l-0.018-0.035l-0.037-0.108l-0.035-0.106l-0.018-0.036l-0.055-0.179
                                                                        l0,0l-0.035-0.144l-0.037-0.07v-0.072l-0.053-0.143l0,0l-0.054-0.144v-0.108l-0.035-0.07l-0.037-0.143l0,0l-0.035-0.144H78.845
                                                                        l-0.053,0.144l0,0l-0.037,0.143l-0.018,0.07l-0.035,0.108l-0.037,0.144l0,0l-0.035,0.143l-0.018,0.072l-0.02,0.07l-0.053,0.144
                                                                        h-0.017l-0.037,0.179l-0.018,0.036l-0.035,0.106l-0.037,0.108l-0.018,0.035l-0.035,0.144l-0.018,0.035l-0.054,0.108l-0.018,0.071
                                                                        l-0.018,0.072l-0.055,0.143l-0.054,0.143l-0.035,0.071l-0.035,0.071l-0.037,0.144l-0.018,0.036l-0.055,0.143l-0.035,0.036
                                                                        l-0.035,0.107l-0.037,0.071l-0.035,0.072l-0.054,0.143h-0.018l-0.035,0.143l-0.037,0.072l-0.035,0.072l-0.072,0.106l-0.072,0.144
                                                                        l-0.035,0.071l-0.035,0.071l-0.054,0.107l-0.018,0.035l-0.072,0.144v0.036l-0.072,0.107l-0.035,0.107l-0.035,0.035l-0.072,0.107
                                                                        v0.036l-0.072,0.106l-0.035,0.072l-0.037,0.071l-0.089,0.144l-0.072,0.107l-0.018,0.035l16.208,16.208
                                                                        C94.945,175.84,97.2,175.303,99.562,175.303z
                                                                        M105,273.656c-1.146-1.073-2.738-1.717-4.456-1.717c-1.716,0-3.31,0.68-4.454,1.753
                                                                        l-0.161,0.144c-1.164,1.18-1.915,2.826-1.915,4.614c0,1.718,0.662,3.256,1.7,4.401l0.053,0.071l0,0l0.072,0.071l0.072,0.072h0.018
                                                                        c1.181,1.181,2.827,1.932,4.615,1.932c1.735,0,3.292-0.645,4.438-1.717l0.177-0.215c1.181-1.182,1.914-2.827,1.914-4.616
                                                                        c0-1.717-0.679-3.291-1.753-4.472l0,0l-0.072-0.071l-0.07-0.071h-0.019l-0.053-0.037l-0.037-0.035v-0.035l-0.07-0.036V273.656z
                                                                        M113.979,271.08c1.218,2.184,1.897,4.723,1.897,7.37c0,4.258-1.718,8.086-4.491,10.841l-0.303,0.287
                                                                        c-2.755,2.611-6.477,4.221-10.538,4.221c-2.666,0-5.205-0.715-7.387-1.896l-16.495,16.493l0,0l0.037,0.072l0.035,0.071
                                                                        l0.089,0.106l0,0l0.072,0.145l0.054,0.07l0.035,0.072l0.072,0.107v0.035l0.072,0.107l0.017,0.072l0.053,0.07l0.072,0.108v0.035
                                                                        l0.072,0.144l0.018,0.035l0.054,0.108l0.035,0.071l0.035,0.072l0.072,0.106v0.036l0.072,0.107l0.035,0.071l0.037,0.071
                                                                        l0.035,0.143h0.018l0.054,0.144l0.035,0.071l0.037,0.072l0.035,0.107l0.035,0.035l0.055,0.144l0.018,0.036l0.037,0.107
                                                                        l0.035,0.106l0.018,0.036l0.054,0.143l0.018,0.036l0.055,0.144l0.018,0.071l0.018,0.071l0.054,0.107l0.018,0.035l0.035,0.144
                                                                        l0.018,0.036l0.037,0.107l0.035,0.107l0.018,0.035l0.037,0.144l0.017,0.035l0.035,0.144l0.037,0.071l0.018,0.072l0.035,0.144l0,0
                                                                        l0.037,0.143l0.035,0.072l0.018,0.07l0.037,0.18l0,0l0.053,0.143h47.872l0.035-0.143l0,0l0.037-0.18l0.035-0.07v-0.072
                                                                        l0.054-0.143l0,0l0.053-0.144v-0.072l0.037-0.071l0.035-0.144v-0.035l0.072-0.144v-0.035l0.035-0.107l0.037-0.107l0.018-0.036
                                                                        l0.053-0.144v-0.035l0.037-0.107l0.035-0.071l0.037-0.071l0.035-0.144v-0.036l0.072-0.143l0.018-0.036l0.035-0.106l0.054-0.107
                                                                        v-0.036l0.072-0.144v-0.035l0.053-0.107l0.055-0.072v-0.071h0.018l0.125-0.286l0.037-0.071l0.035-0.071l0.055-0.107l0,0
                                                                        l0.072-0.143l0.018-0.072l0.053-0.071l0.054-0.108l0.018-0.035l0.054-0.144l0.035-0.035l0.035-0.108l0.072-0.07l0.017-0.072
                                                                        l0.072-0.107l0.018-0.035l0.072-0.107l0.037-0.072l0.035-0.07l0.072-0.108v-0.036l0.072-0.106l0.035-0.071l0.053-0.072
                                                                        l0.054-0.106l0.035-0.036l0.072-0.107l0.018-0.035l0.072-0.108l0.054-0.071l0.035-0.035l0.072-0.144h0.018l0.072-0.144
                                                                        l0.054-0.035l0.035-0.071l0.072-0.144h0.018l0.089-0.107l0.035-0.036l0.055-0.106l0.072-0.107h0.018l0.089-0.107l0.018-0.035
                                                                        l0.072-0.108l0.072-0.071l0.035-0.035l0.089-0.144h0.018l0.072-0.108l0.072-0.07l0.035-0.036l0.107-0.107l0.089-0.144l0.037-0.035
                                                                        l0.07-0.072l0.089-0.107l0,0l0.107-0.106l0.109-0.144l0.07-0.071l0.037-0.036l0.107-0.107l0,0l0.107-0.107l0.055-0.071
                                                                        l0.052-0.036l0.107-0.107c2.327-2.325,5.261-4.043,8.552-4.865v-47.871c-1.861-0.43-3.597-1.18-5.188-2.146L113.979,271.08z
                                                                        M100.544,263.138c2.594,0,5.027,0.644,7.156,1.753l21.056-21.037c-0.913-1.503-1.609-3.148-2.039-4.902H78.845
                                                                        c-0.823,3.256-2.521,6.19-4.829,8.516l-0.109,0.107l-0.052,0.035l-0.072,0.072l-0.089,0.107h-0.018l-0.125,0.107l-0.02,0.035
                                                                        l-0.089,0.072l-0.07,0.072l-0.037,0.035l-0.107,0.107h-0.035l-0.089,0.107l-0.055,0.035l-0.072,0.036l-0.107,0.106l-0.107,0.107
                                                                        l-0.072,0.036l-0.072,0.072l-0.09,0.071h-0.018l-0.107,0.106l-0.035,0.036l-0.107,0.072l-0.072,0.07h-0.037l-0.125,0.108h-0.018
                                                                        l-0.107,0.106l-0.072,0.037l-0.055,0.035l-0.125,0.107l-0.125,0.072l-0.054,0.035l-0.072,0.071l-0.107,0.071h-0.035l-0.107,0.072
                                                                        l-0.037,0.035l-0.107,0.071l-0.089,0.036l-0.035,0.036l-0.126,0.106h-0.035l-0.107,0.072l-0.072,0.036l-0.055,0.035l-0.125,0.071
                                                                        l0,0l-0.144,0.072l-0.07,0.035l-0.072,0.072l-0.107,0.035l-0.019,0.035l-0.125,0.072h-0.053l-0.089,0.071l-0.109,0.036
                                                                        l-0.035,0.036l-0.144,0.071h-0.018l-0.125,0.071l-0.072,0.036l-0.053,0.036l-0.144,0.071l0,0l-0.125,0.072h-0.072l-0.072,0.035
                                                                        l-0.144,0.071l0,0l-0.142,0.071h-0.055l-0.089,0.072l-0.107,0.035h-0.035l-0.144,0.072h-0.035l-0.107,0.07l-0.107,0.037h-0.037
                                                                        l-0.142,0.07h-0.019l-0.142,0.036l-0.072,0.036l-0.072,0.036l-0.142,0.036l0,0l-0.162,0.07h-0.035l-0.108,0.036l-0.107,0.036
                                                                        l-0.035,0.035l-0.144,0.036h-0.035l-0.125,0.036l-0.09,0.035l-0.072,0.037l-0.144,0.035l0,0l-0.142,0.035l-0.072,0.036h-0.072
                                                                        l-0.162,0.036h-0.018l-0.142,0.035v47.871l0.142,0.036l0.018,0.036l0.162,0.035h0.072l0.072,0.035l0.142,0.036l0,0l0.144,0.036
                                                                        l0.072,0.035l0.09,0.036l0.125,0.036h0.035l0.144,0.035h0.035l0.107,0.037l0.108,0.035l0.035,0.035l0.162,0.036l0,0l0.142,0.071
                                                                        l0.072,0.035h0.072l0.142,0.072h0.019l0.142,0.071h0.037l0.107,0.036l0.107,0.035l0.035,0.037l0.144,0.035l0.035,0.035
                                                                        l0.107,0.036l0.072,0.036l0.072,0.036h0.018l0.25,0.106l0.09,0.036l0.072,0.035l0.107,0.072h0.018l0.144,0.071l0.053,0.035
                                                                        l0.072,0.037l0.125,0.071h0.018l0.144,0.071h0.035l0.109,0.072l0.089,0.035l0.053,0.035l0.125,0.072h0.019l0.107,0.07l0.072,0.037
                                                                        l0.07,0.035l0.126,0.072h0.018l0.035,0.035l16.602-16.602c-1.146-2.146-1.79-4.578-1.79-7.155c0-4.222,1.735-8.05,4.51-10.84
                                                                        l0.321-0.287C92.799,264.748,96.483,263.138,100.544,263.138z
                                                                        M190.615,272.619c-1.181-1.181-2.827-1.932-4.615-1.932
                                                                        c-1.574,0-3.041,0.572-4.15,1.502l0,0l-0.072,0.072h-0.035l-0.019,0.035l-0.339,0.322c-1.181,1.18-1.914,2.79-1.914,4.615
                                                                        c0,1.789,0.733,3.435,1.914,4.615c1.179,1.181,2.808,1.896,4.615,1.896c1.718,0,3.292-0.645,4.473-1.753l0.142-0.144
                                                                        c1.181-1.181,1.915-2.826,1.915-4.615S191.796,273.799,190.615,272.619z
                                                                        M194.122,290.223c-2.343,1.465-5.152,2.324-8.121,2.324
                                                                        c-4.222,0-8.068-1.717-10.841-4.472c-2.755-2.791-4.491-6.619-4.491-10.841c0-2.971,0.858-5.76,2.345-8.122l-18.981-18.962
                                                                        c-1.574,0.967-3.31,1.682-5.134,2.146v47.907c3.274,0.786,6.208,2.504,8.516,4.794c0.644,0.68,1.253,1.359,1.788,2.111l0,0
                                                                        l0.251,0.357l0.035,0.036l0.072,0.106l0.144,0.215l0.035,0.035l0.072,0.144l0.09,0.144l0.035,0.035l0.054,0.072l0.072,0.107v0.035
                                                                        l0.09,0.144h0.018l0.072,0.106l0.035,0.072l0.035,0.072l0.072,0.143l0,0l0.072,0.143l0.035,0.037l0.037,0.07l0.072,0.107
                                                                        l0.018,0.035l0.07,0.144l0,0l0.054,0.144l0.035,0.071l0.037,0.072l0.072,0.106v0.037l0.07,0.106l0.037,0.071l0.035,0.072
                                                                        l0.037,0.106l0.035,0.036l0.055,0.143h0.018l0.052,0.144l0.02,0.071l0.035,0.072l0.053,0.107l0.019,0.035l0.053,0.144l0.018,0.036
                                                                        l0.037,0.107l0.035,0.106l0.037,0.036l0.035,0.143l0,0l0.072,0.18l0.018,0.035l0.035,0.107l0.037,0.107l0.018,0.035l0.035,0.144
                                                                        h0.019l0.053,0.144l0.018,0.071l0.02,0.071l0.035,0.144l0,0l0.072,0.179v0.035l0.035,0.108l0.035,0.106v0.037l0.054,0.143l0,0
                                                                        l0.035,0.143l0.02,0.072l0.035,0.107l0.018,0.106l0.018,0.036h47.872l0.037-0.143l0,0l0.035-0.144l0.035-0.106v-0.037l0.054-0.143
                                                                        v-0.035l0.035-0.107l0.02-0.108l0.035-0.036l0.035-0.143v-0.036l0.037-0.106l0.035-0.107l0.018-0.036l0.054-0.144v-0.035
                                                                        l0.035-0.107l0.037-0.107l0.018-0.035l0.052-0.144l0,0l0.055-0.144l0.018-0.071l0.037-0.071l0.053-0.144h0.018l0.037-0.144
                                                                        l0.035-0.07l0.018-0.072l0.055-0.107v-0.035l0.072-0.107l0.035-0.072l0.018-0.071l0.054-0.144l0,0l0.072-0.143l0.035-0.071
                                                                        l0.035-0.036l0.055-0.143l0,0l0.072-0.143l0.018-0.072l0.035-0.036l0.072-0.144l0,0l0.072-0.143l0.035-0.071l0.037-0.036
                                                                        l0.07-0.143l0,0l0.072-0.143l0.037-0.072l0.035-0.036l0.072-0.144l0.072-0.143l0.035-0.036l0.035-0.071l0.072-0.143l0.072-0.072
                                                                        L194.122,290.223z
                                                                        M240.705,53.3c-2.415,0-4.383-1.968-4.383-4.401c0-2.432,1.969-4.4,4.383-4.4h39.786c2.432,0,4.4,1.969,4.4,4.4
                                                                        c0,2.434-1.969,4.401-4.4,4.401H240.705z
                                                                        M162.404,128.111c1.664,0,3.381-1.109,3.954-2.504c1.467-2.898-0.769-6.297-3.954-6.297
                                                                        h-8.783c-1.683,0-3.399,1.072-3.973,2.469c-1.467,2.934,0.788,6.332,3.973,6.332H162.404z
                                                                        M197.557,128.111
                                                                        c1.681,0,3.399-1.109,3.971-2.504c1.449-2.898-0.788-6.297-3.971-6.297h-8.784c-1.664,0-3.381,1.072-3.954,2.469
                                                                        c-1.467,2.934,0.769,6.332,3.954,6.332H197.557z
                                                                        M232.726,128.111c1.663,0,3.399-1.109,3.953-2.504
                                                                        c1.467-2.898-0.769-6.297-3.953-6.297h-8.783c-1.664,0-3.399,1.072-3.954,2.469c-1.467,2.934,0.769,6.332,3.954,6.332H232.726z
                                                                        M251.474,135.697c0,1.681,1.091,3.398,2.486,3.971c2.934,1.467,6.315-0.787,6.315-3.971v-8.766c0-1.683-1.074-3.399-2.469-3.973
                                                                        c-2.934-1.466-6.333,0.788-6.333,3.973V135.697z
                                                                        M251.474,170.867c0,1.68,1.091,3.398,2.486,3.971
                                                                        c2.934,1.467,6.315-0.787,6.315-3.971v-8.803c0-1.646-1.074-3.363-2.469-3.935c-2.934-1.468-6.333,0.786-6.333,3.935V170.867z
                                                                        M251.474,206.036c0,1.683,1.091,3.399,2.486,3.972c2.934,1.43,6.315-0.787,6.315-3.972v-8.802c0-1.645-1.074-3.398-2.469-3.936
                                                                        c-2.934-1.467-6.333,0.752-6.333,3.936V206.036z
                                                                        M251.474,241.205c0,1.646,1.091,3.399,2.486,3.937
                                                                        c2.934,1.467,6.315-0.752,6.315-3.937v-8.801c0-1.646-1.074-3.398-2.469-3.936c-2.934-1.467-6.333,0.751-6.333,3.936V241.205z
                                                                        M76.358,469.898c-2.415,0-4.383-1.968-4.383-4.4c0-2.397,1.969-4.401,4.383-4.401h264.595c2.435,0,4.401,2.004,4.401,4.401
                                                                        c0,2.433-1.967,4.4-4.401,4.4H76.358z
                                                                        M456.016,477.197c2.435,0,4.401,1.968,4.401,4.4s-1.967,4.401-4.401,4.401H222.529
                                                                        c-2.432,0-4.401-1.969-4.401-4.401s1.969-4.4,4.401-4.4H456.016z
                                                                        M203.781,477.197c2.434,0,4.401,1.968,4.401,4.4
                                                                        s-1.967,4.401-4.401,4.401h-22.897c-2.415,0-4.401-1.969-4.401-4.401s1.987-4.4,4.401-4.4H203.781z
                                                                        M359.273,469.898
                                                                        c-2.416,0-4.384-1.968-4.384-4.4c0-2.397,1.968-4.401,4.384-4.401h22.897c2.433,0,4.401,2.004,4.401,4.401
                                                                        c0,2.433-1.969,4.4-4.401,4.4H359.273z
                                                                        M186,261.922c4.222,0,8.068,1.717,10.841,4.472c2.755,2.755,4.471,6.618,4.471,10.841
                                                                        c0,2.29-0.483,4.437-1.377,6.369l18.605,18.604h0.035v-0.035l0.537-0.287l0.035-0.035l0.09-0.035l0.288-0.144h0.018l0.125-0.071
                                                                        l0.269-0.144l0,0l0.144-0.072l0.052-0.035l0.09-0.036l0.125-0.035l0.019-0.036l0.142-0.035l0.037-0.036l0.107-0.036l0.107-0.036
                                                                        l0.035-0.035l0.144-0.035l0.018-0.037l0.125-0.035l0.089-0.036l0.055-0.035l0.161-0.036l0,0l0.142-0.072h0.072l0.072-0.035
                                                                        l0.125-0.035l0.018-0.036l0.144-0.036h0.054l0.09-0.07l0.125-0.037h0.035l0.161-0.035l0,0l0.144-0.072h0.089l0.053-0.035
                                                                        l0.144-0.036h0.018l0.142-0.036l0.072-0.035h0.089l0.144-0.035v-0.036l0.162-0.036v-47.871l-0.162-0.035l0,0l-0.144-0.036h-0.089
                                                                        l-0.072-0.036l-0.142-0.035h-0.018l-0.144-0.035l-0.053-0.037l-0.089-0.035l-0.144-0.036l0,0l-0.161-0.036l-0.035-0.035
                                                                        l-0.125-0.036l-0.09-0.036h-0.054l-0.144-0.035l-0.018-0.035l-0.125-0.036l-0.072-0.036l-0.072-0.036l-0.142-0.036l-0.161-0.07
                                                                        h-0.055l-0.089-0.037l-0.125-0.07h-0.018l-0.144-0.072h-0.035l-0.107-0.035l-0.107-0.037l-0.037-0.035l-0.142-0.071h-0.019
                                                                        l-0.125-0.071l-0.09-0.035h-0.052l-0.144-0.072l-0.125-0.071l-0.072-0.036l-0.072-0.036l-0.125-0.071h-0.018l-0.144-0.071
                                                                        l-0.035-0.036l-0.108-0.036l-0.09-0.071h-0.035l-0.125-0.072l-0.037-0.035l-0.107-0.035l-0.072-0.072l-0.07-0.035l-0.126-0.072
                                                                        l-0.125-0.071l-0.072-0.035l-0.072-0.036l-0.107-0.072h-0.018l-0.125-0.106h-0.037l-0.107-0.072l-0.089-0.071h-0.035l-0.126-0.107
                                                                        h-0.018l-0.107-0.071l-0.072-0.071l-0.053-0.035l-0.144-0.072l-0.107-0.107l-0.055-0.035l-0.072-0.037l-0.107-0.071l-0.018-0.035
                                                                        l-0.125-0.071l-0.037-0.037l-0.089-0.07l-0.07-0.072l-0.037-0.036l-0.125-0.071l-0.018-0.035l-0.107-0.071l-0.072-0.072
                                                                        l-0.037-0.036l-0.125-0.07l-0.125-0.108l-0.037-0.071l-0.072-0.035l-0.107-0.072v-0.035l-0.125-0.107l-0.035-0.035l-0.089-0.072
                                                                        l-0.072-0.072l-0.037-0.035l-0.107-0.107h-0.018l-0.089-0.107l-0.072-0.036l-0.035-0.036l-0.107-0.107
                                                                        c-1.056-1.072-1.986-2.289-2.755-3.577l-0.072-0.107l-0.035-0.071l-0.072-0.107l-0.037-0.071l-0.07-0.108l-0.037-0.07
                                                                        l-0.072-0.143l-0.035-0.037l-0.072-0.143l-0.035-0.072l-0.072-0.143l-0.018-0.036l-0.089-0.143v-0.036l-0.089-0.18l-0.018-0.034
                                                                        l-0.072-0.179l0,0l-0.107-0.18v-0.035l-0.072-0.18l0,0l-0.107-0.215l0,0l-0.072-0.178l0,0l-0.072-0.215l0,0l-0.089-0.18
                                                                        l-0.018-0.035l-0.053-0.18l-0.02-0.035l-0.07-0.179v-0.036l-0.055-0.18l-0.018-0.035l-0.072-0.143v-0.072l-0.054-0.143
                                                                        l-0.018-0.072l-0.035-0.143l-0.035-0.035l-0.037-0.18l-0.018-0.071l-0.035-0.144l-0.02-0.071l-0.035-0.144l-0.035-0.071
                                                                        l-0.037-0.144l0,0h-47.889c-0.447,1.754-1.126,3.399-2.021,4.902l19.429,19.427C181.564,262.387,183.728,261.922,186,261.922z"
                  />
                </svg>
              </div>
              <h3 className="services__title">{t('Services.MainServices.ServiceFourTitle')}</h3>
              <p className="services__text">
              {t('Services.MainServices.ServiceFourText')}
              </p>
            </AnimatedElement>
          </ul>
        </div>
      </section>
      <AnimatedServices />
    </>
  );
};
export default Services;
