import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { Draggable } from "gsap/Draggable";

gsap.registerPlugin(ScrollTrigger);

gsap.registerPlugin(Draggable);
const AnimatedServices = () => {
  const { t } = useTranslation();
  const setDraggable = (target, type, xVal, durationVal, repeatVal, xPercentVal, scrollDurationVal) => {
    const tl = gsap.timeline();
    const tl2 = gsap.timeline();
  
    Draggable.create(target, {
      type: type,
      onDragEnd: function () {
        tl2.to(target, {
          x: xVal,
          duration: durationVal,
          repeat: repeatVal,
          ease: "linear",
        });
  
        tl.to(target, {
          xPercent: xPercentVal,
          duration: scrollDurationVal,
          scrollTrigger: {
            trigger: ".scrolling-text-container",
            toggleActions: "play reset reset reset",
            scrub: 1,
            onUpdate: (self) => {
              const progress = self.progress;
              const newDuration = scrollDurationVal / (1 - progress);
              tl.duration(newDuration);
            },
          },
        });
      },
    });
  
    tl2.to(target, {
      x: xVal,
      duration: durationVal,
      repeat: repeatVal,
      ease: "linear",
    });
  
    tl.to(target, {
      xPercent: xPercentVal,
      duration: scrollDurationVal,
      scrollTrigger: {
        trigger: ".scrolling-text-container",
        toggleActions: "play reset reset reset",
        scrub: 1,
        onUpdate: (self) => {
          const progress = self.progress;
          const newDuration = scrollDurationVal / (1 - progress);
          tl.duration(newDuration);
        },
      },
    });
  };
  
  useEffect(() => {
    if (document.querySelector(".animate-line-1")) {
      setDraggable(
        ".animate-line-1",
        "translateX",
        500,
        50,
        -1,
        30,
        5
      );
    }
  
    if (document.querySelector(".animate-line-2")) {
      setDraggable(
        ".animate-line-2",
        "translateX",
        -600,
        50,
        -1,
        -30,
        15
      );
    }
  }, []);
  const services = {
    Services: t('Services', { returnObjects: true }),

  };

  return (
    <div className="container">
      <div className="scrolling-text-container">
        <ul className="scrolling-text animate-line-1">
        {services.Services.SecondaryServices.ServiceListOne.map((service, index) => (
          <li key={index}>{service}</li>
        ))}
        </ul>
      </div>
      <div className="scrolling-text-container">
        <ul className="scrolling-text animate-line-2">
        {services.Services.SecondaryServices.ServiceListTwo.map((service, index) => (
          <li key={index}>{service}</li>
        ))}
        </ul>
      </div>
    </div>
  );
};
export default AnimatedServices;
