import React, { useState, useEffect } from "react";

const CookieBanner = () => {
  const [consent, setConsent] = useState(localStorage.getItem("cookieConsent"));
  const [displayBanner, setDisplayBanner] = useState(true);

  const handleConsent = () => {
    localStorage.setItem("cookieConsent", true);
    setConsent(true);
    setDisplayBanner(false);
  };

  const handleDecline = () => {
    localStorage.setItem("cookieConsent", false);
    window["ga-disable-G-N2XC410FM7"] = true; // replace UA-XXXXXXX-Y with your Google Analytics tracking ID
    setConsent(false);
    setDisplayBanner(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplayBanner(false);
    }, 10000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    displayBanner && !consent &&(
      <div className="cookie-banner">
        <div className="container">
        <p>
          We use cookies to personalize content and ads, to provide social media features and to analyze our traffic.
          We also share information about your use of our site with our social media, advertising and analytics partners.
          By continuing to browse the site, you are agreeing to our use of cookies.
        </p>
        <div className="cookie-banner__buttons">
          <button onClick={handleConsent} className="button">Accept</button>
          <button onClick={handleDecline} className="button">Decline</button>
        </div>
        </div>
      </div>
    )
  );
};

export default CookieBanner;
