import React from "react";
import { useInView, animated, useSpring } from "react-spring";

const AnimatedElement = ({ tag, children, delay, className, href }) => {
  const [ref, inView] = useInView({ once: true });

  const spring = useSpring({
    y: inView ? 0 : 50,
    opacity: inView ? 1 : 0,
    delay: delay,
    config: { duration: 500 },
  });

  const AnimatedTag = animated[tag];

  return (
    <AnimatedTag className={className} href={href} ref={ref} style={spring}>
      {children}
    </AnimatedTag>
  );
};

export default AnimatedElement;






