import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState("en");

  const changeLanguage = (event) => {
    const newLanguage = event.target.dataset.lang;
    i18n.changeLanguage(newLanguage);
    setLanguage(newLanguage);
  };

  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n.language]);

  return (
    <div className="language">
      <ul className="language-switcher" aria-label="language selector">
        <li>
          <button
            onClick={changeLanguage}
            data-lang="en"
            aria-label="english"
            className={!language.startsWith('en') ? 'active' : ""}
          >
            English
          </button>
        </li>
        <li>
          <button
            onClick={changeLanguage}
            data-lang="fr"
            aria-label="french"
            className={language.startsWith('en') ? 'active' : ""}
          >
            Français
          </button>
        </li>
      </ul>
    </div>
  );
}

export default LanguageSwitcher;
