import React from "react";
import { useTranslation } from "react-i18next";
import CookieBanner from "./CookieBanner";
import AnimatedElement from "./reusables/BasicSpringAnimation";


const Contact = () => {
  const { t } = useTranslation();

  const currentYear = new Date().getFullYear();

  // const [formData, setFormData] = useState({
  //     name: '',
  //     email: '',
  //     subject: '',
  //     message: '',
  //   });

  //   const handleInputChange = (event) => {
  //     const { name, value } = event.target;
  //     setFormData({ ...formData, [name]: value });
  //   };

  //   const handleSubmit = (event) => {
  //     event.preventDefault();

  //     fetch('/send-email', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(formData),
  //     })
  //       .then((response) => {
  //         if (response.ok) {
  //           alert('Email sent successfully');
  //         } else {
  //           throw new Error('Error sending email');
  //         }
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //         alert('Error sending email');
  //       });
  //   };
  return (
    <footer className="contact" id={t('Nav.hrefThree')}>
      <div className="container">
        <AnimatedElement tag="h2" className="contact__title">{t('Contact.ContactTitle')}</AnimatedElement>
        <AnimatedElement tag="p" className="contact__subtitle" delay={200}>
        {t('Contact.ContactText')}
        </AnimatedElement>
        <AnimatedElement tag="div" className="flex" delay={200}>
          {/* <form className="contact__form" onSubmit={handleSubmit}>
            <div className="form__group">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Enter your name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form__group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email address"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form__group">
              <label htmlFor="subject">Subject:</label>
              <input
                type="text"
                id="subject"
                name="subject"
                placeholder="Enter the subject of your message"
                value={formData.subject}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form__group">
              <label htmlFor="message">Message:</label>
              <textarea
                id="message"
                name="message"
                placeholder="Enter your message"
                value={formData.message}
                onChange={handleInputChange}
                required
              ></textarea>
            </div>
            <button type="submit" className="button">
              Send Message
            </button>
          </form> */}

          <div className="contact__info">
            <div className="contact__details">
              {/* <div className="contact__detail">
                <i className="fas fa-map-marker-alt"></i> */}
                {/* <div className="contact__text">
                  <h3>Address</h3>
                  <p>
                    123 Main St, Suite 100
                    <br />
                    Anytown, USA 12345
                  </p>
                </div>
              </div>
              <div className="contact__detail">
                <i className="fas fa-phone-alt"></i>
                <div className="contact__text">
                  <h3>Phone</h3>
                  <p>(123) 456-7890</p>
                </div>
              </div> */}
              <div className="contact__detail">
                <i className="fas fa-envelope"></i>
                <div className="contact__text">
                  <p><a href="mailto:info@karibew.com" className="button" aria-label="email" title="email" role="button">{t('Button.EmailButton')}</a></p>
                </div>
              </div>
            </div>
          </div>
        </AnimatedElement>
        <div>
          <p className="copyright">
            &copy; {currentYear} karibew | {t('Copyright')}.
          </p>
        </div>
      </div>
      <CookieBanner />
    </footer>
  );
};
export default Contact;
