import React from "react";
import { useTranslation } from "react-i18next";
import AnimatedElement from "./reusables/BasicSpringAnimation";
import Particle from "./Particle";

const Banner = () => {
  const { t } = useTranslation();

  return (
    <section className="banner">
      <Particle />
      <AnimatedElement className="banner__text container" tag="div" delay={100}>
        <h1>
          <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 319.98 44.28"
          >
            <path
              d="m11.46,43.2H2.16c-.76,0-1.31-.17-1.65-.51-.34-.34-.51-.89-.51-1.65V2.16C0,1.4.17.85.51.51s.89-.51,1.65-.51h9.3c.76,0,1.31.17,1.65.51.34.34.51.89.51,1.65v13.8h2.4L22.2,1.98c.28-.72.66-1.23,1.14-1.53s1.1-.45,1.86-.45h9.24c.68,0,1.14.17,1.38.51.24.34.22.83-.06,1.47l-6.12,13.98c2.24.12,4.03.91,5.37,2.37,1.34,1.46,2.01,3.39,2.01,5.79v16.92c0,.76-.17,1.31-.51,1.65-.34.34-.89.51-1.65.51h-9.24c-.76,0-1.31-.17-1.65-.51-.34-.34-.51-.89-.51-1.65v-11.7c0-.64-.16-1.14-.48-1.5-.32-.36-.8-.54-1.44-.54h-7.92v13.74c0,.76-.17,1.31-.51,1.65-.34.34-.89.51-1.65.51Z"
              style={{ fill: "#3c78d8" }}
            />
            <path
              d="m57,41.04c0,.76-.17,1.31-.51,1.65-.34.34-.89.51-1.65.51h-8.82c-.76,0-1.31-.17-1.65-.51-.34-.34-.51-.89-.51-1.65v-15.84c0-1.2.19-2.58.57-4.14.38-1.56.97-3.38,1.77-5.46l5.22-13.62c.24-.68.61-1.18,1.11-1.5.5-.32,1.15-.48,1.95-.48h16.14c.76,0,1.39.16,1.89.48.5.32.87.82,1.11,1.5l5.22,13.62c.8,2.08,1.39,3.9,1.77,5.46.38,1.56.57,2.94.57,4.14v15.84c0,.76-.17,1.31-.51,1.65-.34.34-.89.51-1.65.51h-9.06c-.8,0-1.39-.17-1.77-.51-.38-.34-.57-.89-.57-1.65v-8.4h-10.62v8.4Zm3.42-28.44l-2.82,9.12h9.42l-2.82-9.12c-.16-.36-.33-.62-.51-.78-.18-.16-.39-.24-.63-.24h-1.5c-.24,0-.45.08-.63.24-.18.16-.35.42-.51.78Z"
              style={{ fill: "#3c78d8" }}
            />
            <path
              d="m99.96,43.2h-9.24c-.76,0-1.31-.17-1.65-.51-.34-.34-.51-.89-.51-1.65V2.16c0-.76.17-1.31.51-1.65s.89-.51,1.65-.51h19.98c4.48,0,7.57.93,9.27,2.79,1.7,1.86,2.55,4.27,2.55,7.23v2.76c0,1.68-.25,3.11-.75,4.29-.5,1.18-1.37,2.03-2.61,2.55,2.16.24,3.91,1.12,5.25,2.64,1.34,1.52,2.01,3.54,2.01,6.06v12.72c0,.76-.17,1.31-.51,1.65-.34.34-.89.51-1.65.51h-9.3c-.76,0-1.31-.17-1.65-.51-.34-.34-.51-.89-.51-1.65v-9.18c0-.88-.17-1.53-.51-1.95-.34-.42-.93-.63-1.77-.63h-8.4v11.76c0,.76-.17,1.31-.51,1.65-.34.34-.89.51-1.65.51Zm2.16-32.4v7.68h4.5c1.04,0,1.74-.27,2.1-.81.36-.54.54-1.21.54-2.01v-2.04c0-.8-.18-1.47-.54-2.01-.36-.54-1.06-.81-2.1-.81h-4.5Z"
              style={{ fill: "#3c78d8" }}
            />
            <path
              d="m134.34,43.2c-.76,0-1.31-.17-1.65-.51-.34-.34-.51-.89-.51-1.65v-7.14c0-.76.17-1.31.51-1.65s.89-.51,1.65-.51h5.94V11.58h-5.94c-.76,0-1.31-.17-1.65-.51-.34-.34-.51-.89-.51-1.65V2.16c0-.76.17-1.31.51-1.65s.89-.51,1.65-.51h25.56c.76,0,1.31.17,1.65.51.34.34.51.89.51,1.65v7.26c0,.76-.17,1.31-.51,1.65s-.89.51-1.65.51h-5.82v20.16h5.82c.76,0,1.31.17,1.65.51.34.34.51.89.51,1.65v7.14c0,.76-.17,1.31-.51,1.65-.34.34-.89.51-1.65.51h-25.56Z"
              style={{ fill: "#3c78d8" }}
            />
            <path
              d="m194.64,43.2h-23.04c-.76,0-1.31-.17-1.65-.51-.34-.34-.51-.89-.51-1.65V2.16c0-.76.17-1.31.51-1.65s.89-.51,1.65-.51h19.14c3.92,0,6.82.75,8.7,2.25,1.88,1.5,2.82,3.81,2.82,6.93v2.88c0,1.68-.29,3.05-.87,4.11-.58,1.06-1.47,1.85-2.67,2.37,2.56.24,4.46,1.12,5.7,2.64,1.24,1.52,1.86,3.74,1.86,6.66v5.52c0,3.36-.95,5.84-2.85,7.44-1.9,1.6-4.83,2.4-8.79,2.4Zm-11.7-33v6.48h5.1c.76,0,1.33-.2,1.71-.6.38-.4.57-1,.57-1.8v-1.68c0-.8-.19-1.4-.57-1.8-.38-.4-.95-.6-1.71-.6h-5.1Zm0,15.72v7.08h7.8c.76,0,1.33-.2,1.71-.6.38-.4.57-1,.57-1.8v-2.34c0-.8-.19-1.39-.57-1.77-.38-.38-.95-.57-1.71-.57h-7.8Z"
              style={{ fill: "#3c78d8" }}
            />
            <path
              d="m243.94,43.2h-27.84c-.76,0-1.31-.17-1.65-.51-.34-.34-.51-.89-.51-1.65V2.16c0-.76.17-1.31.51-1.65s.89-.51,1.65-.51h27.84c.76,0,1.31.17,1.65.51.34.34.51.89.51,1.65v7.02c0,.76-.17,1.31-.51,1.65-.34.34-.89.51-1.65.51h-16.56v4.62h11.7c.76,0,1.31.17,1.65.51.34.34.51.89.51,1.65v6.42c0,.76-.17,1.31-.51,1.65s-.89.51-1.65.51h-11.7v5.16h16.56c.76,0,1.31.17,1.65.51.34.34.51.89.51,1.65v7.02c0,.76-.17,1.31-.51,1.65-.34.34-.89.51-1.65.51Z"
              style={{ fill: "#3c78d8" }}
            />
            <path
              d="m301.06,13.21c1.36,0,2.36-.3,2.98-.9.63-.6.94-1.76.94-3.46v-2.23c0-1.35.22-2.32.67-2.93.45-.6,1.17-.9,2.16-.9h9.34c.99,0,1.71.3,2.16.9.44.6.67,1.58.67,2.93v4.68c0,10-1.49,16.87-4.47,20.59-2.98,3.72-6.83,5.59-11.54,5.59h-10.21c-.52,2.27-1.32,3.97-2.39,5.11-1.07,1.14-2.55,1.7-4.44,1.7h-2.75c-1.88,0-3.35-.57-4.4-1.7-1.05-1.13-1.83-2.84-2.36-5.11h-10.44c-4.76,0-8.63-1.86-11.62-5.59-2.98-3.72-4.47-10.59-4.47-20.59v-4.68c0-1.35.22-2.32.67-2.93.45-.6,1.17-.9,2.16-.9h9.5c.99,0,1.71.3,2.16.9.45.6.67,1.58.67,2.93v2.23c0,1.7.31,2.86.94,3.46.63.6,1.62.9,2.98.9h9.18c1.57,0,2.72.34,3.45,1.01.73.67,1.2,1.93,1.41,3.78l.47,3.51c.05.5.16.85.31,1.06.16.21.42.32.79.32s.63-.11.79-.32c.16-.21.26-.57.31-1.06l.47-3.51c.21-1.84.68-3.1,1.41-3.78.73-.67,1.88-1.01,3.45-1.01h9.03Z"
              style={{ fill: "#a4c2f4" }}
            />
          </svg>
        </h1>
        <h2>
        {t('Banner.Subtitle')}
        </h2>
        <a href="#contact-us" className="button">
        {t('Button.BannerButton')}
        </a>
      </AnimatedElement>
    </section>
  );
};

export default Banner;
