import React from "react";
import { useTranslation } from "react-i18next";
import AnimatedElement from "./reusables/BasicSpringAnimation";
import Video from "./Video";

const About = () => {
  const { t } = useTranslation();

  return (
    <section className="about" id={t('Nav.hrefTwo')}>
      <div className="about__container container">
        <div className="about__img">
          <Video />
        </div>
        <div className="about__text">
          <AnimatedElement tag="h2" className="about__title">
          {t('About.AboutTitle')}
          </AnimatedElement>
          <AnimatedElement tag="p" delay={200}>
          {t('About.AboutText')}
          </AnimatedElement>
        </div>
      </div>
    </section>
  );
};
export default About;
