import { I18nextProvider } from "react-i18next";
import i18n from './i18n/i18n'
import About from './components/About';
import Header from './components/Header';
import Banner from './components/Banner';
import Services from './components/Services';
import Contact from './components/Contact';


const App = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <Header />
      <Banner />
      <Services />
      <About />
      <Contact />
    </I18nextProvider>
  );
};

export default App;